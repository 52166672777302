import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../theme';
import {
  AuditingProcedureResult,
  auditingProcedureResultToNumber,
  pruneAuditingProcedures,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import Icon from '../Icon';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs, TableRowProps } from '../table/Table';
import Tooltip from '../Tooltip';

type TableItem = AuditingProcedure & TableRowProps<AuditingProcedure>;

const sectionKey: AuditingSectionKey = 'auditingProcedures';

const tBase = `auditing:form.${sectionKey}`;

interface Props extends FormFieldProps<AuditingProceduresForm> {
  auditing?: Auditing;
}

const AuditingProcedures: React.FC<Props> = ({
  formState,
  patchFormState,
  auditing,
}) => {
  const { t } = useTranslation();

  const auditingProcedures = formState.auditingProcedures;

  if (!auditingProcedures) return null;

  const getRowColor = (row: AuditingProcedure) => {
    switch (row.auditingResult) {
      case AuditingProcedureResult.treatedGood:
        return Colors.green;
      case AuditingProcedureResult.treatedModerately:
        return Colors.yellow;
      case AuditingProcedureResult.shortcomings:
        return Colors.red;
      default:
        return undefined;
    }
  };

  const addRowProps = (row: TableItem) => ({
    ...row,
    bgColor: getRowColor,
  });

  const data: TableItem[] = auditingProcedures
    .filter(pruneAuditingProcedures(auditing))
    .map(addRowProps);

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.auditingProcedures?.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, auditingProcedures: patch });
  }

  const checkIcon = <Icon type="Check" color="grey400" />;

  const tableColumns: TableColumnDefs<TableItem> = [
    {
      accessorKey: 'batch',
      cell: ({ row: { original: row } }) => (
        <Tooltip text={t(`auditing:accountClass.${row.batch}`)}>
          <strong>
            {t(`auditing:accountClass.${row.batch}`).split('-')[0]}
          </strong>
        </Tooltip>
      ),
      header: () => t(`${tBase}.batch`),
      className: 'width-min text-center',
    },
    {
      accessorKey: 'action',
      header: () => t(`${tBase}.action`),
    },
    {
      accessorKey: 'period',
      cell: ({ row: { original: row } }) => row.period && checkIcon,
      header: () => t(`${tBase}.period`),
      className: 'width-min text-center',
    },
    {
      accessorKey: 'tp',
      cell: ({ row: { original: row } }) => row.tp && checkIcon,
      header: () => t(`${tBase}.tp`),
      className: 'width-min text-center',
    },
    {
      accessorKey: 'auditor',
      header: () => t(`${tBase}.auditor`),
      className: 'width-min text-center',
    },
    {
      accessorKey: 'auditingResult',
      className: 'width-min text-center',
      header: () => t(`${tBase}.auditingResult`),
      selectProps: {
        options: Object.values(AuditingProcedureResult),
        displayValue: (option: string) =>
          t(`${tBase}.auditingResultOptions.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
      enableGlobalFilter: false,
      sortingFn: ({ original: a }, { original: b }) => {
        const aResult = auditingProcedureResultToNumber(a.auditingResult);
        const bResult = auditingProcedureResultToNumber(b.auditingResult);
        return aResult - bResult;
      },
    },
    {
      accessorKey: 'auditingReference',
      header: () => t(`${tBase}.auditingReference`),
      className: 'width-min text-center',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'auditingComments',
      header: () => t(`${tBase}.auditingComments`),
      className: 'width-min text-center',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable<TableItem>
      sectionKey={sectionKey}
      data={data}
      columns={tableColumns}
    />
  );
};

export default AuditingProcedures;
