import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../components/layout/Wrapper';
import { useStore } from '../store/RootContext';
import Table, { TableColumnDefs } from '../components/table/Table';
import { getFullName } from '../utils/user';
import { confirmDelete } from '../utils';
import UserForm from '../components/forms/UserForm';
import Modal from '../components/Modal';
import {
  useFetchAuditFirms,
  useFetchCustomerCompanies,
  useFetchUsers,
} from '../utils/hooks';
import Divider from '../components/Divider';

export const Users: React.FC = observer(() => {
  const { t } = useTranslation(['users', 'common']);

  const {
    authStore: { role },
    userStore: { allEntities, deleteEntity, getFirmOrCompanyName },
  } = useStore();

  const [stagedEntity, setStagedEntity] = useState<Partial<User>>();

  useFetchUsers();
  useFetchCustomerCompanies();
  useFetchAuditFirms();

  const clearStagedEntity = () => setStagedEntity(undefined);

  const handleAddNew = () => {
    setStagedEntity({});
  };

  const handleEdit = (entity: User) => {
    setStagedEntity(entity);
  };

  const handleDelete = (entity: User) => {
    if (confirmDelete(getFullName(entity))) {
      deleteEntity({ id: entity.id });
    }
  };

  const columns: TableColumnDefs<User> = [
    {
      id: 'name',
      accessorFn: row => getFullName(row),
      header: () => t('common:label.name'),
      cell: info => info.getValue(),
      className: 'text-bold',
      sortingFn: (a, b) =>
        getFullName(a.original).localeCompare(getFullName(b.original)),
    },
    {
      id: 'firmOrCompany',
      accessorFn: row => getFirmOrCompanyName(row),
      header: () => t('common:label.firmOrCompany'),
      cell: info => info.getValue(),
      sortingFn: (a, b) => {
        const aName = getFirmOrCompanyName(a.original);
        const bName = getFirmOrCompanyName(b.original);
        return aName.localeCompare(bName);
      },
    },
    {
      accessorKey: 'email',
      header: () =>
        `${t('common:label.email')} (=${t('common:label.username')})`,
    },
    {
      id: 'role',
      accessorFn: row => (row.role ? t(`users:role.${row.role}`) : '-'),
      header: () => t('common:label.role'),
    },
  ];

  if (role.isAuditorAdmin)
    columns.push({
      id: 'qualificationLevel',
      accessorFn: row =>
        row.qualificationLevel
          ? t(`users:qualificationLevel.${row.qualificationLevel}`)
          : '-',
      header: () => t('users:label.qualificationLevelShort'),
    });

  return (
    <Wrapper>
      <h1 data-testid="view-title">{t('title')}</h1>
      <Divider />

      <Table<User>
        data={allEntities ?? []}
        columns={columns}
        onAddNew={handleAddNew}
        onRowEdit={handleEdit}
        onRowDelete={handleDelete}
        showIdColumn
      />

      {stagedEntity && (
        <Modal
          title={t(`users:${stagedEntity.id ? 'editUser' : 'addUser'}`)}
          onClose={clearStagedEntity}
          isInitiallyOpen
        >
          <UserForm user={stagedEntity} onSuccess={clearStagedEntity} />
        </Modal>
      )}
    </Wrapper>
  );
});

export default Users;
