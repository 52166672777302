import React from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyle, theme, ThemeProvider } from './theme';
import AppRouter from './router/AppRouter';
import { useStore } from './store/RootContext';
import Spinner from './components/Spinner';
import { TOAST_OPTIONS } from './utils';

const App = observer(() => {
  const {
    appStore: { appState },
  } = useStore();

  const showFullScreenSpinner =
    appState.isProcessing || appState.isFetching || appState.isSaving;

  return (
    <ThemeProvider theme={theme}>
      {showFullScreenSpinner && <Spinner isFullScreen />}
      <ToastContainer {...TOAST_OPTIONS} />
      <GlobalStyle />
      <AppRouter />
    </ThemeProvider>
  );
});

export default App;
