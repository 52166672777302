import AuditingProcedureItem from '../../../components/auditing/AuditingProcedureItem';
import AccountMappings from '../../../components/auditing/procedures/AccountMappings';
import PersonelCostsCommonFormFields from '../../../components/auditing/procedures/personelCosts/PersonelCostsCommonFormFields';
import ProcedureAccounts from '../../../components/auditing/procedures/ProcedureAccounts';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { AccountClassKey, AccountKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import { thresholdComputationRules } from './personelCostsUtils';
import AnalyticalReviewOfWages from '../../../components/auditing/procedures/personelCosts/AnalyticalReviewOfWages';
import BalanceMatching from '../../../components/auditing/procedures/BalanceMatching';
import i18n from '../../../i18n';
import ThresholdTestTable from '../../../components/auditing/procedures/ThresholdTestTable';
import { getAccountMappingValues } from '../../../utils';
import { getAccountRangeFromAccountMap } from '../planning/accountMapUtils';
import { analyticalAuditOfVacationPayAccrualsRows } from './personelCostsUtils.analyticalAuditOfVacationPayAccruals';
import { matchingOfWagesToAnnualReportRows } from './personelCostsUtils.matchingOfWagesToAnnualReport';
import { analyticalAuditOfPersonnelOverheadCostsRows } from './personelCostsUtils.analyticalAuditOfPersonnelOverheadCosts';
import { analyticalAuditOfWagesRows } from './personelCostsUtils.analyticalAuditOfWages';
import {
  analyticalReviewOfWagesRows,
  analyticalReviewOfWagesRowsComputationRules,
} from './peronelCostsUtils.analyticalReviewOfWages';
import { makeDefaultPayAccrualsComparisonRows } from './personelCostsUtils.matchingOfPayAccruals';

type SectionFormType = PersonelCostsForm;

const sectionKey: AuditingSectionKey = 'personelCosts';
const batch = FinancialStatementBatch['L-personelCosts'];

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const getAccountRange = getAccountRangeFromAccountMap(auditing);
  return {
    averageNumberOfPersonsCY: null,
    averageNumberOfPersonsPY: null,
    accountMappings: {
      wagesTotal: getAccountRange(AccountKey.personelCosts),
      pensionExpenses: getAccountRange(AccountKey.pensionExpences),
      tyelOrKuelAccounts: '',
      sicknessInsuranceAccounts: '',
      holidayPayWithAssociatedCostsAccount: '',
      holidayPayWithoutAssociatedCostsAccounts: '',
      capitalizedWagesOrOtherAdjustmentsAccounts: '',
      yelWagesAccounts: '',
    },

    overallAssessmentComments: '',

    analyticalReviewOfWages: analyticalReviewOfWagesRows(),
    analyticalAuditOfPersonnelOverheadCosts:
      analyticalAuditOfPersonnelOverheadCostsRows(),
    analyticalAuditOfWages: analyticalAuditOfWagesRows(),
    matchingOfWagesToAnnualReport: matchingOfWagesToAnnualReportRows(),
    personnelCostsPeriodization: {},
    auditOfVacationPayAccruals: {},
    matchingOfVacationPayAccruals: {
      useCalculationBase: true,
      noCalculationBaseReason: '',
      balanceComparisons: makeDefaultPayAccrualsComparisonRows(),
    },
    analyticalAuditOfVacationPayAccruals:
      analyticalAuditOfVacationPayAccrualsRows(),
    auditOfWages: {},
    auditOfIndividualTransactions: {},
    processMappingWithKeyAccountingPrinciples: {},
  };
};

const formFields: FormFields<SectionFormType> = ({
  store,
  auditing,
  formState,
}) => [
  formBuilder.custom(
    <ProcedureAccounts classKey={AccountClassKey[batch]} auditing={auditing} />
  ),

  formBuilder.custom(props => (
    <AccountMappings
      classKey={AccountClassKey[batch]}
      auditing={auditing}
      accountMappings={formState.accountMappings}
      {...props}
    />
  )),

  formBuilder.custom(props => <PersonelCostsCommonFormFields {...props} />),

  ...store.auditingStore.getAuditingProceduresBatch(batch).map(procedure =>
    formBuilder.accordionGroup({
      title: i18n.t(
        `auditing:auditingProcedureAction.${batch}.${procedure.actionKey}`
      ),
      items: [
        formBuilder.custom(
          <AuditingProcedureItem auditingProcedure={procedure} />
        ),
        formBuilder.custom(props => {
          const { formState, patchFormState } = props;
          const { actionKey } = procedure;

          const accountMappingValues = getAccountMappingValues(
            auditing?.generalLedger?.form.groupedGeneralLedger,
            formState.accountMappings
          );

          switch (actionKey) {
            case 'analyticalReviewOfWages':
              return (
                <AnalyticalReviewOfWages
                  dataKey={actionKey}
                  accountMappingValues={accountMappingValues}
                  analyticalReviewOfWagesComputationRules={
                    analyticalReviewOfWagesRowsComputationRules
                  }
                  {...props}
                />
              );
            case 'matchingOfVacationPayAccruals':
              return (
                <BalanceMatching
                  formState={formState.matchingOfVacationPayAccruals}
                  patchForm={patch =>
                    patchFormState({
                      ...formState,
                      matchingOfVacationPayAccruals: {
                        ...formState.matchingOfVacationPayAccruals,
                        ...patch,
                      },
                    })
                  }
                />
              );

            // Actions using threshold test table
            case 'analyticalAuditOfPersonnelOverheadCosts':
            case 'analyticalAuditOfWages':
            case 'matchingOfWagesToAnnualReport':
            case 'analyticalAuditOfVacationPayAccruals':
              return (
                <ThresholdTestTable<SectionFormType>
                  dataKey={actionKey}
                  accountMappingValues={accountMappingValues}
                  thresholdComputationRules={
                    thresholdComputationRules[actionKey]
                  }
                  {...props}
                />
              );
            case 'personnelCostsPeriodization':
            case 'auditOfVacationPayAccruals':
            case 'auditOfWages':
            case 'auditOfIndividualTransactions':
            case 'processMappingWithKeyAccountingPrinciples':
            default:
              return null;
          }
        }),
      ],
    })
  ),
  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
        showContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['materiality', 'detailedPlan'],
};

export default section;
