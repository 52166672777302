import React from 'react';
import { flexRender, Header, Table } from '@tanstack/react-table';
import Icon from '../Icon';
import ContextInfo, { ContextInfoProps } from '../ContextInfo';
import { useAnimation } from '../../utils/hooks';

export interface GroupingHeaderCell {
  colSpan: number;
  label?: string | JSX.Element;
}

interface Props<T> {
  table: Table<T>;
  getClassName: (header: Header<T, unknown>) => string;
  contextInfoProps?: (header: Header<T, unknown>) => ContextInfoProps;
  groupingHeaderRow?: GroupingHeaderCell[];
}

function TableHead<T>({
  table,
  getClassName,
  contextInfoProps,
  groupingHeaderRow,
}: Props<T>) {
  const animationParent = useAnimation();

  const renderContextInfo = (header: Header<T, unknown>) => {
    const contextInfo = contextInfoProps ? contextInfoProps(header) : undefined;
    if (contextInfo?.text || contextInfo?.title)
      return (
        <div className="context-info-container">
          <ContextInfo
            position={contextInfo?.position ?? 'bottom-right'}
            text={contextInfo?.text}
            title={contextInfo?.title}
          />
        </div>
      );
  };

  const renderSortIcon = (type: 'Up' | 'Down') => (
    <Icon
      type={`Chevron${type}`}
      color="text"
      size={12}
      className="sort-icon"
    />
  );

  return (
    <thead>
      {groupingHeaderRow && (
        <tr ref={animationParent}>
          {groupingHeaderRow.map(({ colSpan, label }, i) => (
            <th
              key={`${i}-${label}-${colSpan}`}
              colSpan={colSpan}
              className={label ? 'grouping-cell' : undefined}
            >
              {label}
            </th>
          ))}
        </tr>
      )}

      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id} ref={animationParent}>
          {headerGroup.headers.map(header => (
            <th
              key={header.id}
              className={getClassName(header)}
              colSpan={header.colSpan}
            >
              {header.isPlaceholder ? null : (
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {renderContextInfo(header)}
                  </span>

                  {{
                    asc: renderSortIcon('Up'),
                    desc: renderSortIcon('Down'),
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

export default TableHead;
