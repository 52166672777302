import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps } from '../table/Table';

const sectionKey: AuditingSectionKey = 'materiality';

type Props = FormFieldProps<MaterialityForm> & {
  readonly?: boolean;
};

export const MaterialitiesTable = ({
  formState,
  patchFormState,
  store,
  readonly,
}: Props) => {
  const { t } = useTranslation();

  const {
    auditingStore: { materialityComputeds },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const handleEdit = ({ itemId, value }: OnEditProps<number>) => {
    const updateValue = (
      targetRowId:
        | 'materialityPercent'
        | 'workingMaterialityPercent'
        | 'singleErrorMaterialityPercent'
    ) => {
      if (targetRowId === itemId) return value;
      else return formState[targetRowId];
    };

    patchFormState({
      ...formState,
      materialityPercent: updateValue('materialityPercent'),
      workingMaterialityPercent: updateValue('workingMaterialityPercent'),
      singleErrorMaterialityPercent: updateValue(
        'singleErrorMaterialityPercent'
      ),
    });
  };

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => '',
    },
    {
      accessorKey: 'materialityPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: readonly ? undefined : handleEdit,
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.materialityValue`),
      className: 'text-center width-min nowrap',
    },
  ];

  const readonlyColumns = tableColumns.filter(
    ({ accessorKey }) => accessorKey !== 'materialityPercent'
  );

  const materialities = materialityComputeds(formState);

  const rows = [
    {
      id: 'materialityPercent',
      name: t(`${tBase}.materiality`),
      materialityPercent: formState.materialityPercent,
      materiality: formatCurrency(materialities.materiality),
    },
    {
      id: 'workingMaterialityPercent',
      name: t(`${tBase}.workingMateriality`),
      materialityPercent: formState.workingMaterialityPercent,
      materiality: formatCurrency(materialities.workingMateriality),
    },
    {
      id: 'singleErrorMaterialityPercent',
      name: t(`${tBase}.singleErrorMateriality`),
      materialityPercent: formState.singleErrorMaterialityPercent,
      materiality: formatCurrency(materialities.singleErrorMateriality),
    },
  ];

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.materialities`)}
      hideHeader={readonly}
      data={rows}
      columns={readonly ? readonlyColumns : tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
