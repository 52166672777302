import { useTranslation } from 'react-i18next';
import { Risk } from '../../views/auditingSections/planning/incomeStatementAndBalance';
import {
  formatCurrency,
  formatPercent,
  getClassNameForFinancialStatementRow,
} from '../../utils';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import ToggleButton from '../inputs/ToggleButton';
import { observer } from 'mobx-react-lite';
import AuditingTable from '../table/AuditingTable';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.xl};
  }
`;

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalance';

interface Props extends FormFieldProps<IncomeStatementAndBalanceForm> {
  targetKey: 'incomeStatement' | 'balanceAssets' | 'balanceLiabilities';
}

export const IncomeStatementAndBalanceTable = observer(
  ({ targetKey, formState, patchFormState }: Props) => {
    const { t } = useTranslation();

    const {
      appStore: { setComponentState, getComponentState },
    } = useStore();

    if (!formState) return null;

    const componentKey = `incomeStatementAndBalanceTables-showStatements`;

    const tBase = `auditing:form.${sectionKey}`;

    const data = formState[targetKey] ?? [];

    const formattedData = data.map(item => ({
      ...item,
      currentYear: formatCurrency(item.currentYear),
      priorYear: formatCurrency(item.priorYear),
      change: formatCurrency(item.change),
      changePercent: formatPercent(item.changePercent),
      className: getClassNameForFinancialStatementRow(item.heading),
    }));

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
      const patch = (formState[targetKey] ?? []).map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
        return row;
      });
      patchFormState({ ...formState, [targetKey]: patch });
    }

    const statementKeys = [
      'statementC',
      'statementE',
      'statementA',
      'statementV',
      'statementO',
      'statementP',
      'statementFraud',
      'statementEstimate',
    ];

    let tableColumns: TableColumnDefs<IncomeStatementAndBalanceTableItem> = [
      {
        id: 'key',
        cell: ({ row: { original: row } }) => (
          <div className={row.className}>
            {row.key ? t(`auditing:accountKey.${row.key}`) : '-'}
          </div>
        ),
        className: 'text-left width-max',
        header: () => t(`${tBase}.item`),
      },
      {
        accessorKey: 'currentYear',
        className: 'text-right small-text width-min nowrap text-bold',
        header: () => t(`${tBase}.currentYear`),
      },
      {
        accessorKey: 'priorYear',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.priorYear`),
      },
      {
        accessorKey: 'change',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.change`),
      },
      {
        accessorKey: 'changePercent',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.changePercent`),
      },
      {
        accessorKey: 'risk',
        className: 'text-center width-min',
        header: () => t(`${tBase}.risk`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        selectProps: {
          options: [Risk.notSignificant, Risk.limitedRisk, Risk.significant],
          displayValue: (option: string) => t(`${tBase}.riskOptions.${option}`),
          setValue: (editProps: OnEditProps<string>) =>
            handleEdit<string>(editProps),
        },
      },
      ...statementKeys.map(key => ({
        accessorKey: key,
        className: 'text-center width-min no-cell-padding',
        header: () => t(`${tBase}.${key}`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        onBooleanEdit: (editProps: OnEditProps<boolean>) =>
          handleEdit<boolean>(editProps),
      })),
      {
        accessorKey: 'statementDescription',
        header: () => t(`${tBase}.statementDescription`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        onEdit: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    ];

    const showStatements = getComponentState(componentKey)?.isOpen ?? true;

    if (!showStatements) {
      tableColumns = tableColumns.filter(
        (column: any) =>
          ![...statementKeys, 'statementDescription'].includes(
            column.accessorKey
          )
      );
    }

    return (
      <Container>
        <ToggleButton
          toggled={showStatements}
          text={t(`${tBase}.statementsAndDescriptions`)}
          onClick={() =>
            setComponentState({ key: componentKey, isOpen: !showStatements })
          }
        />
        <AuditingTable<IncomeStatementAndBalanceTableItem>
          sectionKey={sectionKey}
          data={formattedData}
          columns={tableColumns}
          showGlobalFilter={false}
          disableSort
        />
      </Container>
    );
  }
);
