import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'customerCompanies',
};

export class CustomerCompanyApi extends ModuleBaseApi<CustomerCompany> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}
