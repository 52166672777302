import React, { FC, Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Route as RouterRoute,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import appRoutes from './routes';
import ScrollToTop from './ScrollToTop';
import ViewWrapper from './ViewWrapper';
import { useStore } from '../store/RootContext';
import { Login } from '../views';
import Layout from '../views/Layout';
import { useTranslation } from 'react-i18next';
import { getUserRoutes } from '../utils/route';
import { path } from '../i18n/utils';

interface Props {
  routes?: Route[];
}

const AppRouter: FC<Props> = observer(({ routes = appRoutes }) => {
  const { t } = useTranslation('login');

  const {
    authStore: { isLoggedIn, getMe, token, user, role },
  } = useStore();

  useEffect(() => {
    if (token && !user) getMe();
  }, [getMe, token, user]);

  // ...wait until getMe finishes
  if (token && !user) return null;

  // If user is not logged in, render login view.
  if (!isLoggedIn)
    return (
      <Router>
        <ViewWrapper title={t('login:title')}>
          <Login />
        </ViewWrapper>
      </Router>
    );

  const renderRoute = ({ path, index, title, Element }: Route) => (
    <RouterRoute
      key={path}
      index={index}
      path={path}
      element={<ViewWrapper title={title}>{Element}</ViewWrapper>}
    />
  );

  const homeRoutes = getUserRoutes(appRoutes.homeRoutes, role.type);
  const manageRoutes = getUserRoutes(appRoutes.manageRoutes, role.type);
  const auditingRoutes = getUserRoutes(appRoutes.auditingRoutes, role.type);

  return (
    <Router>
      <Suspense fallback={<div>...</div>}>
        <ScrollToTop />
        <Routes>
          <RouterRoute path="/" element={<Layout noAuditingHeader noSideNav />}>
            {homeRoutes.map(renderRoute)}
          </RouterRoute>

          <RouterRoute
            path={`/${path('manage')}`}
            element={<Layout noAuditingHeader />}
          >
            {manageRoutes.map(renderRoute)}
          </RouterRoute>

          <RouterRoute path={`/${path('auditing')}/:id`} element={<Layout />}>
            {auditingRoutes.map(renderRoute)}
          </RouterRoute>
        </Routes>
      </Suspense>
    </Router>
  );
});

export default AppRouter;
