import { lazy } from 'react';
import { path, rt } from '../i18n/utils';
import { Role } from '../utils/constants';
import { notFoundRoute } from './routes.common';

const Users = lazy(() => import('../views/Users'));
const CustomerCompanies = lazy(() => import('../views/CustomerCompanies'));
const AuditFirms = lazy(() => import('../views/AuditFirms'));

const manageRoutes: Route[] = [
  {
    path: path('manage.users'),
    title: rt('manage.users'),
    Element: <Users />,
    roles: [Role.Admin, Role.AuditorAdmin],
    iconType: 'Users',
  },
  {
    path: path('manage.auditFirms'),
    title: rt('manage.auditFirms'),
    Element: <AuditFirms />,
    roles: [Role.Admin],
    iconType: 'AuditFirm',
  },
  {
    path: path('manage.customerCompanies'),
    title: rt('manage.customerCompanies'),
    Element: <CustomerCompanies />,
    roles: [Role.Admin, Role.AuditorAdmin],
    iconType: 'CustomerCompany',
  },
  notFoundRoute,
];

export default manageRoutes;
