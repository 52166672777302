import { ToastOptions } from 'react-toastify';

export enum Role {
  Admin = 'admin',
  AuditorAdmin = 'auditor_admin',
  Auditor = 'auditor',
  Customer = 'customer',
}

export enum QualificationLevel {
  Trainee = 'trainee',
  Junior = 'junior',
  Senior = 'senior',
  Assistant = 'assistant_manager',
  Manager = 'manager',
  Partner = 'partner',
}

export const AUDITING_STATUS_OPTIONS: AuditingStatus[] = [
  'started',
  'finished',
  'approved',
];

export const AUDITING_SECTION_STATUS_OPTIONS: AuditingSectionStatus[] = [
  'not_started',
  ...AUDITING_STATUS_OPTIONS,
];

export const DEFAULT_LANGUAGE: LanguageCode = 'fi';

export const INPUT_WIDTH = '300px';
export const NUMBER_INPUT_WIDTH = '150px';
export const DATE_INPUT_WIDTH = '144px';

export const PASSWORD_LENGTH = 8;

export const TOAST_OPTIONS: ToastOptions = {
  position: 'top-center',
  autoClose: process.env.NODE_ENV === 'development' ? 2000 : 4000,
  closeButton: true,
  closeOnClick: true,
};
