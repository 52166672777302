import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import SideNavAuditing from './SideNavAuditing';
import SideNavManage from './SideNavManage';

const StyledNav = styled.nav`
  height: 100%;
  overflow-y: auto;

  flex: 0 0 ${p => p.theme.ui.sideNavWidth}px;
  padding-top: ${p => p.theme.spacing.lg};
  padding-bottom: ${p => p.theme.spacing.xxl};
  padding-left: 3%;
  ${p => p.theme.font.size[18]};

  > a {
    margin-left: 1rem;
  }
  .accordion a {
    ${p => p.theme.font.size[16]};
    color: ${p => p.theme.color.grey500};
  }

  a {
    display: flex;
    align-items: center;
    padding: ${p => p.theme.spacing.sm} ${p => p.theme.spacing.md};
    text-decoration: none;
    letter-spacing: 0.0125rem;

    &.is-active {
      color: ${p => p.theme.color.text};
      font-weight: bold;
      letter-spacing: 0rem;
    }
    :hover {
      color: ${p => p.theme.color.text};
    }

    > svg {
      margin-right: ${p => p.theme.spacing.md};
      min-width: 20px;
    }
  }
`;

const SideNav: React.FC = observer(() => {
  const {
    authStore: { role },
    auditingStore: { isAuditingSelected },
  } = useStore();

  return (
    <StyledNav className="no-print">
      {(role.isAdmin || role.isAuditorAdmin) && <SideNavManage />}
      {role.isAuditor && isAuditingSelected && <SideNavAuditing />}
    </StyledNav>
  );
});

export default SideNav;
