import { analyticalAuditOfPersonnelOverheadCostsComputationRules } from './personelCostsUtils.analyticalAuditOfPersonnelOverheadCosts';
import { analyticalAuditOfVacationPayAccrualsComputationRules } from './personelCostsUtils.analyticalAuditOfVacationPayAccruals';
import { analyticalAuditOfWagesComputationRules } from './personelCostsUtils.analyticalAuditOfWages';
import { matchingOfWagesToAnnualReportComputationRules } from './personelCostsUtils.matchingOfWagesToAnnualReport';

export const thresholdComputationRules: {
  [key: string]: ThresholdComputationRules;
} = {
  analyticalAuditOfPersonnelOverheadCosts:
    analyticalAuditOfPersonnelOverheadCostsComputationRules,
  analyticalAuditOfWages: analyticalAuditOfWagesComputationRules,
  matchingOfWagesToAnnualReport: matchingOfWagesToAnnualReportComputationRules,
  analyticalAuditOfVacationPayAccruals:
    analyticalAuditOfVacationPayAccrualsComputationRules,
};
