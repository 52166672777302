import { useTranslation } from 'react-i18next';
import AuditingTable from '../../../table/AuditingTable';
import { formatCurrency, formatPercent } from '../../../../utils';
import { TableColumnDefs } from '../../../table/Table';
import Todo from '../../../Todo';

interface Props<FormType> extends FormFieldProps<FormType> {
  dataKey: keyof FormType;
  accountMappingValues: AccountMappingValues;
  analyticalReviewOfWagesComputationRules: AnalyticalReviewOfWagesComputationRules;
}

function AnalyticalReviewOfWages<FormType>({
  formState,
  store,
  dataKey,
  accountMappingValues,
  analyticalReviewOfWagesComputationRules,
}: Props<FormType>) {
  const { t } = useTranslation('auditing');

  if (!formState?.[dataKey]) return null;

  const dataArray = formState[dataKey] as AnalyticalReviewOfWagesRow[];

  const computationRuleProps: AnalyticalReviewComputationFnProps = {
    accountMappingValues,
    getAccountMappingValues: {
      cy: (...keys: string[]) => keys.map(key => accountMappingValues[key]?.cy),
      py: (...keys: string[]) => keys.map(key => accountMappingValues[key]?.py),
    },
    auditingStore: store.auditingStore,
  };

  const data: AnalyticalReviewOfWagesRow[] = dataArray.map(row => {
    let computedValues: AnalyticalReviewOfWagesRow | undefined =
      analyticalReviewOfWagesComputationRules[row.key]
        ? analyticalReviewOfWagesComputationRules[row.key](computationRuleProps)
        : undefined;
    return { ...row, ...computedValues };
  });

  const tableColumns: TableColumnDefs<AnalyticalReviewOfWagesRow> = [
    {
      accessorKey: 'key',
      accessorFn: ({ key }) => t(`form.personelCosts.${key}`),
      className: 'width-full',
      header: () => null,
    },
    {
      accessorKey: 'cyEur',
      accessorFn: ({ cyEur }) => formatCurrency(cyEur),
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.cyEur`),
    },
    {
      accessorKey: 'cyPercentageOfWages',
      accessorFn: ({ cyPercentageOfWages }) =>
        cyPercentageOfWages ? formatPercent(cyPercentageOfWages) : '',
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.cyPercentageOfWages`),
    },
    {
      accessorKey: 'pyEur',
      accessorFn: ({ pyEur }) => formatCurrency(pyEur),
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.pyEur`),
    },
    {
      accessorKey: 'pyPercentageOfWages',
      accessorFn: ({ pyPercentageOfWages }) =>
        pyPercentageOfWages ? formatPercent(pyPercentageOfWages) : '',
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.pyPercentageOfWages`),
    },
    {
      accessorKey: 'diffEur',
      accessorFn: ({ diffEur }) => formatCurrency(diffEur),
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.diffEur`),
    },
    {
      accessorKey: 'diffPercentage',
      accessorFn: ({ diffPercentage }) =>
        diffPercentage ? formatPercent(diffPercentage) : null,
      className: 'width-min nowrap text-right',
      header: () => t(`form.personelCosts.diffPercentage`),
    },
  ];

  return (
    <>
      <Todo text="Kaavoja täytyy tarkistaa" />
      <AuditingTable<AnalyticalReviewOfWagesRow>
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
      />
    </>
  );
}

export default AnalyticalReviewOfWages;
