import auditingTranslations from '../../../i18n/locales/fi/auditing.json';
import { uuid } from '../../../utils';
import { ControlsToBeTested } from './auditingPlanningUtils';

export enum MonetizationRisk {
  notRepealed = 'notRepealed',
  repealed = 'repealed',
}

export enum Risk {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
  significant = 'significant',
  notApplicaple = 'notApplicaple',
}

export const handleMonetizationRiskChange = (
  selectedValue: string | undefined,
  { formState, patchFormState }: FormFieldProps<RisksForm>
) => {
  const monetizationRisk = selectedValue as MonetizationRisk;
  switch (monetizationRisk) {
    case MonetizationRisk.notRepealed:
      // Change all typical risk values to "significant":
      const statements = (formState as RisksForm).monetizationRiskStatements;
      const monetizationRiskStatements = statements.map(statement => ({
        ...statement,
        typicalRisk: Risk.significant,
      }));

      patchFormState({ monetizationRisk, monetizationRiskStatements });
      break;
    default:
      patchFormState({ monetizationRisk });
  }
};

interface RiskValues {
  typicalRisk?: Risk | null;
  controlRisk?: Risk | null;
  totalRisk?: Risk | null;
}

export const getDefaultRiskValues = (auditing?: Auditing): RiskValues => ({
  typicalRisk: null,
  controlRisk:
    auditing?.auditingPlanning?.form.isControlsToBeTested ===
    ControlsToBeTested.controlsNotTrusted
      ? Risk.notApplicaple
      : null,
  totalRisk: null,
});

export const initialMonetizationRiskStatements = (
  props: RiskValues = {}
): MonetizationRiskStatement[] => {
  const keys: MonetizationRiskStatementKey[] = [
    'occurence',
    'existence',
    'completeness',
    'accuracyAndValuation',
    'cutOff',
    'classification',
    'presentation',
  ];

  return keys.map((key: MonetizationRiskStatementKey) => ({
    id: uuid(),
    key,
    typicalRisk: props.typicalRisk ?? null,
    controlRisk: props.controlRisk ?? null,
    totalRisk: props.totalRisk ?? null,
  }));
};

export enum AccountingEstimatesForReferencePeriod {
  notSignificant = 'notSignificant',
  noSignificantDifferences = 'noSignificantDifferences',
  significantDifferences = 'significantDifferences',
}

export enum InquiredAccountingEstimatesPerception {
  recognisedChanges = 'recognisedChanges',
  notRecognisedChanges = 'notRecognisedChanges',
  recognisedChangesDuringAuditing = 'recognisedChangesDuringAuditing',
}

export const showInquiredAccountingEstimatesPerceptionDetails = (
  formState: RisksForm
) =>
  formState.inquiredAccountingEstimatesPerception ===
    InquiredAccountingEstimatesPerception.recognisedChanges ||
  formState.inquiredAccountingEstimatesPerception ===
    InquiredAccountingEstimatesPerception.recognisedChangesDuringAuditing;

export enum PlanningPhaseAccountingEstimates {
  notDetected = 'notDetected',
  detected = 'detected',
}

export enum AccountingEstimateKind {
  deprecationMethodAndPeriod = 'deprecationMethodAndPeriod',
  developmentCosts = 'developmentCosts',
  goodwill = 'goodwill',
  investments = 'investments',
  subsidiaryShares = 'subsidiaryShares',
  stocks = 'stocks',
  loanReceivables = 'loanReceivables',
  tradeReceivables = 'tradeReceivables',
  otherReceivables = 'otherReceivables',
  warrantyReservation = 'warrantyReservation',
  demolitionReservation = 'demolitionReservation',
  valuationAtFairValue = 'valuationAtFairValue',
  litigationCompensations = 'litigationCompensations',
  judgementCosts = 'judgementCosts',
  other = 'other',
}

export const emptyAccountingEstimate = (
  props: { kind?: AccountingEstimateKind; risks?: RiskValues } = {}
): AccountingEstimate => {
  const validKind = props.kind !== AccountingEstimateKind.other;
  const id = uuid();
  const kind = props.kind && validKind ? props.kind : `custom-${id}`;
  const labelTranslations =
    auditingTranslations.form.risks.accountingEstimate.kinds;
  const label = props.kind && validKind ? labelTranslations[props.kind] : '';

  return {
    id: uuid(),
    kind,
    label,
    relatedStatement: null,
    typicalRisk: props?.risks?.typicalRisk ?? null,
    controlRisk: props?.risks?.controlRisk ?? null,
    totalRisk: props?.risks?.totalRisk ?? null,
  };
};

export const getInitialAccountingEstimates = (): AccountingEstimate[] => [];

export const showAccountingEstimates = (formState: RisksForm) =>
  formState.planningPhaseAccountingEstimates ===
  PlanningPhaseAccountingEstimates.detected;

export enum UnderstandingInnerCircleChanges {
  documentedToReportG4 = 'documentedToReportG4',
  documentedToInnerCircleParties = 'documentedToInnerCircleParties',
}

export enum InnerCircleRisks {
  no = 'no',
  yes = 'yes',
}

export const emptyInnerCircleParty = (): InnerCircleParty => ({
  id: uuid(),
  name: '',
  basisForBelonging: '',
  transaction: '',
  basisForTransaction: '',
});

export const initialInnerCircleParties = () => [
  {
    id: uuid(),
    name: 'Esimerkiksi Oy XXX',
    basisForBelonging: 'Tytäryhtiö',
    transaction: 'Vuokraustoiminta',
    basisForTransaction: 'Sopimus',
  },
  {
    id: uuid(),
    name: 'Henkilö Y ',
    basisForBelonging: 'Toimitusjohtaja',
    transaction: 'Osingot, yhteisölle annettu laina',
    basisForTransaction: 'Lainasopimus',
  },
  {
    id: uuid(),
    name: 'OY Z',
    basisForBelonging: 'Hallituksen pj:n omistama yhteisö',
    transaction: 'Tavaran myynti',
    basisForTransaction: 'Liiketoiminta ',
  },
];

export const monetizationRiskAccordionVariant = (
  formState: RisksForm
): FormFieldVariant =>
  formState.monetizationRisk === MonetizationRisk.notRepealed
    ? 'faded'
    : undefined;

export enum CausesToDoubtAbilityToContinue {
  detected = 'detected',
  notDetected = 'notDetected',
}

export enum OtherSignificantRiskKind {
  turnover = 'turnover',
  otherOperatingIncome = 'otherOperatingIncome',
  materialsAndServices = 'materialsAndServices',
  stocks = 'stocks',
  otherOperatingExpenses = 'otherOperatingExpenses',
  financialIncome = 'financialIncome',
  financialExpenses = 'financialExpenses',
  taxes = 'taxes',
  deprecations = 'deprecations',
  tangibleAssets = 'tangibleAssets',
  loans = 'loans',
  subordinatedLoans = 'subordinatedLoans',
  accrualsAndDeferredIncome = 'accrualsAndDeferredIncome',
  tradeCreditors = 'tradeCreditors',
  other = 'other',
}

export enum OtherSignificantRiskStatementKind {
  occurence = 'occurence',
  existence = 'existence',
  completeness = 'completeness',
  accuracyAndValuation = 'accuracyAndValuation',
  cutOff = 'cutOff',
  obligationsAndRights = 'obligationsAndRights',
  classification = 'classification',
  presentation = 'presentation',
}

export const emptyOtherSignificantRiskStatement = (
  props: { risks?: RiskValues } = {}
): OtherSignificantRiskStatement => ({
  id: uuid(),
  kind: null,
  typicalRisk: props?.risks?.typicalRisk ?? null,
  controlRisk: props?.risks?.controlRisk ?? null,
  totalRisk: props?.risks?.totalRisk ?? null,
});

export const emptyOtherSignificantRisk = (
  props: { risks?: RiskValues } = {}
): OtherSignificantRisk => ({
  id: uuid(),
  name: '',
  details: '',
  kind: null,
  kindDetails: '',
  statements: [emptyOtherSignificantRiskStatement({ risks: props.risks })],
});

export enum MeetsCrisisMunicipalityCriteria {
  detected = 'detected',
  notDetected = 'notDetected',
}

export enum AssesmentProcedureCanBeInitiatedIf {
  deficitNotCovered = 'deficitNotCovered',
  deficitPerResidentTooHigh = 'deficitPerResidentTooHigh',
}

export enum EconomicKeyFiguresOverLimits {
  consolidatedIncomeStatementRatio = 'consolidatedIncomeStatementRatio',
  incomeTaxRate = 'incomeTaxRate',
  loansPerResident = 'loansPerResident',
  loanManagementMargin = 'loanManagementMargin',
}
