import { PlanningItems } from '../../../components/auditing/DetailedPlan.BatchActionsTable';
import {
  batchName,
  defaultAuditingProcedures,
  FinancialStatementBatch,
  incomeStatementAndBalanceItemHasNoRisks,
  isDefaultBatch,
  transformRemarkToAuditingProcedure,
} from '../auditing/auditingProceduresUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for auditingProcedures section!
 */

// NOTE: This section is using other section's form
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'detailedPlan';
const formSectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ store }) => ({
  auditingProcedures: [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(),
  ],
});

const formFields: FormFields<SectionFormType> = ({ auditing }) => {
  const hasNoRisks = incomeStatementAndBalanceItemHasNoRisks(auditing);

  // Helper to build financial statement batches' accordion groups
  const batchAccordion = (
    batch: FinancialStatementBatch,
    props: { open?: boolean; hidden?: boolean } = {}
  ) =>
    formBuilder.accordionGroup({
      open: props?.open,
      title: batchName(batch),
      hidden: props?.hidden ?? (!isDefaultBatch(batch) && hasNoRisks(batch)),
      items: [
        formBuilder.custom(props => (
          <PlanningItems batchKey={batch} {...props} />
        )),
      ],
    });

  return [
    batchAccordion(FinancialStatementBatch['B-commonActions']),
    batchAccordion(FinancialStatementBatch['F-financialStatement']),
    batchAccordion(FinancialStatementBatch['G-management']),
    batchAccordion(FinancialStatementBatch['J-operatingIncome']),
    batchAccordion(FinancialStatementBatch['J-turnover']),
    batchAccordion(FinancialStatementBatch['JB-otherOperatingIncome']),
    batchAccordion(FinancialStatementBatch['JA-tradeReceivables']),
    batchAccordion(FinancialStatementBatch['K-materialsAndServices']),
    batchAccordion(FinancialStatementBatch['KA-tradeCreditors']),
    batchAccordion(FinancialStatementBatch['L-personelCosts']),
    batchAccordion(FinancialStatementBatch['KB-otherOperatingExpenses']),
    batchAccordion(FinancialStatementBatch['XJ-financialIncome']),
    batchAccordion(FinancialStatementBatch['XK-financialExpenses']),
    batchAccordion(FinancialStatementBatch['O-fixedAssets']),
    batchAccordion(FinancialStatementBatch['P-inventories']),
    batchAccordion(FinancialStatementBatch['Q-receivables']),
    batchAccordion(FinancialStatementBatch['QA-bankBalances']),
    batchAccordion(FinancialStatementBatch['R-equity']),
    batchAccordion(FinancialStatementBatch['S-loansFromFinancialInstitutions']),
    batchAccordion(FinancialStatementBatch['T-otherCreditors']),
    batchAccordion(
      FinancialStatementBatch['M-governmentTransfersAndTaxRevenues']
    ),
    batchAccordion(FinancialStatementBatch['N-taxes']),
    batchAccordion(FinancialStatementBatch['U-assignmentAssetsAndLiabilities']),
  ];
};

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  formSectionKey,
  defaultFormState,
  formFields,
  depending: ['incomeStatementAndBalance'],
};

export default section;
