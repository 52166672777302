import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import Divider from '../Divider';
import { Button } from '../inputs';
import Table, { TableProps } from './Table';

const Container = styled.div`
  page-break-inside: avoid;

  @media print {
    transform: scale(0.7);
    transform-origin: top left;
  }
`;

interface Props<T> extends TableProps<T> {
  sectionKey?: AuditingSectionKey;
  onAddNewRow?: () => void;
}

function AuditingTable<T>({ sectionKey, onAddNewRow, ...props }: Props<T>) {
  const { t } = useTranslation();

  const {
    auditingStore: { auditingLocked, isAuditingSectionFinished },
  } = useStore();

  const disabled =
    auditingLocked || (sectionKey && isAuditingSectionFinished(sectionKey));

  return (
    <Container>
      <Table variant="plain" {...props} disabled={disabled} />

      {!disabled && onAddNewRow && (
        <>
          <Divider size="sm" noBorder />

          <Button
            text={'+ ' + t('action:addNewRow')}
            onClick={onAddNewRow}
            variant="link"
          />
        </>
      )}
    </Container>
  );
}

export default observer(AuditingTable);
