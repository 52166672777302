import ErrorsImpact from '../../../components/auditing/Errors.ImpactTable';
import {
  defaultAuditingProcedures,
  transformRemarkToAuditingProcedure,
} from '../auditing/auditingProceduresUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for auditingProcedures section!
 */

// NOTE: This section is using other section's form
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'errors';
const formSectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ store }) => ({
  auditingProcedures: [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(),
  ],
  shouldUpdateAuditPlanDueToErrors: null,
  shouldUpdateAuditPlanDueToErrorsDetails: '',
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.accordionGroup({
    titleKey: 'table.notFixed',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="notFixed" auditing={auditing} {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    titleKey: 'table.fixed',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="fixed" auditing={auditing} {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    titleKey: 'table.notStarted',
    open: true,
    items: [
      formBuilder.custom(props => (
        <ErrorsImpact type="notStarted" auditing={auditing} {...props} />
      )),
    ],
  }),

  formBuilder.boolean({
    accessor: 'shouldUpdateAuditPlanDueToErrors',
    options: ['no', 'yes'],
    detailsAccessor: 'shouldUpdateAuditPlanDueToErrorsDetails',
    detailsHidden: !formState.shouldUpdateAuditPlanDueToErrors,
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  formSectionKey,
  defaultFormState,
  formFields,
  depending: [],
};

export default section;
