import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  AdministrativeIndependency,
  AdministrativeParticipation,
  CommitmentToCompetence,
  CommunityHasInternalAuditing,
  CompetitiveSituation,
  CustomerBase,
  CyclicalActivity,
  DifferentiationOfWorkTasks,
  EconomicSituation,
  HonestAndEthicalCulture,
  InternalAuditingEvidence,
  InternalAuditingInquiries,
  InternalAuditingInquiriesFindings,
  InternalControlFollowsLawsAndInternalInstructions,
  InternalControlProvideAppropriateBasis,
  ManagementAttitudeToFinancialReporting,
  ManagementInvolvement,
  ManagementsReportMeetsOurUnderstanding,
  ManagementsStatementOnInternalControlMeetsOurUnderstanding,
  Market,
  OrganizationalStructureIsAppropriate,
  ProductionTecknologyLevel,
  RiskManagementFollowsLawsAndInternalInstructions,
  showAdministrativeIndependency,
  showAdministrativeParticipation,
  showInternalAuditingEvidence7c,
  showInternalAuditingInquiries7a,
  showInternalAuditingInquiriesFindings7b,
  SignificantChanges,
  SupervisionOfGroupEntitiesImplementedAppropriately,
} from './internalControlAndOperatingEnvironmentUtils';
import { PartOfTheGroup } from './permanentDataUtils';

type SectionFormType = InternalControlAndOperatingEnvironmentForm;

const sectionKey: AuditingSectionKey = 'internalControlAndOperatingEnvironment';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  inquiredRiskAssessmentProcess: '',
  inquiredRiskAssessmentProcessDate:
    auditing.auditingFrontPage?.form.auditingStartDate ?? null,
  riskAssessmentProcessDetails: '',
  market: null,
  marketDetails: '',
  cyclicalActivity: null,
  cyclicalActivityDetails: '',
  competitiveSituation: null,
  competitiveSituationDetails: '',
  customerBase: null,
  customerBaseDetails: '',
  productionTecknologyLevel: null,
  productionTecknologyLevelDetails: '',
  economicSituation: null,
  economicSituationDetails: '',
  significantChanges: null,
  significantChangesDetails: '',

  inquiredInternalControl: '',
  inquiredInternalControlDate:
    auditing.auditingFrontPage?.form.auditingStartDate ?? null,
  honestAndEthicalCulture: null,
  honestAndEthicalCultureDetails: '',
  commitmentToCompetence: null,
  commitmentToCompetenceDetails: '',
  managementInvolvement: null,
  managementInvolvementDetails: '',
  administrativeIndependency: null,
  administrativeIndependencyDetails: '',
  administrativeParticipation: null,
  administrativeParticipationDetails: '',
  differentiationOfWorkTasks: null,
  differentiationOfWorkTasksDetails: '',
  managementAttitudeToFinancialReporting: null,
  managementAttitudeToFinancialReportingDetails: '',
  communityHasInternalAuditing: null,
  communityHasInternalAuditingDetails: '',
  internalAuditingInquiries: null,
  internalAuditingInquiriesDetails: '',
  internalAuditingInquiriesFindings: null,
  internalAuditingInquiriesFindingsDetails: '',
  internalAuditingEvidence: null,
  internalAuditingEvidenceDetails: '',
  internalControlProvideAppropriateBasis: null,
  internalControlProvideAppropriateBasisDetails: '',
  organizationalStructureIsAppropriate: null,
  organizationalStructureIsAppropriateDetails: '',

  // group supervision
  groupSupervisionAndOwnershipControl: '',
  monitoringOfSubsidiariesAndPossibleDeviation: '',
  fullfillmentOfReportingObligationsOfSubsidiaries: '',
  adherenceToGroupInstructionsInMattersRequiringPriorApproval: '',
  managementsReportMeetsOurUnderstanding: null,
  summaryOfPotentialDeviationInManagementsReports: '',
  referencesToOtherRelevantWorkPapers: '',
  managementsReportsCorrectness: '',
  supervisionOfGroupEntitiesImplementedAppropriately: null,
  summaryOfPotentialDeviationInSupervisionOfGroupEntities: '',

  overallUnderstandingOfRiskManagement: '',
  riskManagementFollowsLawsAndInternalInstructions: null,
  riskManagementFollowsLawsAndInternalInstructionsDetails: '',
  possibleReferencesAndOverallUnderstandingOfLevelOfRiskManagement: '',

  overallUnderstandingOfInternalControl: '',
  internalControlFollowsLawsAndInternalInstructions: null,
  summaryOfPossibleDeviationsInInternalControl: '',
  evaluationOfManagementsStatementOnInternalControl: '',
  managementsStatementOnInternalControlMeetsOurUnderstanding: null,
  summaryOfPossibleDeviationsInManagement: '',
  possibleReferencesAndOverallUnderstandingOfLevelOfControl: '',
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingOfRiskAssessmentProcess',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'inquiredRiskAssessmentProcess',
            showContextInfo: 'top-right',
          }),
          formBuilder.dateField({
            accessor: 'inquiredRiskAssessmentProcessDate',
          }),
        ],
      }),

      formBuilder.textArea({
        accessor: 'riskAssessmentProcessDetails',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'market',
        options: Object.values(Market),
        detailsAccessor: 'marketDetails',
      }),

      formBuilder.radioGroup({
        accessor: 'cyclicalActivity',
        options: Object.values(CyclicalActivity),
        detailsAccessor: 'cyclicalActivityDetails',
        detailsHidden: formState.cyclicalActivity !== CyclicalActivity.seasonal,
      }),

      formBuilder.radioGroup({
        accessor: 'competitiveSituation',
        options: Object.values(CompetitiveSituation),
        detailsAccessor: 'competitiveSituationDetails',
      }),

      formBuilder.radioGroup({
        accessor: 'customerBase',
        options: Object.values(CustomerBase),
        detailsAccessor: 'customerBaseDetails',
        detailsHidden:
          formState.customerBase !== CustomerBase.dependentOnFewCustomers,
      }),

      formBuilder.radioGroup({
        accessor: 'productionTecknologyLevel',
        options: Object.values(ProductionTecknologyLevel),
        detailsAccessor: 'productionTecknologyLevelDetails',
      }),

      formBuilder.radioGroup({
        accessor: 'economicSituation',
        options: Object.values(EconomicSituation),
        detailsAccessor: 'economicSituationDetails',
        detailsHidden:
          formState.economicSituation !== EconomicSituation.detected,
      }),

      formBuilder.radioGroup({
        accessor: 'significantChanges',
        showContextInfo: 'bottom-left',
        options: Object.values(SignificantChanges),
        detailsAccessor: 'significantChangesDetails',
      }),

      formBuilder.textArea({
        accessor: 'overallUnderstandingOfRiskManagement',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'riskManagementFollowsLawsAndInternalInstructions',
        options: Object.values(
          RiskManagementFollowsLawsAndInternalInstructions
        ),
        detailsAccessor:
          'riskManagementFollowsLawsAndInternalInstructionsDetails',
      }),

      formBuilder.textArea({
        accessor:
          'possibleReferencesAndOverallUnderstandingOfLevelOfRiskManagement',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'understandingInternalControlComponents',
    items: [
      formBuilder.text({
        hidden:
          auditing?.permanentData?.form.companyInformation
            ?.internalControlAndRiskManagementInstructionUpToDate === true,
        textKey: 'understandingInternalControlComponentsNotUpToDate',
      }),

      formBuilder.text({
        hidden:
          auditing?.permanentData?.form.companyInformation
            ?.internalControlAndRiskManagementInstructionUpToDate !== true,
        textKey: 'understandingInternalControlComponentsUpToDate',
      }),

      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'inquiredInternalControl',
            showContextInfo: 'top-right',
          }),
          formBuilder.dateField({
            accessor: 'inquiredInternalControlDate',
          }),
        ],
      }),

      // 1
      formBuilder.radioGroup({
        accessor: 'honestAndEthicalCulture',
        options: Object.values(HonestAndEthicalCulture),
        detailsAccessor: 'honestAndEthicalCultureDetails',
      }),

      // 2
      formBuilder.radioGroup({
        accessor: 'commitmentToCompetence',
        showContextInfo: 'top-right',
        options: Object.values(CommitmentToCompetence),
        detailsAccessor: 'commitmentToCompetenceDetails',
      }),

      // 3
      formBuilder.radioGroup({
        accessor: 'managementInvolvement',
        showContextInfo: 'top-right',
        options: Object.values(ManagementInvolvement),
        detailsAccessor: 'managementInvolvementDetails',
      }),

      // 4
      formBuilder.radioGroup({
        accessor: 'administrativeIndependency',
        options: Object.values(AdministrativeIndependency),
        detailsAccessor: 'administrativeIndependencyDetails',
        detailsHidden: !showAdministrativeIndependency(formState),
      }),

      // 4a
      formBuilder.radioGroup({
        accessor: 'administrativeParticipation',
        hidden: !showAdministrativeParticipation(formState),
        showContextInfo: 'top-right',
        options: Object.values(AdministrativeParticipation),
        detailsAccessor: 'administrativeParticipationDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.administrativeParticipation !==
          AdministrativeParticipation.notAppropriate,
      }),

      // 5
      formBuilder.radioGroup({
        accessor: 'differentiationOfWorkTasks',
        options: Object.values(DifferentiationOfWorkTasks),
        detailsAccessor: 'differentiationOfWorkTasksDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.differentiationOfWorkTasks !==
          DifferentiationOfWorkTasks.belowNormal,
      }),

      // 6
      formBuilder.radioGroup({
        accessor: 'managementAttitudeToFinancialReporting',
        showContextInfo: 'top-right',
        options: Object.values(ManagementAttitudeToFinancialReporting),
        detailsAccessor: 'managementAttitudeToFinancialReportingDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.managementAttitudeToFinancialReporting !==
          ManagementAttitudeToFinancialReporting.belowNormal,
      }),

      // 7
      formBuilder.radioGroup({
        accessor: 'communityHasInternalAuditing',
        options: Object.values(CommunityHasInternalAuditing),
        detailsAccessor: 'communityHasInternalAuditingDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden: !(
          formState.communityHasInternalAuditing ===
            CommunityHasInternalAuditing.yes ||
          formState.communityHasInternalAuditing ===
            CommunityHasInternalAuditing.yesOutsourced
        ),
      }),

      // 7a
      formBuilder.radioGroup({
        accessor: 'internalAuditingInquiries',
        hidden: !showInternalAuditingInquiries7a(formState),
        options: Object.values(InternalAuditingInquiries),
        detailsAccessor: 'internalAuditingInquiriesDetails',
      }),

      // 7b
      formBuilder.radioGroup({
        accessor: 'internalAuditingInquiriesFindings',
        hidden: !showInternalAuditingInquiriesFindings7b(formState),
        options: Object.values(InternalAuditingInquiriesFindings),
        detailsAccessor: 'internalAuditingInquiriesFindingsDetails',
        detailsHidden:
          formState.internalAuditingInquiriesFindings !==
          InternalAuditingInquiriesFindings.yes,
      }),

      // 7c
      formBuilder.radioGroup({
        accessor: 'internalAuditingEvidence',
        hidden: !showInternalAuditingEvidence7c(formState),
        options: Object.values(InternalAuditingEvidence),
        detailsAccessor: 'internalAuditingEvidenceDetails',
        detailsHidden:
          formState.internalAuditingEvidence !== InternalAuditingEvidence.yes,
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'internalControlProvideAppropriateBasis',
        options: Object.values(InternalControlProvideAppropriateBasis),
        detailsAccessor: 'internalControlProvideAppropriateBasisDetails',
        detailsHidden:
          formState.internalControlProvideAppropriateBasis !==
          InternalControlProvideAppropriateBasis.no,
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'organizationalStructureIsAppropriate',
        options: Object.values(OrganizationalStructureIsAppropriate),
        detailsAccessor: 'organizationalStructureIsAppropriateDetails',
        detailsHidden:
          formState.organizationalStructureIsAppropriate !==
          OrganizationalStructureIsAppropriate.no,
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'overallUnderstandingOfInternalControl',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'internalControlFollowsLawsAndInternalInstructions',
        options: Object.values(
          InternalControlFollowsLawsAndInternalInstructions
        ),
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPossibleDeviationsInInternalControl',
      }),

      formBuilder.textArea({
        accessor: 'evaluationOfManagementsStatementOnInternalControl',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'managementsStatementOnInternalControlMeetsOurUnderstanding',
        options: Object.values(
          ManagementsStatementOnInternalControlMeetsOurUnderstanding
        ),
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPossibleDeviationsInManagement',
      }),

      formBuilder.textArea({
        accessor: 'possibleReferencesAndOverallUnderstandingOfLevelOfControl',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'groupSupervision',
    hidden:
      auditing?.permanentData?.form.isPartOfTheGroup !==
      PartOfTheGroup.yesIsParentCompany,
    items: [
      // 1
      formBuilder.subtitle({
        titleKey: 'groupInstructionsGuidelinesForGroupManagementTitle',
      }),

      formBuilder.text({
        hidden:
          auditing?.permanentData?.form.companyInformation
            .groupInstructionUpToDate === true,
        textKey: 'groupInstructionNotUpToDate',
      }),

      formBuilder.text({
        hidden:
          auditing?.permanentData?.form.companyInformation
            .groupInstructionUpToDate !== true,
        textKey: 'groupInstructionUpToDate',
      }),

      formBuilder.textArea({
        accessor: 'groupSupervisionAndOwnershipControl',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'monitoringOfSubsidiariesAndPossibleDeviation',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'fullfillmentOfReportingObligationsOfSubsidiaries',
        showContextInfo: 'top-right',
      }),

      formBuilder.textArea({
        accessor: 'adherenceToGroupInstructionsInMattersRequiringPriorApproval',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'supervisionOfGroupEntitiesImplementedAppropriately',
        options: Object.values(
          SupervisionOfGroupEntitiesImplementedAppropriately
        ),
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPotentialDeviationInSupervisionOfGroupEntities',
      }),

      // 2
      formBuilder.subtitle({
        titleKey: 'managementsReportsCorrectnessTitle',
      }),

      formBuilder.textArea({
        accessor: 'managementsReportsCorrectness',
        showContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'managementsReportMeetsOurUnderstanding',
        options: Object.values(ManagementsReportMeetsOurUnderstanding),
      }),

      formBuilder.textArea({
        accessor: 'summaryOfPotentialDeviationInManagementsReports',
      }),

      // 3
      formBuilder.subtitle({
        titleKey: 'referencesToOtherRelevantWorkPapersTitle',
      }),

      formBuilder.textArea({
        accessor: 'referencesToOtherRelevantWorkPapers',
        showContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
