import { GroupedGeneralLedgerTable } from '../../../components/auditing/GeneralLedger.GroupedTable';
import { UploadGeneralLedger } from '../../../components/auditing/GeneralLedger.UploadGeneralLedger';
import { ParseMethod } from './generalLedgerUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = GeneralLedgerForm;

const sectionKey: AuditingSectionKey = 'generalLedger';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  parseMethod: ParseMethod.groupedAccounts,
  groupedGeneralLedger: [],
};

const formFields: FormFields<SectionFormType> = [
  formBuilder.custom(props => <UploadGeneralLedger {...props} />),
  formBuilder.custom(props => <GroupedGeneralLedgerTable {...props} />),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMap'],
};

export default section;
