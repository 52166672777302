import React, { ChangeEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDateTime, isDate } from '../../utils';
import Divider from '../Divider';
import {
  Radio,
  InputGroup,
  TextArea,
  TextInput,
  Checkbox,
  Select,
} from '../inputs';
import Table from '../table/Table';

const ComputedValue = styled.div`
  display: inline-block;
  padding: ${p => p.theme.spacing.md};
  background-color: ${p => p.theme.color.grey100};
  border: 1px solid ${p => p.theme.color.grey200};
  border-radius: ${p => p.theme.borderRadius.sm};
  font-weight: bold;
`;

type HandleInputChange<T> = (
  e: ChangeEvent<HTMLInputElement>,
  parentGroupKey?: keyof T
) => void;

type HandleTextAreaOrInputChange = (
  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void;

interface Props<T> {
  translationBase: string;
  formField: FormField<T>;
  formState: T;
  formFieldProps: FormFieldProps<T>;
  showRemark?: boolean;
  disabled?: boolean;
  handleTextChange: HandleTextAreaOrInputChange;
  handleNumberChange: HandleTextAreaOrInputChange;
  handleDateChange: HandleInputChange<T>;
  handleBooleanChange: HandleInputChange<T>;
  handleBooleanOptionChange: HandleInputChange<T>;
  handleSelectChange: <SelectType>(
    fieldName: keyof T
  ) => (value: SetStateAction<SelectType | undefined>) => void;
}

function FormElement<T>({
  translationBase,
  formField,
  formState,
  formFieldProps,
  showRemark,
  disabled: formDisabled,
  handleTextChange,
  handleDateChange,
  handleNumberChange,
  handleBooleanChange,
  handleBooleanOptionChange,
  handleSelectChange,
}: Props<T>) {
  const { t } = useTranslation();

  const translate = (key: string, value?: string | number) => {
    let formattedValue = value;
    if (value) {
      if (isDate(value)) {
        formattedValue = formatDateTime(`${value}`, { format: 'DDDD' });
      }
    }
    return t(`${translationBase}.${key}`, { value: formattedValue });
  };

  const getStringValue = (accessor?: FormFieldAccessor<T>) => {
    if (!accessor) return '';
    return (formState[accessor] as unknown as string) || '';
  };

  const getBooleanValue = (
    accessor?: FormFieldAccessor<T>,
    childAccessor?: string
  ) => {
    if (!accessor) return null;
    let value = null;
    if (childAccessor) value = (formState[accessor] as any)?.[childAccessor];
    else value = formState[accessor];
    return value === null ? null : !!value;
  };

  const getTableData = (accessor?: FormFieldAccessor<T>) => {
    if (!accessor) return [];
    return (formState[accessor] as unknown as any[]) ?? [];
  };

  const {
    accessor,
    type,
    title,
    text,
    placeholder,
    contextInfo,
    options,
    optionDisplayValue,
    columns,
    disabled: fieldDisabled,
    hidden,
    render,
    customOnChange, // TODO: Currently custom onChange supports only SELECT and RADIO GROUP inputs!
  } = formField;

  if (hidden) return null;

  const accessorString = String(accessor);
  const stringValue = getStringValue(accessor);

  const commonProps = {
    name: accessorString,
    label: translate(accessorString, stringValue),
    placeholder,
    contextInfo,
    disabled: formDisabled || fieldDisabled,
    showRemark,
  };

  switch (type) {
    /**
     * TEXT AREA
     */
    case 'textArea':
      return (
        <TextArea
          value={stringValue}
          onChange={handleTextChange}
          fullWidth
          {...commonProps}
        />
      );

    /**
     * TEXT INPUT
     */
    case 'textInput':
      return (
        <TextInput
          value={stringValue}
          onChange={handleTextChange}
          fullWidth
          {...commonProps}
        />
      );

    /**
     * NUMBER INPUT
     */
    case 'numberInput':
      return (
        <TextInput
          type="number"
          value={stringValue}
          onChange={handleNumberChange}
          {...commonProps}
        />
      );

    /**
     * DATE INPUT
     */
    case 'dateInput':
      return (
        <TextInput
          type="date"
          value={stringValue}
          onChange={handleDateChange}
          {...commonProps}
        />
      );

    /**
     * BOOLEAN (CHECKBOX OR TWO RADIO BUTTONS)
     */
    case 'boolean':
      const booleanValue = getBooleanValue(accessor);

      if (options?.length === 2) {
        const booleanOptionProps = {
          name: accessorString,
          onChange: handleBooleanOptionChange,
        };

        /**
         * Instead of checkbox, render two radio buttons if (two) options were provided
         */
        return (
          <InputGroup {...commonProps}>
            <Radio
              value="false"
              checked={booleanValue === false}
              label={t(`action:${options[0]}`)}
              {...booleanOptionProps}
              disabled={commonProps.disabled}
            />
            <Radio
              value="true"
              checked={!!booleanValue}
              label={t(`action:${options[1]}`)}
              {...booleanOptionProps}
              disabled={commonProps.disabled}
            />
          </InputGroup>
        );
      }

      /**
       * Render checkbox (if no radio options were provided)
       */
      return (
        <Checkbox
          {...commonProps}
          checked={!!booleanValue}
          onChange={handleBooleanChange}
        />
      );

    /**
     * CHECKBOX GROUP
     */
    case 'checkboxGroup':
      if (!accessor) return null;
      return (
        <InputGroup {...commonProps}>
          {options?.map(option => {
            const optionValue = getBooleanValue(accessor, option);
            return (
              <Checkbox
                key={`${accessorString}-${option}`}
                name={option}
                checked={!!optionValue}
                label={optionDisplayValue ? optionDisplayValue(option) : option}
                onChange={e => handleBooleanChange(e, accessor)}
                variant="light"
                disabled={commonProps.disabled}
              />
            );
          })}
        </InputGroup>
      );

    /**
     * RADIO BUTTON GROUP
     * Similar to Select, but all options are always visible
     */
    case 'radioGroup':
      return (
        <InputGroup {...commonProps}>
          {options?.map(option => (
            <Radio
              key={option}
              name={accessorString}
              value={option}
              checked={option === stringValue}
              label={optionDisplayValue ? optionDisplayValue(option) : option}
              onChange={e => {
                const value = e.currentTarget.value;
                if (customOnChange) customOnChange(value, formFieldProps);
                else handleSelectChange(accessorString as keyof T)(value);
              }}
              disabled={commonProps.disabled}
            />
          ))}
        </InputGroup>
      );

    /**
     * SELECT FORM FIELD
     */
    case 'selectAutocomplete':
    case 'select':
      return (
        <Select
          {...commonProps}
          autocomplete={type === 'selectAutocomplete'}
          value={stringValue}
          options={options ?? []}
          displayValue={optionDisplayValue}
          setValue={(value: React.SetStateAction<string | undefined>) => {
            if (customOnChange) customOnChange(value as string, formFieldProps);
            else handleSelectChange(accessorString as keyof T)(value);
          }}
        />
      );

    /**
     * TABLE
     */
    case 'table':
      return (
        <Table
          {...commonProps}
          data={getTableData(accessor)}
          columns={columns ?? []}
          title={title}
          showGlobalFilter={false}
          variant="plain"
        />
      );

    /**
     * COMPUTED VALUE
     */
    case 'computed':
      return (
        <div>
          <ComputedValue {...commonProps}>
            {typeof render === 'function' ? render(formFieldProps) : render}
          </ComputedValue>
        </div>
      );

    /**
     * CUSTOM RENDER
     */
    case 'custom':
      return (
        <div>
          {typeof render === 'function' ? render(formFieldProps) : render}
        </div>
      );

    case 'text':
      return <p>{text}</p>;

    /**
     * SEPARATOR
     */
    case 'separator':
      return <Divider size="xl" />;

    case 'subtitle':
      return <h2>{title}</h2>;

    default:
      return null;
  }
}

export default FormElement;
