import { formatDateTime, localizedDate } from './date';

export enum AuditingTemplate {
  private = 'private',
  public = 'public',
}

type OmittedSections = {
  [AuditingTemplate.private]: AuditingSectionKey[];
  [AuditingTemplate.public]: AuditingSectionKey[];
};

export const OMITTED_SECTIONS: OmittedSections = Object.freeze({
  [AuditingTemplate.private]: [
    'governmentTransfersAndTaxRevenues',
    'management',
  ],
  [AuditingTemplate.public]: [],
});

export const sortAuditings = (a: Auditing, b: Auditing) => {
  return (
    localizedDate(b.startDate).toMillis() -
    localizedDate(a.startDate).toMillis()
  );
};

export const financialYear = (auditing?: Auditing) => {
  if (!auditing) return '';

  const format = 'D';
  const startDate = formatDateTime(auditing.startDate, { format });
  const endDate = formatDateTime(auditing.endDate, { format });
  return `${startDate}-${endDate}`;
};

export const getClassNameForFinancialStatementRow = (
  headingLevel?: AccountRowHeadingLevel
) =>
  headingLevel === 1
    ? 'text-bold text-uppercase'
    : headingLevel && headingLevel >= 2
    ? 'text-bold'
    : 'pull-right';

/**
 * Parses a comma separated list of account ranges.
 *
 * @param accountRanges A comma separated list of account ranges, e.g. "1000-1999,3000,4000-4499,6000"
 * @returns An array of account ranges, e.g. [[1000, 1999], [3000, 3000], [4000, 4499], [6000, 6000]]
 */
const parseAccountRanges = (accountRanges: string): number[][] =>
  accountRanges
    // Strip everything except numbers, commas and dashes
    .replace(/[^0-9,-]/g, '')
    // Split into ranges
    .split(',')
    // Split each range into start and end (end is optional)
    .map(item => item.split('-').map(Number));

/**
 * Sums up the current and prior year values of a list of grouped general ledger items.
 * @param generalLedgerItems A list of grouped general ledger items
 * @returns An object containing the summed up current and prior year values
 */
const sumCurrentAndPriorYearValues = (
  generalLedgerItems: GroupedGeneralLedgerItem[]
) =>
  generalLedgerItems.reduce(
    (acc, { currentYear, priorYear }) => ({
      currentYear: acc.currentYear + (currentYear ?? 0),
      priorYear: acc.priorYear + (priorYear ?? 0),
    }),
    { currentYear: 0, priorYear: 0 }
  );

export const getSummedAccountItems = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): SummedAccountItem[] => {
  return Object.keys(accountMappings).map(key => {
    const accountRanges = parseAccountRanges(accountMappings[key]);

    // Filter out all rows that don't match the account range
    const matchingAccountRows = (groupedGeneralLedger ?? []).filter(
      ({ account }) => {
        return accountRanges.some(([start, end]) => {
          if (start && end) return account >= start && account <= end;
          if (start) return account === start;
          return false;
        });
      }
    );

    // Sum up the current and prior year values
    const { currentYear, priorYear } =
      sumCurrentAndPriorYearValues(matchingAccountRows);

    return {
      id: key,
      accountKey: key,
      accountName: key,
      currentYear,
      priorYear,
      accountRange: accountMappings[key],
    };
  });
};

export const getAccountMappingValues = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): AccountMappingValues => {
  const accountMappingValues = getSummedAccountItems(
    groupedGeneralLedger,
    accountMappings
  );

  return accountMappingValues.reduce(
    (acc, { accountKey, currentYear, priorYear }) => ({
      ...acc,
      [accountKey]: {
        cy: currentYear ?? 0,
        py: priorYear ?? 0,
      },
    }),
    {}
  );
};
