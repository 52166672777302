export enum ErrorsFixedByManagement {
  fixed,
  notFixedButForReasonableReasons,
  notFixed,
}

export enum AuditingReportFormat {
  standard = 'standard',
  negativeStatement = 'negativeStatement',
  reserverStatement = 'reserverStatement',
  noStatement = 'noStatement',
}

export enum OtherInformationIncludedInAuditingReport {
  no = 'no',
  yesButNoErrors = 'yesButNoErrors',
  yesWithErrors = 'yesWithErrors',
}

export enum OtherReportingOblications {
  no = 'no',
  otherReportingOblications = 'otherReportingOblications',
}

export enum OtherReportingToManagement {
  noOtherReporting = 'noOtherReporting',
  auditingReport = 'auditingReport',
  auditingMemo = 'auditingMemo',
  other = 'other',
}

export enum SignOffConfirmations {
  topic1 = 'topic1',
  topic2 = 'topic2',
  topic3 = 'topic3',
  topic4 = 'topic4',
  topic5 = 'topic5',
  topic6 = 'topic6',
  topic7 = 'topic7',
  topic8 = 'topic8',
  topic9 = 'topic9',
}

export enum QualityControlConfirmations {
  topic1 = 'topic1',
  topic2 = 'topic2',
  topic3 = 'topic3',
  topic4 = 'topic4',
  topic5 = 'topic5',
  topic6 = 'topic6',
}
