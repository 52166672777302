import CompletedProcedures from '../../../components/auditing/Closure.CompletedProcedures';
import {
  defaultClosureProcedures,
  DetectedEventsToDoubtAbilityToContinue,
  InquiredAbilityToContinue,
  InquiredIfRegulationsHaveBeenCompliedWith,
  InquiredWhetherAnyAbusesAreKnown,
} from './closureUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = ClosureForm;

const sectionKey: AuditingSectionKey = 'closure';

export const tBase = `auditing:form.closure`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  // PART I
  needToUpdateEstimatesOrMateriality: null,
  needToUpdateEstimatesOrMaterialityDetails: '',
  closingPhaseDiscussionsWithManagementDate: '',
  closingPhaseDiscussionsWithManagementDetails: '',
  inquiredIfRegulationsHaveBeenCompliedWith: null,
  inquiredIfRegulationsHaveBeenCompliedWithDetails: '',
  inquiredWhetherAnyAbusesAreKnown: null,
  inquiredWhetherAnyAbusesAreKnownDetails: '',
  abusesDetected: null,
  abusesDetectedDetails: '',
  managementDecisionsMayHaveCreatedRiskBias: null,
  managementDecisionsMayHaveCreatedRiskBiasDetails: '',
  suspiciousEventsDetected: null,
  suspiciousEventsDetectedDetails: '',
  analyticalMeasuresHaveConfirmedTheConclusions: null,
  analyticalMeasuresHaveConfirmedTheConclusionsDetails: '',

  // PART II
  completedProcedures: defaultClosureProcedures(),
  completedProceduresDetails: '',
  detectedEventsForWhichAdjustmentsShouldBeMade: null,
  detectedEventsForWhichAdjustmentsShouldBeMadeDetails: '',
  detectedEventsProperlyTakenIntoAccount: null,
  detectedEventsProperlyTakenIntoAccountDetails: '',
  inquiredAbilityToContinue: null,
  inquiredAbilityToContinueDetails: '',
  detectedEventsToDoubtAbilityToContinue: null,
  detectedEventsToDoubtAbilityToContinueDetails: '',
};

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  /**
   * PART I
   */

  formBuilder.boolean({
    options: ['no', 'yes'],
    accessor: 'needToUpdateEstimatesOrMateriality',
    detailsAccessor: 'needToUpdateEstimatesOrMaterialityDetails',
    showContextInfo: 'bottom-left',
    showDetailsContextInfo: 'top-right',
    detailsHidden: !formState.needToUpdateEstimatesOrMateriality,
  }),

  formBuilder.group({
    items: [
      formBuilder.dateField({
        accessor: 'closingPhaseDiscussionsWithManagementDate',
      }),
      formBuilder.textArea({
        accessor: 'closingPhaseDiscussionsWithManagementDetails',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  formBuilder.radioGroup({
    options: Object.values(InquiredIfRegulationsHaveBeenCompliedWith),
    accessor: 'inquiredIfRegulationsHaveBeenCompliedWith',
    detailsAccessor: 'inquiredIfRegulationsHaveBeenCompliedWithDetails',
    showDetailsContextInfo: 'top-right',
    detailsHidden:
      formState.inquiredIfRegulationsHaveBeenCompliedWith !==
      InquiredIfRegulationsHaveBeenCompliedWith.notComplied,
  }),

  formBuilder.radioGroup({
    options: Object.values(InquiredWhetherAnyAbusesAreKnown),
    accessor: 'inquiredWhetherAnyAbusesAreKnown',
    detailsAccessor: 'inquiredWhetherAnyAbusesAreKnownDetails',
    showDetailsContextInfo: 'top-right',
    detailsHidden:
      formState.inquiredWhetherAnyAbusesAreKnown !==
      InquiredWhetherAnyAbusesAreKnown.known,
  }),

  formBuilder.boolean({
    options: ['no', 'yes'],
    accessor: 'abusesDetected',
    detailsAccessor: 'abusesDetectedDetails',
    showDetailsContextInfo: 'top-right',
    detailsHidden: !formState.abusesDetected,
  }),

  formBuilder.boolean({
    options: ['no', 'yes'],
    accessor: 'managementDecisionsMayHaveCreatedRiskBias',
    detailsAccessor: 'managementDecisionsMayHaveCreatedRiskBiasDetails',
    showDetailsContextInfo: 'top-right',
    detailsHidden: !formState.managementDecisionsMayHaveCreatedRiskBias,
  }),

  formBuilder.boolean({
    options: ['no', 'yes'],
    accessor: 'suspiciousEventsDetected',
    detailsAccessor: 'suspiciousEventsDetectedDetails',
    showDetailsContextInfo: 'top-right',
    detailsHidden: !formState.suspiciousEventsDetected,
  }),

  formBuilder.boolean({
    options: ['no', 'yes'],
    accessor: 'analyticalMeasuresHaveConfirmedTheConclusions',
    detailsAccessor: 'analyticalMeasuresHaveConfirmedTheConclusionsDetails',
    showDetailsContextInfo: 'top-right',
  }),

  /**
   * PART II
   */

  formBuilder.accordionGroup({
    titleKey: 'eventsBetweenTitle',
    open: true,
    items: [
      formBuilder.text({ textKey: 'eventsBetweenInfo' }),

      formBuilder.custom(props => <CompletedProcedures {...props} />),

      formBuilder.boolean({
        accessor: 'detectedEventsForWhichAdjustmentsShouldBeMade',
        options: ['no', 'yes'],
        detailsAccessor: 'detectedEventsForWhichAdjustmentsShouldBeMadeDetails',
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.boolean({
        accessor: 'detectedEventsProperlyTakenIntoAccount',
        options: ['no', 'yes'],
        detailsAccessor: 'detectedEventsProperlyTakenIntoAccountDetails',
        showDetailsContextInfo: 'top-right',
        hidden: !formState.detectedEventsForWhichAdjustmentsShouldBeMade,
      }),

      formBuilder.radioGroup({
        accessor: 'inquiredAbilityToContinue',
        options: Object.values(InquiredAbilityToContinue),
        detailsAccessor: 'inquiredAbilityToContinueDetails',
        showDetailsContextInfo: 'top-right',
      }),

      formBuilder.radioGroup({
        accessor: 'detectedEventsToDoubtAbilityToContinue',
        options: Object.values(DetectedEventsToDoubtAbilityToContinue),
        detailsAccessor: 'detectedEventsToDoubtAbilityToContinueDetails',
        showDetailsContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['auditingProcedures'],
};

export default section;
