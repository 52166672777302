import React from 'react';
import { useTranslation } from 'react-i18next';
import AuditingTable from '../../../table/AuditingTable';
import { TableColumnDefs } from '../../../table/Table';
import { OnEditProps } from '../../../table/TableInputs';

interface Props extends FormFieldProps<PersonelCostsForm> {}

const PersonelCostsCommonFormFields: React.FC<Props> = ({
  formState,
  patchFormState,
}) => {
  const { t } = useTranslation();

  function handleEdit<T>({ columnId, value }: OnEditProps<T>) {
    const validIds = ['averageNumberOfPersonsCY', 'averageNumberOfPersonsPY'];

    if (columnId && validIds.includes(columnId)) {
      patchFormState({
        ...formState,
        [columnId]: Number(value),
      });
    }
  }

  const data = [
    {
      id: 'averageNumberOfPersons',
      label: t('auditing:form.personelCosts.averageNumberOfPersons'),
      averageNumberOfPersonsCY: formState.averageNumberOfPersonsCY,
      averageNumberOfPersonsPY: formState.averageNumberOfPersonsPY,
    },
  ];

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'width-full text-bold',
      header: () => null,
    },
    {
      id: 'averageNumberOfPersonsCY',
      accessorKey: 'averageNumberOfPersonsCY',
      header: () => 'CY',
      className: 'width-min text-center',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      id: 'averageNumberOfPersonsPY',
      accessorKey: 'averageNumberOfPersonsPY',
      header: () => 'PY',
      className: 'width-min text-center',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
  ];

  return (
    <AuditingTable
      data={data}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export default PersonelCostsCommonFormFields;
