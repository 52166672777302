import AccountMapResetButton from '../../../components/auditing/AccountMap.ResetButton';
import AccountMapTable from '../../../components/auditing/AccountMap.Table';
import { AuditingTemplate } from '../../../utils';
import { generateAccountMap } from './accountMapUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = AccountMapForm;

const sectionKey: AuditingSectionKey = 'accountMap';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  accountMap: generateAccountMap(auditing.template ?? AuditingTemplate.private),
});

const accountMapKeys: (keyof AccountMap)[] = [
  'incomeStatementAccountMap',
  'balanceSheetAssetsAccountMap',
  'balanceSheetLiabilitiesAccountMap',
];

const formFields: FormFields<SectionFormType> = [
  formBuilder.group({
    items: [
      formBuilder.custom(props => <AccountMapResetButton {...props} />),
      formBuilder.text({ textKey: 'infoText' }),
    ],
  }),

  ...accountMapKeys.map(key =>
    formBuilder.accordionGroup({
      open: true,
      titleKey: key,
      items: [
        formBuilder.custom(props => (
          <AccountMapTable targetKey={key} {...props} />
        )),
      ],
    })
  ),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
