import { IsDecisionMade } from './auditing/governmentTransfersAndTaxRevenuesUtils';
import {
  ManagementsReportMeetsOurUnderstanding,
  RiskManagementFollowsLawsAndInternalInstructions,
  SupervisionOfGroupEntitiesImplementedAppropriately,
} from './planning/internalControlAndOperatingEnvironmentUtils';
import {
  InternalControlFollowsLawsAndInternalInstructions,
  ManagementsStatementOnInternalControlMeetsOurUnderstanding,
} from './planning/internalControlAndOperatingEnvironmentUtils';
import {
  FailureToNotify,
  LegalSituation,
  TaxDebtInformation,
} from './planning/permanentDataUtils';
import {
  assetsLiabilitiesDifferenceAccessor,
  auditorInformationAccessor,
  hasWagesAndSalariesAccessor,
  makeRemarkRule,
  reviewOfPriorityBoardsNotesAccessor,
  reviewOfPriorityOfficialsNotesAccessor,
  councilMeetingMinutesNotesAccessor,
  boardMeetingMinutesNotesAccessor,
} from './remarkRuleUtils';

/**
 * Define remark rules for PERMANENT DATA section.
 */
const permanentDataRules: AuditingSectionRule[] = [
  {
    rule: ['auditorInformation.title', []],
    depends: [[auditorInformationAccessor, [true]]],
  },
  {
    // E.g. below field gets "remarked" IF its value is false (user answered "No")
    // AND its depending field (isFirstAuditing) has value true (user answered "Yes")
    rule: ['isCustomerApprovalAndCustomerIdentificationCompleted', [false]],
    depends: [['isFirstAuditing', [true]]],
  },
  {
    rule: ['isMattersThatAffectAssignment', [true]],
    depends: [['isFirstAuditing', [false]]],
  },
  { rule: ['areTermsAgreedAndUpToDate', [false]] },
  { rule: ['properIdentificationOfRepresentatives', [false]] },
  { rule: ['companyInformation.capitalStockUpToDate', [false]] },
  { rule: ['companyInformation.numberOfStocksUpToDate', [false]] },
  { rule: ['companyInformation.boardChairmanUpToDate', [false]] },
  { rule: ['companyInformation.boardMember1UpToDate', [false]] },
  { rule: ['companyInformation.boardMember2UpToDate', [false]] },
  { rule: ['companyInformation.boardMember3UpToDate', [false]] },
  { rule: ['companyInformation.boardDeputyMemberUpToDate', [false]] },
  { rule: ['companyInformation.ceoUpToDate', [false]] },
  { rule: ['companyInformation.deputyCeoUpToDate', [false]] },
  { rule: ['companyInformation.administrativeRuleUpToDate', [false]] },
  { rule: ['companyInformation.municipalStrategyUpToDate', [false]] },
  { rule: ['companyInformation.groupInstructionUpToDate', [false]] },
  {
    rule: [
      'companyInformation.internalControlAndRiskManagementInstructionUpToDate',
      [false],
    ],
  },
  { rule: ['companyInformation.purchaseInstructionUpToDate', [false]] },
  { rule: ['companyInformation.riskManagementGuidelinesUpToDate', [false]] },
  { rule: ['isEquityNegativityRegistered', [false]] },
  {
    rule: ['upToDateListsOfSharesAndShareholders', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  {
    rule: ['advanceCollectionRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  { rule: ['liableForVATOnBusiness', [false]] },
  {
    rule: ['liableForVATOnProperty', [false]],
    depends: [['hasPropertyRentalActivities', [true]]],
  },
  {
    rule: ['employerRegister', [false]],
    depends: [
      [hasWagesAndSalariesAccessor, [true], 'incomeStatementAndBalance'],
    ],
  },
  {
    rule: ['isShareholderAgreementDone', [false]],
    depends: [['hasOnlyOneOwner', [false]]],
  },
  { rule: ['taxDebtInformation', [TaxDebtInformation.taxDebtOverLimit]] },
  { rule: ['failureToNotify', [FailureToNotify.defects]] },
  {
    rule: [
      'legalSituation',
      Object.values(LegalSituation).filter(
        value => value !== LegalSituation.normal
      ),
    ],
  },
];

/**
 * Define remark rules for INCOME STATEMENT AND BALANCE section.
 */
const incomeStatementAndBalanceRules: AuditingSectionRule[] = [
  {
    rule: [assetsLiabilitiesDifferenceAccessor, [true]],
    accessorKey: 'assetsLiabilitiesDifference',
  },
];

/**
 * Define remark rules for GOVERNMENT TRANSFERS AND TAX REVENUES section.
 */
const governmentTransfersAndTaxRevenuesRules: AuditingSectionRule[] = [
  {
    rule: [
      'settlingForGovernmentSharesStudentInformation',
      [IsDecisionMade.no],
    ],
  },
  {
    rule: ['settlingForGovernmentSharesBasicServices', [IsDecisionMade.no]],
  },
];

/**
 * Define remark rules for G - MANAGEMENT section.
 */
const managementRules: AuditingSectionRule[] = [
  { rule: ['municipalStrategyUpToDate', [false]] },
  { rule: ['administrativeRuleUpToDate', [false]] },
  { rule: ['budgetUpToDate', [false]] },
  { rule: ['financialStatementUpToDate', [false]] },
  { rule: ['auditBoardEvaluationReportUpToDate', [false]] },
  { rule: ['auditReportUpToDate', [false]] },
  { rule: ['municipalCooperationAgreementsUpToDate', [false]] },
  { rule: ['groupInstructionUpToDate', [false]] },
  { rule: ['boardCommitmentsUpToDate', [false]] },
  { rule: ['boardRewardJustificationsUpToDate', [false]] },
  { rule: ['serviceFeesUpToDate', [false]] },
  { rule: ['programmeOfMeasuresUpToDate', [false]] },
  { rule: ['administrativeRuleMeetsRequirementsUpToDate', [false]] },
  { rule: ['budgetAndMunicipalStrategyInLineUpToDate', [false]] },
  { rule: ['isBudgetRealisticUpToDate', [false]] },
  { rule: ['budgetAndChangesProperlyApprovedUpToDate', [false]] },
  { rule: ['councilGuidelinesForInternalInstructionsUpToDate', [false]] },
  { rule: ['internalInstructionsMeetCouncilGuidelinesUpToDate', [false]] },
  { rule: ['internalInstructionsUpToDateUpToDate', [false]] },
  { rule: ['currentPracticesAreValidUpToDate', [false]] },
  { rule: ['planningOfBudgetAndInvestmentsUpToDate', [false]] },
  { rule: ['groupSupervisionAndReportingUpToDate', [false]] },
  { rule: ['informingAndInformationAccessUpToDate', [false]] },
  {
    rule: [
      'obligationToGetMunicipalitysOpinionBeforeDecisionsUpToDate',
      [false],
    ],
  },
  { rule: ['groupsInternalServicesUpToDate', [false]] },
  { rule: ['subsidiaryBoardCompositionAndAppointmentUpToDate', [false]] },
  { rule: ['subsidiaryGoodManagementAndLeadershipUpToDate', [false]] },
  { rule: ['previousYearFinancialStatementConfirmedUpToDate', [false]] },
  { rule: ['nextYearBudgetApprovedUpToDate', [false]] },
  { rule: ['handlingOfBudgetChangesUpToDate', [false]] },
  { rule: ['isBudgetReviewedEveryQuarterUpToDate', [false]] },
  { rule: ['affiliationNoticesUpToDate', [false]] },
  { rule: ['evaluationReportUpToDate', [false]] },
  {
    rule: [reviewOfPriorityBoardsNotesAccessor, [true]],
    accessorKey: 'reviewOfPriorityBoardsNotesRemarks',
  },
  {
    rule: [reviewOfPriorityOfficialsNotesAccessor, [true]],
    accessorKey: 'reviewOfPriorityOfficialsNotesRemarks',
  },
  {
    rule: [councilMeetingMinutesNotesAccessor, [true]],
    accessorKey: 'councilMeetingMinutesNotesRemarks',
  },
  {
    rule: [boardMeetingMinutesNotesAccessor, [true]],
    accessorKey: 'boardMeetingMinutesNotesRemarks',
  },
];

/*
 * Define remark rules for INTERNAL CONTROL AND OPERATING ENVIRONMENT section.
 */
const internalControlAndOperatingEnvironmentRules: AuditingSectionRule[] = [
  {
    rule: [
      'managementsReportMeetsOurUnderstanding',
      [ManagementsReportMeetsOurUnderstanding.no],
    ],
  },
  {
    rule: [
      'supervisionOfGroupEntitiesImplementedAppropriately',
      [SupervisionOfGroupEntitiesImplementedAppropriately.no],
    ],
  },
  {
    rule: [
      'riskManagementFollowsLawsAndInternalInstructions',
      [RiskManagementFollowsLawsAndInternalInstructions.no],
    ],
  },
  {
    rule: [
      'internalControlFollowsLawsAndInternalInstructions',
      [InternalControlFollowsLawsAndInternalInstructions.no],
    ],
  },
  {
    rule: [
      'managementsStatementOnInternalControlMeetsOurUnderstanding',
      [ManagementsStatementOnInternalControlMeetsOurUnderstanding.no],
    ],
  },
];

export const REMARK_RULES: RemarkRule[] = [
  ...permanentDataRules.map(makeRemarkRule('permanentData')),
  ...incomeStatementAndBalanceRules.map(
    makeRemarkRule('incomeStatementAndBalance')
  ),
  ...governmentTransfersAndTaxRevenuesRules.map(
    makeRemarkRule('governmentTransfersAndTaxRevenues')
  ),
  ...managementRules.map(makeRemarkRule('management')),
  ...internalControlAndOperatingEnvironmentRules.map(
    makeRemarkRule('internalControlAndOperatingEnvironment')
  ),
];
