import React from 'react';
import { ContextInfoProps } from '../ContextInfo';
import { Label } from './Label';

interface Props {
  label: string;
  contextInfo?: ContextInfoProps;
  required?: boolean;
  disabled?: boolean;
  showRemark?: boolean;
  children: React.ReactNode;
}

export const InputGroup: React.FC<Props> = ({
  label,
  contextInfo,
  required,
  disabled,
  showRemark,
  children,
}) => (
  <div>
    {label && (
      <Label
        disabled={disabled}
        contextInfo={contextInfo}
        className={required ? 'is-required' : ''}
        showRemark={showRemark}
      >
        {label}
      </Label>
    )}
    {children}
  </div>
);

export default InputGroup;
