import { AccountClassKey, AccountKey } from './accountMapKeys';

const accountMap: AccountMap = {
  incomeStatementAccountMap: [
    {
      key: AccountKey.operatingIncome,
      heading: 1,
      start: 3000,
      end: 3599,
    },
    {
      key: AccountKey.salesProceeds,
      heading: 2,
      start: 3000,
      end: 3199,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.feeIncome,
      heading: 2,
      start: 3200,
      end: 3299,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.subsidiesAndGrants,
      heading: 2,
      start: 3300,
      end: 3339,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.public_otherOperatingIncome,
      heading: 2,
      start: 3400,
      end: 3559,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.rentalIncome,
      start: 3400,
      end: 3499,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.otherIncome,
      start: 3500,
      end: 3559,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.subsidiesAndGrantsFromMunicipality,
      heading: 2,
      start: 3560,
      end: 3560,
      classKey: AccountClassKey['J-operatingIncome'],
    },
    {
      key: AccountKey.changeInInventory,
      heading: 2,
      start: 3600,
      end: 3699,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.manufactureForOwnUse,
      heading: 1,
      start: 3700,
      end: 3799,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.operatingExpenses,
      heading: 1,
      start: 4000,
      end: 4999,
    },
    {
      key: AccountKey.personelCosts,
      heading: 2,
      start: 4000,
      end: 4299,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.wagesAndSalaries,
      start: 4000,
      end: 4099,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.socialSecurityCosts,
      start: 4100,
      end: 4299,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.pensionExpences,
      start: 4100,
      end: 4149,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.otherSocialSecurityCosts,
      start: 4150,
      end: 4299,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.servicesPurchases,
      heading: 2,
      start: 4300,
      end: 4499,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.customerServicePurchases,
      start: 4300,
      end: 4339,
      classKey: AccountClassKey['K-materialsAndServices'],
    },
    {
      key: AccountKey.otherServicePurchases,
      start: 4340,
      end: 4499,
      classKey: AccountClassKey['K-materialsAndServices'],
    },
    {
      key: AccountKey.materialsSupliesAndGoods,
      heading: 2,
      start: 4500,
      end: 4699,
      classKey: AccountClassKey['K-materialsAndServices'],
    },
    {
      key: AccountKey.purchases,
      start: 4500,
      end: 4699,
      classKey: AccountClassKey['K-materialsAndServices'],
    },
    {
      key: AccountKey.grants,
      heading: 2,
      start: 4700,
      end: 4799,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.householdGrants,
      start: 4700,
      end: 4739,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.public_otherOperatingExpenses,
      heading: 2,
      start: 4800,
      end: 4997,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.rentalExpenses,
      start: 4800,
      end: 4899,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.otherExpenses,
      start: 4900,
      end: 4995,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.otherOperatingExpenses,
      heading: 2,
      start: 4700,
      end: 4995,
      classKey: AccountClassKey['KB-otherOperatingExpenses'],
    },
    {
      key: AccountKey.profitOrLossOfAssociatedEntities,
      heading: 2,
      start: 4996,
      end: 4999,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.profitMargin,
      heading: 1,
      start: 3000,
      end: 4999,
    },
    {
      key: AccountKey.taxRevenues,
      heading: 2,
      start: 5000,
      end: 5499,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.governmentTransfers,
      heading: 2,
      start: 5500,
      end: 5899,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.governmentTransfers_municipalitysBasicServices,
      start: 5501,
      end: 5520,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.governmentTransfers_educationalAndCulturalActivity,
      start: 5700,
      end: 5899,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.governmentTransfers_other,
      start: 5800,
      end: 5870,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.compensationForLossOfTaxRevenue,
      start: 5890,
      end: 5899,
      classKey: AccountClassKey['M-governmentTransfersAndTaxRevenues'],
    },
    {
      key: AccountKey.financialIncomeAndExpences,
      heading: 2,
      start: 6000,
      end: 6999,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.interestIncome,
      start: 6000,
      end: 6099,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.otherFinancialIncome,
      start: 6100,
      end: 6199,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.interestExpenses,
      start: 6200,
      end: 6299,
      classKey: AccountClassKey['XK-financialExpenses'],
    },
    {
      key: AccountKey.otherFinancingCosts,
      start: 6300,
      end: 6399,
      classKey: AccountClassKey['XK-financialExpenses'],
    },
    {
      key: AccountKey.annualContributionMargin,
      heading: 1,
      start: 3000,
      end: 6999,
    },
    {
      key: AccountKey.depreciationAndAmortization,
      heading: 2,
      start: 7000,
      end: 7299,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.plannedDepreciations,
      start: 7100,
      end: 7199,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.ownershipEliminationDifferences,
      start: 7220,
      end: 7221,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.groupGoodwillDepreciationAndGroupReserveReduction,
      start: 7222,
      end: 7229,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.impairments,
      start: 7230,
      end: 7299,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.occasionalIncomeAndExpenses,
      heading: 2,
      start: 8000,
      end: 8199,
      classKey: AccountClassKey['JB-otherOperatingIncome'],
    },
    {
      key: AccountKey.occasionalIncome,
      start: 8000,
      end: 8099,
      classKey: AccountClassKey['JB-otherOperatingIncome'],
    },
    {
      key: AccountKey.occasionalExpenses,
      start: 8100,
      end: 8199,
      classKey: AccountClassKey['JB-otherOperatingIncome'],
    },
    {
      key: AccountKey.profitOrLoss,
      heading: 1,
      start: 3000,
      end: 8199,
    },
    {
      key: AccountKey.financialStatementTransfers,
      heading: 2,
      start: 8200,
      end: 8499,
    },
    {
      key: AccountKey.depreciationDifferenceChange,
      heading: 2,
      start: 8500,
      end: 8599,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.reservationsChange,
      heading: 2,
      start: 8600,
      end: 8699,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.fundsChange,
      heading: 2,
      start: 8700,
      end: 8759,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.incomeTaxes,
      heading: 2,
      start: 8760,
      end: 8764,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.taxesForTheFinancialYear,
      heading: 2,
      start: 8765,
      end: 8765,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.deferredTaxes,
      heading: 2,
      start: 8766,
      end: 8779,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.minorityInterests,
      heading: 2,
      start: 8780,
      end: 8799,
      classKey: AccountClassKey['XJ-financialIncome'],
    },
    {
      key: AccountKey.financialYearSurplusOrDeficit,
      heading: 1,
      start: 8800,
      end: 9999,
    },
  ],
  balanceSheetAssetsAccountMap: [
    {
      key: AccountKey.balanceAssets,
      start: 1000,
      end: 1999,
      heading: 1,
    },
    {
      key: AccountKey.nonCurrentAssets,
      heading: 1,
      start: 1000,
      end: 1029,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.intangibleAssets,
      heading: 2,
      start: 1000,
      end: 1029,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.intangibleRights,
      start: 1000,
      end: 1006,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.computerSoftwares,
      start: 1005,
      end: 1005,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherIntangibleRights,
      start: 1006,
      end: 1011,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.groupGoodwill,
      start: 1012,
      end: 1009,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherCapitalisedLongtermCosts,
      start: 1010,
      end: 1019,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_advancePayments,
      start: 1020,
      end: 1099,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.tangibleAssets,
      heading: 2,
      start: 1100,
      end: 1199,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.landAndWaters_includeValueIncreases,
      start: 1100,
      end: 1109,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.landAndWaters_withoutValueIncreases,
      start: 1100,
      end: 1104,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.landAndWaters,
      start: 1100,
      end: 1100,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.propertyConnectionFees,
      start: 1102,
      end: 1102,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.propertyRentalRightsAndConnectionFees,
      start: 1104,
      end: 1104,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.landAndWaters_valueIncreases,
      start: 1105,
      end: 1105,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.buildings,
      start: 1110,
      end: 1120,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.residentialBuildings,
      start: 1115,
      end: 1115,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherBuildings,
      start: 1116,
      end: 1116,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.fixedStructuresAndEquipments,
      start: 1130,
      end: 1130,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.machineryAndEquipment,
      start: 1160,
      end: 1179,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.meansOfTransport,
      start: 1160,
      end: 1160,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.computerEquipments,
      start: 1165,
      end: 1165,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.communicationDevices,
      start: 1166,
      end: 1166,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherMachinesAndEquipments,
      start: 1170,
      end: 1170,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherTangibleAssets,
      start: 1180,
      end: 1189,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.naturalResources,
      start: 1180,
      end: 1180,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.valuableAndArtObjects,
      start: 1184,
      end: 1184,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherCommodities,
      start: 1187,
      end: 1187,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.advancePaymentsAndConstructionInProgress,
      start: 1190,
      end: 1199,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_advancePayments,
      start: 1190,
      end: 1190,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.incompletePurchases,
      start: 1195,
      end: 1195,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_investments,
      heading: 2,
      start: 1200,
      end: 1299,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.partnershipShares,
      start: 1200,
      end: 1209,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.affiliatedCompanyShares,
      start: 1200,
      end: 1200,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.jointVentureShares,
      start: 1201,
      end: 1201,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_otherSharesAndSimilarRightsOfOwnership,
      start: 1202,
      end: 1202,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_shares,
      start: 1204,
      end: 1214,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.sharesInSubsidiaries,
      start: 1204,
      end: 1204,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.housingSharesInSubsidiaries,
      start: 1205,
      end: 1205,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.municipalAssociationShares,
      start: 1206,
      end: 1206,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.basicCapitalSharesOfBusinessEstablishments,
      start: 1209,
      end: 1209,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.basicCapitalSharesOfOtherBalanceSheetUnits,
      start: 1210,
      end: 1210,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_otherSharesAndSimilarRightsOfOwnership,
      start: 1212,
      end: 1212,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherHousingShares,
      start: 1213,
      end: 1213,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.increasesInTheValueOfShares,
      start: 1214,
      end: 1214,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.nonCurrentAssets_bondReceivables,
      start: 1222,
      end: 1222,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherLoanReceivables,
      start: 1230,
      end: 1239,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.capitalLoanReceivables,
      start: 1237,
      end: 1237,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherLoanReceivables2,
      start: 1239,
      end: 1239,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherReceivables,
      start: 1240,
      end: 1249,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.otherReceivables2,
      start: 1247,
      end: 1247,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.refundableJoiningFees,
      start: 1248,
      end: 1248,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.assignmentFunds,
      heading: 1,
      start: 1400,
      end: 1499,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.governmentMandates,
      heading: 2,
      start: 1400,
      end: 1429,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.balanceAssets_brokeredGovernmentLoans,
      start: 1410,
      end: 1410,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.otherGovernmentMandates,
      start: 1420,
      end: 1420,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.endowmentFundsAssets,
      heading: 2,
      start: 1440,
      end: 1440,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.otherAssignmentAssets,
      heading: 2,
      start: 1460,
      end: 1460,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.currentAssets,
      heading: 1,
      start: 1500,
      end: 1999,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.stocks,
      heading: 2,
      start: 1500,
      end: 1599,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.rawMaterialsAndConsumables,
      start: 1500,
      end: 1500,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.semiFinishedProducts,
      start: 1530,
      end: 1530,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.finishedProductsOrGoods,
      start: 1550,
      end: 1550,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.otherStocks,
      start: 1570,
      end: 1570,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.currentAssets_advancePayments,
      start: 1590,
      end: 1590,
      classKey: AccountClassKey['P-inventories'],
    },
    {
      key: AccountKey.receivables,
      heading: 2,
      start: 1600,
      end: 1829,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTermReceivables,
      heading: 2,
      start: 1600,
      end: 1719,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_tradeReceivables,
      start: 1600,
      end: 1600,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_loanReceivables,
      start: 1630,
      end: 1630,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_municipalityReceivables,
      start: 1640,
      end: 1640,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_deferredTaxAssets,
      start: 1650,
      end: 2979,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_otherReceivables,
      start: 1688,
      end: 1688,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_prepaymentsAndAccruedIncome,
      start: 1690,
      end: 1719,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_transferredInterest,
      start: 1711,
      end: 1711,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.longTerm_otherTransferReceivables,
      start: 1712,
      end: 1712,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTermReceivables,
      start: 1720,
      end: 1829,
      heading: 2,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_tradeReceivables,
      start: 1720,
      end: 1720,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_loanReceivables,
      start: 1750,
      end: 1750,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_municipalityReceivables,
      start: 1760,
      end: 1760,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_deferredTaxAssets,
      start: 1770,
      end: 1770,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_otherReceivables,
      start: 1800,
      end: 1814,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_otherReceivables,
      start: 1800,
      end: 1800,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_vatReceivables,
      start: 1810,
      end: 1810,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_prepaymentsAndAccruedIncome,
      start: 1815,
      end: 1829,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_transferredInterest,
      start: 1816,
      end: 1816,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.shortTerm_otherTransferReceivables,
      start: 1829,
      end: 1829,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.currentAssets_investments,
      heading: 2,
      start: 1830,
      end: 1899,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.currentAssets_shares,
      start: 1830,
      end: 1830,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.investmentsInMoneyMarketInstruments,
      start: 1850,
      end: 1869,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.investmentsInGovernmentBonds,
      start: 1850,
      end: 1850,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.investmentsInMunicipalCertificates,
      start: 1855,
      end: 1855,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.investmentsInOtherMoneyMarketInstruments,
      start: 1860,
      end: 1860,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.currentAssets_bondReceivables,
      start: 1870,
      end: 1870,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.otherInvestments,
      start: 1890,
      end: 1899,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.totalFundShares,
      start: 1891,
      end: 1891,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.otherInvestments,
      start: 1892,
      end: 1892,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.cashInHandAndAtBanks,
      heading: 2,
      start: 1900,
      end: 1900,
      classKey: AccountClassKey['Q-receivables'],
    },
    {
      key: AccountKey.balanceAssets,
      start: 1000,
      end: 1999,
      heading: 1,
    },
  ],
  balanceSheetLiabilitiesAccountMap: [
    {
      key: AccountKey.balanceLiabilities,
      start: 2000,
      end: 2999,
      heading: 1,
    },
    {
      key: AccountKey.equity,
      heading: 1,
      start: 2000,
      end: 2099,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.basicCapital,
      start: 2000,
      end: 2000,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.basicCapitalOfFoundationsAndAssociations,
      start: 2005,
      end: 2005,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.appreciationFund,
      start: 2010,
      end: 2010,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.otherOwnFunds,
      start: 2015,
      end: 2015,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.surplusOrDeficitOfPreviousFinancialYears,
      start: 2040,
      end: 2040,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.surplusOrDeficitForPeriod,
      start: 2050,
      end: 2050,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.balanceLiabilities_minorityInterests,
      heading: 1,
      start: 2060,
      end: 2060,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.balanceLiabilities_minorityInterests2,
      start: 2060,
      end: 2060,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.depreciationDifferenceAndVoluntaryReservations,
      heading: 1,
      start: 2100,
      end: 2119,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.cumulativeAcceleratedDepreciation,
      start: 2100,
      end: 2100,
      classKey: AccountClassKey['O-fixedAssets'],
    },
    {
      key: AccountKey.voluntaryReservations,
      start: 2110,
      end: 2110,
    },
    {
      key: AccountKey.provisions,
      heading: 1,
      start: 2120,
      end: 2130,
    },
    {
      key: AccountKey.provisionsForPensions,
      start: 2120,
      end: 2120,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.otherProvisions,
      start: 2130,
      end: 2130,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.capitalsOfAssignments,
      heading: 1,
      start: 2300,
      end: 2389,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.governmentAssignments,
      heading: 2,
      start: 2300,
      end: 2324,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.balanceLiabilities_toBeBrokeredGovernmentLoans,
      start: 2300,
      end: 2300,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.balanceLiabilities_brokeredGovernmentLoans,
      start: 2310,
      end: 2310,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.otherCapitalOfGovernmentMandates,
      start: 2320,
      end: 2320,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.capitalsOfEndowmentFunds,
      heading: 2,
      start: 2325,
      end: 2325,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.otherAssignmentCapitals,
      heading: 2,
      start: 2330,
      end: 2330,
      classKey: AccountClassKey['U-assignmentAssetsAndLiabilities'],
    },
    {
      key: AccountKey.groupReserve,
      heading: 1,
      start: 2399,
      end: 2399,
    },
    {
      key: AccountKey.groupReserve2,
      start: 2399,
      end: 2399,
      classKey: AccountClassKey['R-equity'],
    },
    {
      key: AccountKey.creditors,
      heading: 1,
      start: 2400,
      end: 2600,
    },
    {
      key: AccountKey.longTermInterestBearingDebt,
      heading: 2,
      start: 2400,
      end: 2439,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_bonds,
      start: 2400,
      end: 2400,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_loansFromFinancialAndInsuranceInstitutions,
      start: 2410,
      end: 2410,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_loansFromTheMunicipality,
      start: 2415,
      end: 2415,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_loansFromPublicEntities,
      start: 2420,
      end: 2420,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermCreditors_loansFromOtherLenders,
      start: 2430,
      end: 2430,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.longTermInterestFreeDebtCapital,
      heading: 2,
      start: 2440,
      end: 2499,
    },
    {
      key: AccountKey.longTermCreditors_advancesReceived,
      start: 2449,
      end: 2449,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_tradeCreditors,
      start: 2457,
      end: 2457,
      classKey: AccountClassKey['KA-tradeCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_interestFreeDebtsFromTheMunicipality,
      start: 2458,
      end: 2458,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_joiningFeesAndOtherDebts,
      start: 2460,
      end: 2469,
    },
    {
      key: AccountKey.longTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts,
      start: 2460,
      end: 2460,
    },
    {
      key: AccountKey.longTermCreditors_joiningFeesAndOtherDebts2,
      start: 2469,
      end: 2469,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_accrualsAndDeferredIncome,
      start: 2470,
      end: 2470,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.longTermCreditors_taxCreditors,
      start: 2480,
      end: 2480,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.shortTerm,
      heading: 2,
      start: 2500,
      end: 2999,
    },
    {
      key: AccountKey.shortTermInterestBearingDebt,
      heading: 2,
      start: 2500,
      end: 2539,
    },
    {
      key: AccountKey.shortTermCreditors_bonds,
      start: 2500,
      end: 2500,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.repaymentsOfLongTermLoans_bonds,
      start: 2501,
      end: 2501,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_loansFromFinancialAndInsuranceInstitutions,
      start: 2510,
      end: 2510,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.repaymentsOfLongTermLoans_loansFromFinancialAndInsuranceInstitutions,
      start: 2511,
      end: 2511,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_loansFromTheMunicipality,
      start: 2515,
      end: 2515,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.repaymentsOfLongTermLoans_loansFromTheMunicipality,
      start: 2516,
      end: 2516,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_loansFromPublicEntities,
      start: 2520,
      end: 2520,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.repaymentsOfLongTermLoans_loansFromPublicEntities,
      start: 2521,
      end: 2521,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.shortTermCreditors_loansFromOtherLenders,
      start: 2530,
      end: 2530,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.repaymentsOfLongTermLoans_loansFromOtherLenders,
      start: 2531,
      end: 2531,
      classKey: AccountClassKey['S-loansFromFinancialInstitutions'],
    },
    {
      key: AccountKey.shortTermInterestFreeDebtCapital,
      heading: 2,
      start: 2540,
      end: 2999,
    },
    {
      key: AccountKey.shortTermCreditors_advancesReceived,
      start: 2540,
      end: 2540,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_tradeCreditors,
      start: 2545,
      end: 2545,
      classKey: AccountClassKey['KA-tradeCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_interestFreeDebtsFromTheMunicipality,
      start: 2550,
      end: 2550,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_joiningFeesAndOtherDebts,
      heading: 2,
      start: 2560,
      end: 2569,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_cashCollateralReceivedBasedOnDerivativeContracts,
      start: 2561,
      end: 2561,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_vatDebt,
      start: 2563,
      end: 2563,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_connectionFeeDebts,
      start: 2568,
      end: 2568,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_otherCreditors,
      start: 2569,
      end: 2569,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.shortTermCreditors_accrualsAndDeferredIncome,
      heading: 2,
      start: 2570,
      end: 2589,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.transferredInterest,
      start: 2579,
      end: 2579,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.periodizationOfHolidayPay,
      start: 2580,
      end: 2580,
      classKey: AccountClassKey['L-personelCosts'],
    },
    {
      key: AccountKey.otherAccruedLiabilities,
      start: 2589,
      end: 2589,
      classKey: AccountClassKey['T-otherCreditors'],
    },
    {
      key: AccountKey.deferredTaxLiabilities,
      start: 2590,
      end: 2590,
      classKey: AccountClassKey['N-taxes'],
    },
    {
      key: AccountKey.balanceLiabilities,
      heading: 1,
      start: 2000,
      end: 2999,
    },
  ],
};

export default accountMap;
