import { AccountingEstimates } from '../../../components/auditing/Risks.AccountingEstimates';
import { InnerCircleParties } from '../../../components/auditing/Risks.InnerCircleParties';
import { MonetizationRiskStatements } from '../../../components/auditing/Risks.MonetizationRiskStatements';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  AccountingEstimatesForReferencePeriod,
  CausesToDoubtAbilityToContinue,
  getDefaultRiskValues,
  getInitialAccountingEstimates,
  handleMonetizationRiskChange,
  initialInnerCircleParties,
  initialMonetizationRiskStatements,
  InnerCircleRisks,
  InquiredAccountingEstimatesPerception,
  MonetizationRisk,
  monetizationRiskAccordionVariant,
  PlanningPhaseAccountingEstimates,
  showAccountingEstimates,
  showInquiredAccountingEstimatesPerceptionDetails,
  UnderstandingInnerCircleChanges,
  MeetsCrisisMunicipalityCriteria,
  AssesmentProcedureCanBeInitiatedIf,
  EconomicKeyFiguresOverLimits,
} from './risksUtils';
import { OtherSignificantRisks } from '../../../components/auditing/Risks.OtherSignificantRisks';
import { AuditingTemplate } from '../../../utils';

type SectionFormType = RisksForm;

const sectionKey: AuditingSectionKey = 'risks';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  // PART I
  monetizationRisk: null,
  monetizationRiskStatements: initialMonetizationRiskStatements(
    getDefaultRiskValues(auditing)
  ),
  monetizationRiskDetails: '',
  // PART II
  controlsIgnoredRisk: '',
  // PART III
  accountingEstimatesRecognizedByManagement: '',
  accountingEstimatesForReferencePeriod: null,
  accountingEstimatesForReferencePeriodDetails: '',
  inquiredAccountingEstimates: '',
  inquiredAccountingEstimatesDate:
    auditing?.auditingFrontPage?.form.auditingStartDate ?? null,
  inquiredAccountingEstimatesDetails: '',
  inquiredAccountingEstimatesPerception: null,
  inquiredAccountingEstimatesPerceptionDetails: '',
  planningPhaseAccountingEstimates: null,
  accountingEstimates: getInitialAccountingEstimates(),
  // PART IV
  understandingInnerCircle: '',
  understandingInnerCircleDate:
    auditing?.auditingFrontPage?.form.auditingStartDate ?? null,
  understandingInnerCircleDetails: '',
  communityRecognizesInnerCircle: null,
  communityRecognizesInnerCircleNoDetails: '',
  communityRecognizesInnerCircleYesDetails: '',
  understandingInnerCircleChanges: null,
  understandingInnerCircleChangesDetails: '',
  innerCircleParties: initialInnerCircleParties(),
  innerCircleRisks: null,
  innerCircleRisksDetails: '',
  innerCircleTransactionAudit: '',
  // PART V
  causesToDoubtAbilityToContinue: null,
  causesToDoubtAbilityToContinueDetails: '',

  meetsCrisisMunicipalityCriteria: null,
  meetsCrisisMunicipalityCriteriaDetails: '',
  assesmentProcedureCanBeInitiatedIf: null,
  economicKeyFiguresOverLimits: null,
  // PART VI
  otherSignificantRisks: [],
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  // PART I
  formBuilder.accordionGroup({
    variant: monetizationRiskAccordionVariant(formState),
    open: false,
    titleKey: 'monetizationRisk',
    items: [
      formBuilder.radioGroup({
        accessor: 'monetizationRisk',
        options: Object.values(MonetizationRisk),
        customOnChange: handleMonetizationRiskChange,
      }),

      formBuilder.custom(props => <MonetizationRiskStatements {...props} />),

      formBuilder.textArea({
        accessor: 'monetizationRiskDetails',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  // PART II
  formBuilder.accordionGroup({
    open: false,
    titleKey: 'controlsIgnoredRisk',
    items: [
      formBuilder.textArea({
        accessor: 'controlsIgnoredRisk',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  // PART III
  formBuilder.accordionGroup({
    open: false,
    titleKey: 'accountingEstimates',
    items: [
      formBuilder.textArea({
        accessor: 'accountingEstimatesRecognizedByManagement',
        showContextInfo: 'top-left',
      }),

      formBuilder.radioGroup({
        accessor: 'accountingEstimatesForReferencePeriod',
        options: Object.values(AccountingEstimatesForReferencePeriod),
        detailsAccessor: 'accountingEstimatesForReferencePeriodDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden:
          formState.accountingEstimatesForReferencePeriod !==
          AccountingEstimatesForReferencePeriod.significantDifferences,
      }),

      formBuilder.group({
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.textInput({
                accessor: 'inquiredAccountingEstimates',
                showContextInfo: 'top-right',
              }),
              formBuilder.dateField({
                accessor: 'inquiredAccountingEstimatesDate',
              }),
            ],
          }),

          formBuilder.radioGroup({
            accessor: 'inquiredAccountingEstimatesPerception',
            options: Object.values(InquiredAccountingEstimatesPerception),
            detailsAccessor: 'inquiredAccountingEstimatesPerceptionDetails',
            detailsHidden:
              !showInquiredAccountingEstimatesPerceptionDetails(formState),
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.radioGroup({
            accessor: 'planningPhaseAccountingEstimates',
            options: Object.values(PlanningPhaseAccountingEstimates),
          }),

          formBuilder.custom(props => <AccountingEstimates {...props} />, {
            hidden: !showAccountingEstimates(formState),
          }),

          formBuilder.text({
            textKey: 'accountingEstimateInfo',
            hidden: !showAccountingEstimates(formState),
          }),
          formBuilder.text({
            textKey: 'accountingEstimateInfo2',
            hidden: !showAccountingEstimates(formState),
          }),
        ],
      }),
    ],
  }),

  // PART IV
  formBuilder.accordionGroup({
    open: false,
    titleKey: 'innerCircle',
    items: [
      formBuilder.group({
        items: [
          formBuilder.groupRow({
            items: [
              formBuilder.textInput({
                accessor: 'understandingInnerCircle',
                showContextInfo: 'top-right',
              }),
              formBuilder.dateField({
                accessor: 'understandingInnerCircleDate',
              }),
            ],
          }),

          formBuilder.textArea({
            accessor: 'understandingInnerCircleDetails',
            showContextInfo: 'top-right',
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.boolean({
            accessor: 'communityRecognizesInnerCircle',
            options: ['no', 'yes'],
          }),
          formBuilder.textArea({
            accessor: 'communityRecognizesInnerCircleNoDetails',
            showContextInfo: 'bottom-right',
            hidden: formState.communityRecognizesInnerCircle !== false,
          }),
          formBuilder.textArea({
            accessor: 'communityRecognizesInnerCircleYesDetails',
            showContextInfo: 'bottom-right',
            hidden: formState.communityRecognizesInnerCircle !== true,
          }),
        ],
      }),

      formBuilder.group({
        items: [
          formBuilder.radioGroup({
            accessor: 'understandingInnerCircleChanges',
            options: Object.values(UnderstandingInnerCircleChanges),
            detailsAccessor: 'understandingInnerCircleChangesDetails',
            showDetailsContextInfo: 'bottom-right',
          }),

          formBuilder.custom(props => <InnerCircleParties {...props} />, {
            hidden:
              formState.understandingInnerCircleChanges !==
              UnderstandingInnerCircleChanges.documentedToInnerCircleParties,
          }),
        ],
      }),

      formBuilder.radioGroup({
        accessor: 'innerCircleRisks',
        options: Object.values(InnerCircleRisks),
        detailsAccessor: 'innerCircleRisksDetails',
        showDetailsContextInfo:
          formState.innerCircleRisks === InnerCircleRisks.yes
            ? 'bottom-right'
            : undefined,
      }),

      formBuilder.textArea({
        accessor: 'innerCircleTransactionAudit',
        showContextInfo: 'bottom-right',
      }),
    ],
  }),

  // PART V (Only for private template)
  formBuilder.accordionGroup({
    open: false,
    hiddenInTemplate: [AuditingTemplate.public],
    titleKey: 'businessContinuity',
    items: [
      formBuilder.radioGroup({
        accessor: 'causesToDoubtAbilityToContinue',
        options: Object.values(CausesToDoubtAbilityToContinue),
        showContextInfo: 'bottom-left',
        detailsAccessor: 'causesToDoubtAbilityToContinueDetails',
      }),
    ],
  }),

  // PART V (Only for public template)
  formBuilder.accordionGroup({
    open: false,
    hiddenInTemplate: [AuditingTemplate.private],
    titleKey: 'crisisMunicipalityCriteria',
    items: [
      formBuilder.radioGroup({
        accessor: 'meetsCrisisMunicipalityCriteria',
        options: Object.values(MeetsCrisisMunicipalityCriteria),
      }),

      formBuilder.checkboxGroup({
        accessor: 'assesmentProcedureCanBeInitiatedIf',
        options: Object.values(AssesmentProcedureCanBeInitiatedIf),
        hidden: formState.meetsCrisisMunicipalityCriteria !== MeetsCrisisMunicipalityCriteria.detected,
      }),

      formBuilder.checkboxGroup({
        accessor: 'economicKeyFiguresOverLimits',
        options: Object.values(EconomicKeyFiguresOverLimits),
        hidden: formState.meetsCrisisMunicipalityCriteria !== MeetsCrisisMunicipalityCriteria.detected,
      }),

      formBuilder.textArea({
        accessor: 'meetsCrisisMunicipalityCriteriaDetails',
        hidden: formState.meetsCrisisMunicipalityCriteria !== MeetsCrisisMunicipalityCriteria.detected,
      }),
    ],
  }),

  // PART VI
  formBuilder.accordionGroup({
    open: false,
    titleKey: 'otherSignificantRisks.title',
    items: [formBuilder.custom(props => <OtherSignificantRisks {...props} />)],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
