import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import {
  FinancialStatementBatch,
  FinancialStatementEntirety,
  makeAuditingProcedure,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';

type FormType = AuditingProceduresForm;
type TableItemType = AuditingProcedure;

const sectionKey: AuditingSectionKey = 'auditingProcedures';
const formFieldKey: keyof FormType = 'auditingProcedures';

const makeNewItem = makeAuditingProcedure;

interface Props extends FormFieldProps<FormType> {
  batchKey: FinancialStatementBatch;
}

export const PlanningItems = ({
  batchKey,
  formState,
  patchFormState,
}: Props) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  // Take only data that matches given key
  const data = (formState[formFieldKey] ?? []).filter(
    ({ batch }) => batch === batchKey
  );

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.auditingProcedures.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, [formFieldKey]: patch });
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      [formFieldKey]: [
        ...(formState[formFieldKey] ?? []),
        makeNewItem({ batch: batchKey }),
      ],
    });
  };

  const handleDeleteRow = ({ id, batch, action }: TableItemType) => {
    const batchText = batch ? t(`auditing:accountClass.${batch}`) : '';
    if (confirmDelete(`${batchText}: ${action ?? ''}`)) {
      const patch = (formState[formFieldKey] ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, [formFieldKey]: patch });
    }
  };

  const tableColumns: TableColumnDefs<TableItemType> = [
    {
      accessorKey: 'action',
      header: () => t(`${tBase}.action`),
      className: 'width-max',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },

    {
      accessorKey: 'entirety',
      className: 'width-min text-center',
      header: () => t(`${tBase}.entirety`),
      selectProps: {
        options: Object.values(FinancialStatementEntirety),
        displayValue: (option: string) =>
          t(`auditing:financialStatementEntirety.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },

    {
      accessorKey: 'period',
      header: () => t(`${tBase}.period`),
      className: 'width-min text-center',
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
    },
    {
      accessorKey: 'tp',
      header: () => t(`${tBase}.tp`),
      className: 'width-min text-center',
      onBooleanEdit: (editProps: OnEditProps<boolean>) =>
        handleEdit<boolean>(editProps),
    },
    {
      accessorKey: 'auditor',
      header: () => t(`${tBase}.auditor`),
      className: 'width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable<TableItemType>
      sectionKey="detailedPlan"
      onAddNewRow={handleAddNewRow}
      data={data}
      columns={tableColumns}
      onRowDelete={handleDeleteRow}
      showGlobalFilter={false}
      disableSort
    />
  );
};
