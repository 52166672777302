import React from 'react';
import { formatCurrency } from '../../../utils';
import {
  generateAccountMap,
  getAllAccountMapRowsMatchingClassKey,
} from '../../../views/auditingSections/planning/accountMapUtils';
import AuditingTable from '../../table/AuditingTable';
import { TableColumnDefs } from '../../table/Table';

interface Props {
  classKey: AccountClassKey;
  auditing?: Auditing;
}

const ProcedureAccounts: React.FC<Props> = ({ auditing, classKey }) => {
  const auditingTemplate = auditing?.template;
  const auditingAccountMap = auditing?.accountMap?.form.accountMap;
  const auditingGeneralLedger =
    auditing?.generalLedger?.form.groupedGeneralLedger;

  const accountMap =
    !auditingAccountMap && auditingTemplate
      ? generateAccountMap(auditingTemplate)
      : auditingAccountMap;

  if (!accountMap) return null;

  // Get all rows from the account map that match the class key
  const accountsMapRows = getAllAccountMapRowsMatchingClassKey(
    accountMap,
    classKey
  );

  // Filter the general ledger to only include rows that match the account map rows
  const procedureAccountRows = auditingGeneralLedger?.filter(({ account }) => {
    const hasMatchingAccountClass = !!accountsMapRows.find(
      ({ start, end }) => account >= start && account <= end
    );
    return hasMatchingAccountClass;
  });

  if (!procedureAccountRows?.length) return null;

  const tableColumns: TableColumnDefs<GroupedGeneralLedgerItem> = [
    {
      accessorKey: 'account',
      className: 'width-min',
      header: () => null,
    },
    {
      accessorKey: 'accountName',
      header: () => null,
    },
    {
      accessorKey: 'currentYear',
      header: () => 'CY',
      className: 'width-min text-right nowrap',
      accessorFn: (row: any) => formatCurrency(row.currentYear),
    },
    {
      accessorKey: 'priorYear',
      header: () => 'PY',
      className: 'width-min text-right nowrap',
      accessorFn: (row: any) => formatCurrency(row.priorYear),
    },
  ];

  return (
    <AuditingTable
      data={procedureAccountRows}
      columns={tableColumns}
      showGlobalFilter={false}
    />
  );
};

export default ProcedureAccounts;
