export const getAddress = (company: AuditFirm | CustomerCompany) => {
  const street = company.streetAddress ?? '';
  const postalCode = company.postalCode ?? '';
  const city = company.city ?? '';
  const country = company.country ?? '';

  return `${street}, ${postalCode} ${city}, ${country}`;
};

export const isValidBusinessId = (businessId: string = '') => {
  const trimmedId = businessId.trim();
  const pattern = /[\d]{7}-[\d]{1}/;
  return trimmedId.length === 9 && pattern.test(trimmedId);
};

export const sortCompanies = (a: Company, b: Company) => {
  return a.name.localeCompare(b.name);
};
