import { useTranslation } from 'react-i18next';
import { AuditingTemplate, formatCurrency } from '../../utils';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';
import {
  getAverageOfLiabilitiesAndRevenues,
  getCombinedRevenues,
} from '../../views/auditingSections/planning/materialityUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps } from '../table/Table';

const sectionKey: AuditingSectionKey = 'materiality';

export const AlternativeMaterialities = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const {
    auditingStore: {
      materialityComputeds,
      getFinancialNumber,
      auditingTemplate,
    },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => t(`${tBase}.alternatives.name`),
    },
    {
      accessorKey: 'currentYear',
      header: () => t(`auditing:form.incomeStatementAndBalance.currentYear`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'priorYear',
      header: () => t(`auditing:form.incomeStatementAndBalance.priorYear`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'materialityPercent',
      onNumberEdit: ({ itemId, value }: OnEditProps<number>) => {
        const alternatives = formState.alternatives;

        const updateValue = (targetRowId: string) => {
          if (targetRowId === itemId) return value;
          else {
            switch (targetRowId) {
              case 'turnover':
                return alternatives.turnoverMaterialityPercent;
              case 'operatingProfitOrLoss':
                return alternatives.operatingProfitOrLossMaterialityPercent;
              case 'equity':
                return alternatives.equityMaterialityPercent;
              case 'liabilities':
                return alternatives.liabilitiesMaterialityPercent;
              case 'combinedRevenues':
                return alternatives.combinedRevenuesMaterialityPercent;
              case 'averageOfLiabilitiesAndRevenues':
                return alternatives.averageOfLiabilitiesAndRevenuesMaterialityPercent;
              case 'annualContributionMargin':
                return alternatives.annualContributionMarginMaterialityPercent;
              default:
                return null;
            }
          }
        };

        patchFormState({
          ...formState,
          alternatives: {
            turnoverMaterialityPercent: updateValue('turnover'),
            operatingProfitOrLossMaterialityPercent: updateValue(
              'operatingProfitOrLoss'
            ),
            equityMaterialityPercent: updateValue('equity'),
            liabilitiesMaterialityPercent: updateValue('liabilities'),
            combinedRevenuesMaterialityPercent: updateValue('combinedRevenues'),
            averageOfLiabilitiesAndRevenuesMaterialityPercent: updateValue(
              'averageOfLiabilitiesAndRevenues'
            ),
            annualContributionMarginMaterialityPercent: updateValue(
              'annualContributionMargin'
            ),
          },
        });
      },
      header: () => t(`${tBase}.alternatives.materialityPercent`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.alternatives.materiality`),
      className: 'text-center width-min nowrap',
    },
  ];

  const percents = formState?.alternatives ?? [];
  const materialities = materialityComputeds(formState).alternatives;

  const isTemplate = (...templates: AuditingTemplate[]) =>
    auditingTemplate && templates.includes(auditingTemplate);

  const rows = [
    {
      id: 'turnover',
      name: t(`${tBase}.materialityBasisOptions.turnover`),
      currentYear: formatCurrency(getFinancialNumber(AccountKey.turnover)),
      priorYear: formatCurrency(
        getFinancialNumber(AccountKey.turnover, 'priorYear')
      ),
      materialityPercent: percents.turnoverMaterialityPercent,
      materiality: formatCurrency(materialities.turnoverMateriality),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'operatingProfitOrLoss',
      name: t(`${tBase}.materialityBasisOptions.operatingProfitOrLoss`),
      currentYear: formatCurrency(
        getFinancialNumber(AccountKey.operatingProfitOrLoss)
      ),
      priorYear: formatCurrency(
        getFinancialNumber(AccountKey.operatingProfitOrLoss, 'priorYear')
      ),
      materialityPercent: percents.operatingProfitOrLossMaterialityPercent,
      materiality: formatCurrency(
        materialities.operatingProfitOrLossMateriality
      ),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'equity',
      name: t(`${tBase}.materialityBasisOptions.equity`),
      currentYear: formatCurrency(getFinancialNumber(AccountKey.equity)),
      priorYear: formatCurrency(
        getFinancialNumber(AccountKey.equity, 'priorYear')
      ),
      materialityPercent: percents.equityMaterialityPercent,
      materiality: formatCurrency(materialities.equityMateriality),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'liabilities',
      name: t(`${tBase}.materialityBasisOptions.balanceLiabilities`),
      currentYear: formatCurrency(
        getFinancialNumber(AccountKey.balanceLiabilities)
      ),
      priorYear: formatCurrency(
        getFinancialNumber(AccountKey.balanceLiabilities, 'priorYear')
      ),
      materialityPercent: percents.liabilitiesMaterialityPercent,
      materiality: formatCurrency(materialities.liabilitiesMateriality),
    },
    {
      id: 'combinedRevenues',
      name: t(`${tBase}.materialityBasisOptions.combinedRevenues`),
      currentYear: formatCurrency(getCombinedRevenues(store)),
      priorYear: formatCurrency(getCombinedRevenues(store, 'priorYear')),
      materialityPercent: percents.combinedRevenuesMaterialityPercent,
      materiality: formatCurrency(materialities.combinedRevenuesMateriality),
      hidden: isTemplate(AuditingTemplate.private),
    },
    {
      id: 'averageOfLiabilitiesAndRevenues',
      name: t(
        `${tBase}.materialityBasisOptions.averageOfLiabilitiesAndRevenues`
      ),
      currentYear: formatCurrency(getAverageOfLiabilitiesAndRevenues(store)),
      priorYear: formatCurrency(
        getAverageOfLiabilitiesAndRevenues(store, 'priorYear')
      ),
      materialityPercent:
        percents.averageOfLiabilitiesAndRevenuesMaterialityPercent,
      materiality: formatCurrency(
        materialities.averageOfLiabilitiesAndRevenuesMateriality
      ),
      hidden: isTemplate(AuditingTemplate.private),
    },
    {
      id: 'annualContributionMargin',
      name: t(`${tBase}.materialityBasisOptions.annualContributionMargin`),
      currentYear: formatCurrency(
        getFinancialNumber(AccountKey.annualContributionMargin)
      ),
      priorYear: formatCurrency(
        getFinancialNumber(AccountKey.annualContributionMargin, 'priorYear')
      ),
      materialityPercent: percents.annualContributionMarginMaterialityPercent,
      materiality: formatCurrency(
        materialities.annualContributionMarginMateriality
      ),
      hidden: isTemplate(AuditingTemplate.private),
    },
  ].filter(row => !row.hidden);

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.alternatives.title`)}
      data={rows}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
