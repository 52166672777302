import { t } from '../../i18n';
import Todo from '../../components/Todo';

import auditingFrontPage from './auditingFrontPage';
// Planning imports
import permanentData from './planning/permanentData';
import accountMap from './planning/accountMap';
import generalLedger from './planning/generalLedger';
import incomeStatementAndBalance from './planning/incomeStatementAndBalance';
import independence from './planning/independence';
import auditingPlanning from './planning/auditingPlanning';
import internalControlAndOperatingEnvironment from './planning/internalControlAndOperatingEnvironment';
import itAndProcesses from './planning/itAndProcesses';
import risks from './planning/risks';
import materiality from './planning/materiality';
import detailedPlan from './planning/detailedPlan';
// Auditing imports
import auditingProcedures from './auditing/auditingProcedures';
import personelCosts from './auditing/personelCosts';
import governmentTransfersAndTaxRevenues from './auditing/governmentTransfersAndTaxRevenues';
import management from './auditing/management';
// Reporting imports
import errors from './reporting/errors';
import closure from './reporting/closure';
import communicationAndReporting from './reporting/communicationAndReporting';

const TODO = (
  sectionKey: AuditingSectionKey
): AuditingSectionDefinition<unknown> => ({
  sectionKey,
  formFields: [
    {
      type: 'custom',
      render: <Todo text={t(`route:auditing.${sectionKey}`)} />,
    },
  ],
  defaultFormState: {},
});

const AuditingSections = {
  auditingFrontPage,

  // Planning definitions
  permanentData,
  accountMap,
  generalLedger,
  incomeStatementAndBalance,
  independence,
  auditingPlanning,
  internalControlAndOperatingEnvironment,
  itAndProcesses,
  risks,
  materiality,
  detailedPlan,

  // Auditing definitions
  auditingProcedures,
  personelCosts,
  governmentTransfersAndTaxRevenues,
  management,

  // Reporting definitions
  errors,
  closure,
  communicationAndReporting,

  instructions: TODO('instructions'),
};

export default AuditingSections;
