import { AlternativeMaterialities } from '../../../components/auditing/Materiality.AlternativeMaterialities';
import { MaterialitiesTable } from '../../../components/auditing/Materiality.MaterialitiesTable';
import { SeparateMaterialities } from '../../../components/auditing/Materiality.SeparateMaterialities';
import UpdateMaterialityBaseNumberButton from '../../../components/auditing/Materiality.UpdateBaseNumberButton';
import {
  getDefaultSeparateMaterialities,
  getMaterialityBasisOptions,
  handleMaterialityBasedInChange,
  handleMaterialityBasisChange,
  isSameMaterialityForAllItems,
  MaterialityBasedIn,
} from './materialityUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { AuditingTemplate } from '../../../utils';

type SectionFormType = MaterialityForm;

const sectionKey: AuditingSectionKey = 'materiality';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  materialityBasedIn: null,
  materialityBasis: null,
  materialityBasisText: '',
  materialityBaseNumber: null,
  materialityPercent: auditing.template === AuditingTemplate.public ? 2 : null,
  workingMaterialityPercent: 75,
  singleErrorMaterialityPercent: 5,
  reasonsOfMateriality: '',
  isSameMaterialityForAllItems: isSameMaterialityForAllItems(auditing.template),
  separateMaterialities: getDefaultSeparateMaterialities(auditing.template),
  alternatives: {
    turnoverMaterialityPercent: 3,
    operatingProfitOrLossMaterialityPercent: 10,
    equityMaterialityPercent: 10,
    liabilitiesMaterialityPercent:
      auditing.template === AuditingTemplate.public ? 2 : 3,
    combinedRevenuesMaterialityPercent: 2,
    averageOfLiabilitiesAndRevenuesMaterialityPercent: 2,
    annualContributionMarginMaterialityPercent: 5,
  },
});

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.group({
    items: [
      formBuilder.select({
        accessor: 'materialityBasedIn',
        options: Object.values(MaterialityBasedIn),
        customOnChange: handleMaterialityBasedInChange,
      }),

      formBuilder.select({
        accessor: 'materialityBasis',
        options: getMaterialityBasisOptions(auditing?.template),
        customOnChange: handleMaterialityBasisChange,
      }),

      formBuilder.textArea({
        accessor: 'materialityBasisText',
        hidden: formState.materialityBasis !== 'other',
      }),

      formBuilder.numberInput({
        accessor: 'materialityBaseNumber',
        disabled:
          formState.materialityBasedIn === MaterialityBasedIn.actualNumbers &&
          formState.materialityBasis !== 'other',
      }),

      formBuilder.custom(props => (
        <UpdateMaterialityBaseNumberButton {...props} />
      )),
    ],
  }),

  formBuilder.custom(props => <AlternativeMaterialities {...props} />),

  formBuilder.custom(props => <MaterialitiesTable {...props} />),

  formBuilder.textArea({ accessor: 'reasonsOfMateriality' }),

  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'isSameMaterialityForAllItems',
        options: ['no', 'yes'],
      }),

      formBuilder.custom(props => <SeparateMaterialities {...props} />, {
        hidden:
          !!formState.isSameMaterialityForAllItems ||
          formState.isSameMaterialityForAllItems === null,
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
