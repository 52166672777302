import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import { usingActualNumbersForMateriality } from '../../views/auditingSections/planning/materialityUtils';
import { Button } from '../inputs';

const UpdateMaterialityBaseNumberButton = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const { getFinancialNumber, auditingLocked, isAuditingSectionFinished } =
    store.auditingStore;

  if (auditingLocked || isAuditingSectionFinished('materiality')) return null;

  const { materialityBasis, materialityBasedIn, materialityBaseNumber } =
    formState;

  const financialNumber =
    materialityBasis !== 'combinedRevenues' &&
    materialityBasis !== 'averageOfLiabilitiesAndRevenues' &&
    getFinancialNumber(materialityBasis);

  const isChanged =
    financialNumber && financialNumber !== materialityBaseNumber;

  if (!usingActualNumbersForMateriality(materialityBasedIn) || !isChanged)
    return null;

  const tKey = 'auditing:form.materiality.updateMaterialityBaseNumber';

  return (
    <Button
      text={t(tKey, { value: formatCurrency(financialNumber) })}
      onClick={() => patchFormState({ materialityBaseNumber: financialNumber })}
      fullWidth
      variant="link"
    />
  );
};

export default UpdateMaterialityBaseNumberButton;
