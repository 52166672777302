import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  generalLedgerParser,
  ParseMethod,
  ParserCallbackFn,
  ParserOptions,
} from '../../views/auditingSections/planning/generalLedgerUtils';
import FileUpload from '../inputs/FileUpload';
import { generateFinancialStatementData } from '../../views/auditingSections/planning/incomeStatementAndBalanceUtils';
import { useParams } from '../../utils';
import { Select } from '../inputs';
import CsvParseMethodExample from '../CsvParseMethodExample';

const ParseMethodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${p => p.theme.spacing.md};
`;

export const UploadGeneralLedger = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<GeneralLedgerForm>) => {
  const { t } = useTranslation();
  const { id: auditingId } = useParams();

  const { auditingStore, notificationStore } = store;

  const { auditingLocked, isAuditingSectionFinished, getAccountMap } =
    auditingStore;

  const disabled = auditingLocked || isAuditingSectionFinished('generalLedger');

  const [parseMethod, setParseMethod] = useState<ParseMethod | undefined>(
    formState?.parseMethod
  );

  useEffect(() => {
    if (formState?.parseMethod) setParseMethod(formState.parseMethod);
  }, [formState.parseMethod]);

  if (!formState) return null;

  // Generates income statement and balance sheets, and silently patches them to API in the background.
  const generateIncomeStatementAndBalance = (
    groupedGeneralLedger: GroupedGeneralLedger
  ) => {
    const incomeStatementAndBalanceSection = auditingStore.getAuditingSection(
      'incomeStatementAndBalance'
    );

    const auditingPatch: Partial<Auditing> = {
      incomeStatementAndBalance: {
        ...incomeStatementAndBalanceSection,
        form: generateFinancialStatementData(
          groupedGeneralLedger,
          getAccountMap()
        ),
      },
    };

    auditingStore.editEntity(
      { id: auditingId, data: auditingPatch },
      { isSilent: true, isBackground: true }
    );
  };

  const callback = (
    groupedGeneralLedger?: GroupedGeneralLedger,
    errorKey = 'common'
  ) => {
    if (groupedGeneralLedger) {
      // Success
      patchFormState({ parseMethod, groupedGeneralLedger });
      generateIncomeStatementAndBalance(groupedGeneralLedger);
      notificationStore.notify(t('notification:success.fileUpload'), 'success');
    } else {
      // Error
      notificationStore.notify(t(`notification:error.${errorKey}`), 'error');
    }
  };

  const handleFileUpload = (parseMethod?: ParseMethod) => (file: File) => {
    const parserProps: [File, ParserCallbackFn, ParserOptions] = [
      file,
      callback,
      { accountMap: getAccountMap() },
    ];

    switch (parseMethod) {
      case ParseMethod.netvisor:
        return generalLedgerParser.parseNetvisorData(...parserProps);
      default:
        return generalLedgerParser.parseGroupedAccounts(...parserProps);
    }
  };

  if (disabled) return null;

  return (
    <FileUpload
      label={t('common:label.uploadGeneralLedger')}
      accept={['.csv', '.xlsx', '.xls']}
      onFileUpload={handleFileUpload(parseMethod)}
    >
      <ParseMethodContainer>
        <Select
          options={Object.values(ParseMethod)}
          value={parseMethod}
          setValue={setParseMethod}
          displayValue={option =>
            t(`auditing:form.generalLedger.parseMethods.${option}`)
          }
          disabled={disabled}
        />

        <CsvParseMethodExample type={parseMethod} />
      </ParseMethodContainer>
    </FileUpload>
  );
};
