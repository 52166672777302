import { Api } from '../RootApi';

/**
 * Base API for modules.
 *
 * Provides some basic requests for a given entity.
 * E.g. fetch entities, post new entity, patch entity, delete entity etc...
 */
export abstract class ModuleBaseApi<Entity> {
  protected api: Api;
  protected basePath: string;

  constructor(rootApi: Api, basePath: string) {
    this.api = rootApi;
    this.basePath = basePath;
  }

  async getOneEntity({ id }: Api.Payload.GetOne) {
    return await this.api.get<Entity>(`${this.basePath}/${id}`);
  }

  async getAllEntities() {
    return await this.api.get<Entity[]>(this.basePath);
  }

  async addEntity({ data }: Api.Payload.Post<Omit<Entity, 'id'>>) {
    return await this.api.post<Entity>(this.basePath, data);
  }

  async editEntity({ id, data }: Api.Payload.Patch<Partial<Entity>>) {
    return await this.api.patch<Entity>(`${this.basePath}/${id}`, data);
  }

  async deleteEntity({ id }: Api.Payload.Delete) {
    return await this.api.delete<Entity>(`${this.basePath}/${id}`);
  }
}
