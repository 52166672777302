import { t } from '../../../i18n';
import { uuid } from '../../../utils';
import { AccountClassKey, AccountKey } from '../planning/accountMapKeys';
import { Risk } from '../planning/incomeStatementAndBalance';

export enum AuditingProcedureResult {
  notStarted = 'notStarted',
  treatedGood = 'treatedGood',
  treatedModerately = 'treatedModerately',
  shortcomings = 'shortcomings',
  noEvidenceReceived = 'noEvidenceReceived',
}

export const hasShortcomings = ({ auditingResult }: AuditingProcedure) =>
  auditingResult === AuditingProcedureResult.shortcomings;

export const auditingProcedureResultToNumber = (
  auditingProcedureResult: AuditingProcedureResult | null
) => {
  switch (auditingProcedureResult) {
    case AuditingProcedureResult.notStarted:
      return 0;
    case AuditingProcedureResult.treatedGood:
      return 1;
    case AuditingProcedureResult.treatedModerately:
      return 2;
    case AuditingProcedureResult.shortcomings:
      return 3;
    case AuditingProcedureResult.noEvidenceReceived:
      return 4;
    case null:
      return 5;
  }
};

/**
 * Helper to look if a given income statement items have risks
 */
export const incomeStatementAndBalanceItemHasNoRisks =
  (auditing?: Auditing) =>
  (...incomeStatementAndBalanceKeys: string[]) => {
    const { incomeStatement, balanceAssets, balanceLiabilities } =
      auditing?.incomeStatementAndBalance?.form ?? {};

    const keyValueHasSomeRisk = (item: IncomeStatementAndBalanceItem) =>
      incomeStatementAndBalanceKeys.includes(
        item.classKey ? AccountClassKey[item.classKey] : AccountKey[item.key]
      ) &&
      item.risk &&
      [Risk.significant, Risk.limitedRisk].includes(item.risk);

    const itemsHavingSomeRisk = [
      ...(incomeStatement ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssets ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilities ?? []).filter(keyValueHasSomeRisk),
    ];
    const noRisksFound = !itemsHavingSomeRisk.length;

    return noRisksFound;
  };

export enum FinancialStatementBatch {
  'REMARK' = 'REMARK',
  'B-commonActions' = 'B-commonActions',
  'F-financialStatement' = 'F-financialStatement',
  'G-management' = 'G-management',
  'J-operatingIncome' = 'J-operatingIncome',
  'J-turnover' = 'J-turnover',
  'JB-otherOperatingIncome' = 'JB-otherOperatingIncome',
  'K-materialsAndServices' = 'K-materialsAndServices',
  'L-personelCosts' = 'L-personelCosts',
  'OA-deprecations' = 'OA-deprecations',
  'KB-otherOperatingExpenses' = 'KB-otherOperatingExpenses',
  'XJ-financialIncome' = 'XJ-financialIncome',
  'XK-financialExpenses' = 'XK-financialExpenses',
  'OB-financialStatementTransfers' = 'OB-financialStatementTransfers',
  'M-governmentTransfersAndTaxRevenues' = 'M-governmentTransfersAndTaxRevenues',
  'N-taxes' = 'N-taxes',
  'O-fixedAssets' = 'O-fixedAssets',
  'P-inventories' = 'P-inventories',
  'JA-tradeReceivables' = 'JA-tradeReceivables',
  'Q-receivables' = 'Q-receivables',
  'QA-bankBalances' = 'QA-bankBalances',
  'R-equity' = 'R-equity',
  'RA-subordinatedLoans' = 'RA-subordinatedLoans',
  'RB-provisions' = 'RB-provisions',
  'S-loansFromFinancialInstitutions' = 'S-loansFromFinancialInstitutions',
  'KA-tradeCreditors' = 'KA-tradeCreditors',
  'T-otherCreditors' = 'T-otherCreditors',
  'U-assignmentAssetsAndLiabilities' = 'U-assignmentAssetsAndLiabilities',
}

export const initialBatches: FinancialStatementBatch[] = [
  FinancialStatementBatch['B-commonActions'],
  FinancialStatementBatch['F-financialStatement'],
  FinancialStatementBatch['G-management'],
];

export const isDefaultBatch = (batch: FinancialStatementBatch) =>
  initialBatches.includes(batch);

export const isRemarkBatch = ({ batch }: { batch: FinancialStatementBatch }) =>
  batch === FinancialStatementBatch.REMARK;

export const isNotRemarkBatch = ({
  batch,
}: {
  batch: FinancialStatementBatch;
}) => batch !== FinancialStatementBatch.REMARK;

/**
 * Check if auditing procedure has either "period" or "tp" (or both) value set to true.
 */
export const hasPeriodOrTP = (auditingProcedure: AuditingProcedure) => {
  return !!auditingProcedure.period || !!auditingProcedure.tp;
};

/**
 * Filter auditing proceduder that should be visible in "auditing" & "reporting" phases
 */
export const pruneAuditingProcedures =
  (auditing?: Auditing) => (auditingProcedure: AuditingProcedure) => {
    const { batch } = auditingProcedure;

    const batchHasSomeRisks =
      !incomeStatementAndBalanceItemHasNoRisks(auditing)(batch);

    const isValidBatch = isDefaultBatch(batch) || batchHasSomeRisks;

    return isValidBatch && hasPeriodOrTP(auditingProcedure);
  };

export const batchName = (batch: FinancialStatementBatch) =>
  t(`auditing:accountClass.${batch}`);

export enum FinancialStatementEntirety {
  financialStatementAndAccounting = 'financialStatementAndAccounting',
  internalControlAndManagement = 'internalControlAndManagement',
  businessIndicators = 'businessIndicators',
  lawAndTaxation = 'lawAndTaxation',
  inspectionGeneralAndOthers = 'inspectionGeneralAndOthers',
}

// Use shortened Batch & Entirety collections internally (just to keep lines more compact)
const Batch = { ...FinancialStatementBatch };
const Entirety = {
  ...FinancialStatementEntirety,
  // Below is just simple shorthands for entirety enum values:
  '1': FinancialStatementEntirety.financialStatementAndAccounting,
  '2': FinancialStatementEntirety.internalControlAndManagement,
  '3': FinancialStatementEntirety.businessIndicators,
  '4': FinancialStatementEntirety.lawAndTaxation,
  '5': FinancialStatementEntirety.inspectionGeneralAndOthers,
};
type EntiretyKey = '1' | '2' | '3' | '4' | '5';

export const makeAuditingProcedure = (
  props: Partial<AuditingProcedure> & { batch: FinancialStatementBatch }
): AuditingProcedure => {
  const id = uuid();
  return {
    id,
    actionKey: `action-${id}`,
    action: '',
    entirety: null,
    period: null,
    tp: null,
    auditor: '',
    auditingResult: null,
    auditingReference: '',
    auditingComments: '',
    auditingProcedureGoals: '',
    auditingProcedureNatureAndScopeAndExpectations: '',
    auditingProcedurePerceptions: '',
    impactOnIncomeStatement: null,
    impactOnBalanceSheet: null,
    impactOnEquity: null,
    conclusion: '',
    fixed: null,
    ...props,
  };
};

export const transformRemarkToAuditingProcedure = (
  remark: AuditingRemark
): AuditingProcedure =>
  makeAuditingProcedure({
    id: remark.key,
    batch: FinancialStatementBatch.REMARK,
    action: remark.label,
    period: false,
    tp: false,
    auditingResult: AuditingProcedureResult.shortcomings,
    auditingReference: t(`route:auditing.${remark.sectionKey}`),
  });

type Action = string;
type Period = boolean | null;
type TP = boolean | null;
type AuditingProcedureTuple = [Action, EntiretyKey, Period, TP];

const castToTupleArrays = (array: (string | boolean | null)[][]) =>
  array as AuditingProcedureTuple[];

const toAuditingProcedure =
  (batch: FinancialStatementBatch) =>
  ([actionKey, entiretyKey, period, tp]: AuditingProcedureTuple) =>
    makeAuditingProcedure({
      batch,
      actionKey,
      action: t(`auditing:auditingProcedureAction.${batch}.${actionKey}`),
      entirety: Entirety[entiretyKey],
      period,
      tp,
      auditingResult: AuditingProcedureResult.notStarted,
    });

export const defaultAuditingProcedures = (): AuditingProcedure[] => [
  /************************************************************************** /
   * B - Tarkastuksen yleiset toimet
   ***************************************************************************/

  ...castToTupleArrays([
    ['discussionsWithManagement', '5', true, null],
    ['auditPlan', '5', true, null],
    ['confirmationLetter', '5', null, true],
    ['dataRequests', '5', true, true],
    ['reportingInterimReportAndOtherPossibleReports', '5', null, true],
  ]).map(toAuditingProcedure(Batch['B-commonActions'])),

  /************************************************************************** /
   * F - Tilinpäätös ja kirjanpito
   ***************************************************************************/

  ...castToTupleArrays([
    ['complianceOfAccountingPolicies', '2', true, null],
    ['gitcUserRights', '2', true, null],
    ['auditOfFinancialStatementNotes', '1', null, true],
    ['auditOfInterimPeriodNotes', '1', true, null],
    ['auditOfOpeningBalance', '1', true, null],
    ['reviewOfOpeningBalanceAndPreviousFinancialStatements', '1', true, null],
    ['analyticalReviewOfIncomeStatementAndBalanceSheet', '1', true, true],
    ['reviewOfFinancialStatementPresentationAndContent', '1', null, true],
    ['reviewOfManagementReportContent', '1', null, true],
    ['consolidationAudit', '1', null, true],
    ['auditOfEventsAfterTheBalanceSheetDate', '1', null, true],
    ['reviewOfFinancialStatementSignatures', '1', null, true],
    ['generalLedgerVsSubLedgerMatchings', '1', null, null],
    ['identificationAndAuditOfHighRiskItems', '1', null, null],
  ]).map(toAuditingProcedure(Batch['F-financialStatement'])),

  /************************************************************************** /
   * G - Hallinto
   ***************************************************************************/

  ...castToTupleArrays([
    ['publicNetworkDocuments', '2', true, null],
    ['municipalStrategy', '2', true, null],
    ['administrativeRule', '2', true, null],
    ['budget', '2', true, null],
    ['internalInstructions', '2', true, null],
    ['groupInstruction', '2', true, null],
    ['boardMeetingMinutes', '2', true, true],
    ['councilMeetingMinutes', '2', true, null],
    ['reviewOfPriorityBoards', '2', true, null],
    ['reviewOfPriorityOfficials', '2', true, null],
    ['insurancePolicies', '2', true, null],
    ['affiliationRegister', '2', true, null],
    ['auditOfRelatedPartyTransactions', '2', true, null],
  ]).map(toAuditingProcedure(Batch['G-management'])),

  /************************************************************************** /
   * J - Liikevaihto
   ***************************************************************************/

  ...castToTupleArrays([
    ['analyticalReviewOfSales', '1', true, true],
    ['processMappingWtAndMainAccountingPrinciples', '2', true, null],
    ['reviewOfIndividualTransactions', '1', true, null],
    ['matchingOfSalesToInvoicing', '1', true, null],
    ['reviewOfRevenueRecognition', '1', true, true],
    ['reviewOfSalesCutoff', '1', null, true],
    ['dataAnalysis', '1', true, null],
    ['completenessOfRevenue', '1', null, true],
    ['reviewOfCreditInvoices', '1', true, null],
    ['reviewOfSignificantContracts', '1', true, null],
    ['controlTesting', '2', true, null],
    ['reviewOfCreditInvoicesInCurrentFinancialYear', '1', null, true],
    ['reviewOfUnbilledDeliveriesListing', '1', null, null],
  ]).map(toAuditingProcedure(Batch['J-turnover'])),

  /************************************************************************** /
   * JB - Liiketoiminnan muut tuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['individualTransactionInspection', '1', true, null],
    ['analyticalReview', '1', true, null],
    ['accuracyOfBreaks', '1', null, true],
  ]).map(toAuditingProcedure(Batch['JB-otherOperatingIncome'])),

  /************************************************************************** /
   * JA - Myyntisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['matchingOfAccountsReceivableToSubLedger', '1', null, true],
    ['mathematicalAccuracyOfAccountsReceivableLedger', '1', null, true],
    ['agingOfAccountsReceivable', '1', null, true],
    ['reviewOfAccountsReceivableIncludingNegative', '1', null, true],
    ['reviewOfIndividualTransactions', '1', null, true],
    ['reviewOfOpenAccountsReceivablePayments', '1', null, true],
    ['controlMatchingCheck', '2', true, true],
    ['categorizationOfReceivables', '1', null, true],
  ]).map(toAuditingProcedure(Batch['JA-tradeReceivables'])),

  /************************************************************************** /
   * K - Materiaalit ja palvelut
   ***************************************************************************/

  ...castToTupleArrays([
    ['reviewOfIndividualTransactions', '1', true, null],
    ['riskAnalysisReview', '1', true, null],
    ['analysisOfProfitMargins', '1', true, true],
    ['dataAnalysis', '1', true, null],
    ['matchingOfAccountsPayableListToGeneralLedger', '1', true, null],
    ['classificationOfPurchases', '1', true, null],
    ['verificationOfPurchaseCutoff', '1', null, true],
    ['controlTesting', '2', true, null],
    ['processMappingWTAndKeyAccountingPolicies', '2', true, null],
    ['pivotingOfSuppliersAndReviewOfTheList', '1', true, null],
  ]).map(toAuditingProcedure(Batch['K-materialsAndServices'])),

  /************************************************************************** /
   * KA - Ostovelat
   ***************************************************************************/

  ...castToTupleArrays([
    ['matchingOfPurchaseLedger', '1', null, true],
    ['mathematicalAccuracyOfPurchaseLedger', '1', null, true],
    [
      'ageDistributionOfAccountsPayableAndUnusualBalancesInclNegative',
      '1',
      null,
      true,
    ],
    ['dataAnalysis', '1', true, null],
    ['matchingControlInspection', '2', true, true],
  ]).map(toAuditingProcedure(Batch['KA-tradeCreditors'])),

  /************************************************************************** /
   * L - Henkilöstökulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['analyticalReviewOfWages', '1', null, true],
    ['analyticalAuditOfPersonnelOverheadCosts', '1', null, true],
    ['analyticalAuditOfWages', '1', null, true],
    ['matchingOfWagesToAnnualReport', '1', null, true],
    ['personnelCostsPeriodization', '1', null, true],
    ['auditOfVacationPayAccruals', '1', null, true],
    ['matchingOfVacationPayAccruals', '1', null, true],
    ['analyticalAuditOfVacationPayAccruals', '1', null, true],
    ['auditOfWages', '2', null, true],
    ['auditOfIndividualTransactions', '1', null, true],
    ['processMappingWithKeyAccountingPrinciples', '2', true, null],
  ]).map(toAuditingProcedure(Batch['L-personelCosts'])),

  /************************************************************************** /
   * KB - Liiketoiminnan muut kulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['inspectionOfIndividualTransactions', '1', null, true],
    ['riskAnalysis', '1', null, true],
    ['inspectionOfVouchersFromMostSignificantAccounts', '1', null, true],
    ['verificationOfRentExpenses', '1', null, true],
    ['analyticalInspection', '1', null, true],
    ['classificationOfExpenses', '1', null, true],
    ['legalExpenses', '1', true, null],
  ]).map(toAuditingProcedure(Batch['KB-otherOperatingExpenses'])),

  /************************************************************************** /
   * M - Valtionosuudet ja verotulot
   ***************************************************************************/

  ...castToTupleArrays([
    ['reliabilityOfGovernmentSharesStudentInformation', '2', true, null],
    ['reliabilityOfGovernmentSharesBasicServices', '2', true, true],
    ['settlingForGovernmentSharesStudentInformation', '2', true, null],
    ['settlingForGovernmentSharesBasicServices', '2', true, null],
    ['taxReconciliationAccountingVersusTaxAuthority', '1', null, true],
    ['govermentSharesReconciliationStudentInformation', '1', null, true],
    ['govermentSharesReconciliationBasicServices', '1', null, true],
  ]).map(toAuditingProcedure(Batch['M-governmentTransfersAndTaxRevenues'])),

  /************************************************************************** /
   * XJ - Rahoitustuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['substantiveAuditOfFinancialIncome', '1', true, true],
    ['analyticalAudit', '1', true, null],
    ['matchingOfIncomeToPortfolioReport', '1', null, true],
    ['reviewOfContracts', '1', true, null],
    ['examinationOfIndividualVouchers', '1', true, null],
    ['reviewOfPayment', '1', null, true],
  ]).map(toAuditingProcedure(Batch['XJ-financialIncome'])),

  /************************************************************************** /
   * XK - Rahoituskulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['substantiveAuditOfFinancialExpenses', '1', true, true],
    ['analyticalAuditOfInterestExpense', '1', null, true],
    ['auditBasedOnContracts', '1', true, null],
    ['matchingOfExpensesToPortfolioReport', '1', null, true],
    ['auditOfIndividualDocuments', '1', true, null],
  ]).map(toAuditingProcedure(Batch['XK-financialExpenses'])),

  /************************************************************************** /
   * O - Käyttöomaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['additions', '1', true, null],
    ['deductions', '1', true, null],
    ['depreciationAudit', '1', null, true],
    ['utilizationOfEVLmaxDepreciation', '4', true, null],
    ['utilizationOfDoubleDepreciation', '4', true, null],
    ['valuationOfBuildings', '1', true, null],
    ['valuationOfIntangibleAssets', '1', true, null],
    ['valuationOfInvestments', '1', true, null],
    ['matchingOfFixedAssetsSubledgerToGeneralLedger', '1', null, true],
    ['matchingOfDepreciationAccrualsSubledgerToGeneralLedger', '1', null, true],
    ['depreciationAccrualAudit', '1', null, true],
    ['processMappingWTAndKeyAccountingPrinciples', '2', true, null],
  ]).map(toAuditingProcedure(Batch['O-fixedAssets'])),

  /************************************************************************** /
   * P - Vaihto-omaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['participationInInventory', '1', true, null],
    ['matchingOfInventoryChanges', '1', null, true],
    ['valuationAndPricingAuditOfInventory', '1', null, true],
    ['matchingOfInventoryListToLedger', '1', null, true],
    [
      'auditOfDifferencesBetweenFinalInventoryAndInventoryList',
      '1',
      null,
      true,
    ],
    ['dataAnalysis', '1', null, true],
    ['processMappingWTAndKeyAccountingPrinciples', '2', true, null],
    ['analysisOfInventoryResults', '2', null, true],
    ['analysisOfSlowMovingItems', '2', null, true],
  ]).map(toAuditingProcedure(Batch['P-inventories'])),

  /************************************************************************** /
   * Q - Saamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['existenceAndClassificationOfInterCompanyReceivables', '1', null, true],
    ['existenceAndClassificationOfOtherReceivables', '1', null, true],
    [
      'loansFromRelatedPartiesAndTheirMainConditionsAndGuarantees',
      '2',
      true,
      null,
    ],
  ]).map(toAuditingProcedure(Batch['Q-receivables'])),

  /************************************************************************** /
   * QA - Rahat ja pankkisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['matchingWithConfirmation', '1', null, true],
    ['matchingWithBankStatement', '1', null, true],
    ['matchingOfAnnualTransactions', '1', null, true],
    ['existenceOfInvestments', '1', null, true],
    ['valuationOfInvestments', '1', null, true],
    ['additionsToInvestments', '1', null, true],
    ['reductionsToInvestments', '1', null, true],
    ['matchingOfInvestmentsWithConfirmation', '1', null, true],
    ['examinationOfIndividualPayments', '1', true, null],
    ['reviewOfTransactionsDuringTheFinancialYear', '1', true, null],
    ['processMappingWTAndKeyAccountingPrinciples', '2', true, null],
    ['checkingBalancesDirectlyFromBankingSoftware', '1', null, null],
  ]).map(toAuditingProcedure(Batch['QA-bankBalances'])),

  /************************************************************************** /
   * R - Oma pääoma
   ***************************************************************************/

  ...castToTupleArrays([
    ['continuityOfEquity', '1', true, null],
    ['presentationOfEquity', '1', true, null],
    ['complianceOfProfitLossTreatment', '1', true, null],
    ['auditOfEquityChangesToShareholderDecisions', '2', true, null],
    ['sufficiencyOfEquity', '2', null, true],
    ['solvencyTest', '2', null, null],
    ['matchingOfLoanToEquity', '1', null, null],
    ['writtenTermsAndClassificationOfLoans', '1', null, null],
    ['interestLiabilityAndDisclosureOfLoans', '1', null, null],
    ['POLRepaymentAndInterestTreatment', '2', null, null],
    ['appropriatenessOfDividendProposal', '2', null, null],
  ]).map(toAuditingProcedure(Batch['R-equity'])),

  /************************************************************************** /
   * S - Lainat rahoituslaitoksilta
   ***************************************************************************/

  ...castToTupleArrays([
    ['existenceAndCompleteness', '1', null, true],
    ['verificationOfLoanBalanceWithDebtInstrument', '1', null, true],
    ['verificationOfLoanBalanceWithConfirmationOfBalance', '1', null, true],
    ['classificationOfLoans', '1', null, true],
    ['reviewOfLoanAgreementsAndSpecialConditions', '1', true, null],
    ['reviewOfCovenantsAndTheirCompliance', '1', true, null],
    ['managementOfLoanBalancesBasedOnPreviousPeriods', '1', null, true],
  ]).map(toAuditingProcedure(Batch['S-loansFromFinancialInstitutions'])),

  /************************************************************************** /
   * T - Muut velat
   ***************************************************************************/

  ...castToTupleArrays([
    ['existenceClassificationAndMatchingOfOtherLiabilities', '1', null, true],
    ['existenceClassificationAndMatchingOfAccruedExpenses', '1', null, true],
    ['examinationOfIndividualTransactions', '1', null, true],
  ]).map(toAuditingProcedure(Batch['T-otherCreditors'])),

  /************************************************************************** /
   * N - Verot
   ***************************************************************************/

  ...castToTupleArrays([
    ['latestTaxDecision', '4', true, null],
    ['taxPeriodization', '1', null, true],
    ['advanceTaxVerification', '1', true, null],
    ['reviewOfTaxTypesUsed', '4', true, null],
  ]).map(toAuditingProcedure(Batch['N-taxes'])),
];

export const makeEmptyThresholdTestRow = (
  thresholdTestRow?: Partial<ThresholdTestRow>
): ThresholdTestRow => {
  const id = uuid();
  return {
    id,
    key: `${id}-custom`,
    value: null,
    ...thresholdTestRow,
  };
};

/**
 * Returns the value of the threshold test row item if it is set to use custom value.
 * Otherwise returns the given default value or the default value of the row item itself.
 */
export const getValueOrDefaultValue = (
  rowItem?: ThresholdTestRow,
  defaultValue?: ThresholdTestRowValue
) => {
  const value = rowItem?.useCustomValue
    ? rowItem?.value
    : defaultValue ?? rowItem?.defaultValue;
  return value ?? 0;
};
