import { lazy } from 'react';
import { path, rt } from '../i18n/utils';
import { Role } from '../utils/constants';
import manageRoutes from './routes.manage';
import auditingRoutes from './routes.auditing';
import { notFoundRoute } from './routes.common';

const FrontPage = lazy(() => import('../views/FrontPage'));
const UserProfile = lazy(() => import('../views/UserProfile'));
const AuditingSelection = lazy(() => import('../views/AuditingSelection'));

const homeRoutes: Route[] = [
  {
    path: '/',
    index: true,
    title: rt('frontPage'),
    Element: <FrontPage />,
  },
  {
    path: `/${path('myProfile')}`,
    title: rt('myProfile'),
    Element: <UserProfile />,
  },

  {
    path: `/${path('auditing')}`,
    title: rt('auditing'),
    Element: <AuditingSelection />,
    roles: [Role.Auditor, Role.Customer],
  },
  notFoundRoute,
];

const appRoutes = {
  homeRoutes,
  manageRoutes,
  auditingRoutes,
};

export default appRoutes;
