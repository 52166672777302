import React from 'react';
import { InputCheckboxContainer } from './InputContainer';
import { Label } from './Label';

interface Props extends CommonInputProps {
  value: string | number;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue?: React.Dispatch<React.SetStateAction<any>>;
}

export const Radio: React.FC<Props> = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  setValue,
  autoFocus,
  required,
  disabled = false,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    if (setValue) setValue(event.currentTarget.value);
  };

  return (
    <InputCheckboxContainer id={id} data-testid={id}>
      <Label
        disabled={disabled}
        className={required ? 'text-normal is-required' : 'text-normal'}
      >
        <input
          data-testid={`${id}-input`}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled}
        />
        <span>{label}</span>
      </Label>
    </InputCheckboxContainer>
  );
};

export default Radio;
