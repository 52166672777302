import appRoutes from '../../router/routes';
import { useStore } from '../../store/RootContext';
import { AuditingTemplate, OMITTED_SECTIONS } from '../auditing';
import { getUserRoutes } from '../route';

const omitSections =
  (template?: AuditingTemplate) =>
  ({ id }: Route) => {
    // In this case, route id should always match auditing section key, thus casting should be safe.
    const sectionKey = id as AuditingSectionKey;
    if (!template || !sectionKey) return false;
    return !OMITTED_SECTIONS[template].includes(
      sectionKey as AuditingSectionKey
    );
  };

export const useGroupedAuditingRoutes = (template?: AuditingTemplate) => {
  const {
    authStore: { role },
  } = useStore();

  const auditingRoutes = getUserRoutes(appRoutes.auditingRoutes, role.type)
    .filter(({ isHidden }) => !isHidden)
    .filter(omitSections(template));

  const hasCategory = (category: AuditingCategory) => (item: Route) =>
    item.category?.includes(category);

  return {
    start: auditingRoutes.filter(hasCategory('start')),
    planning: auditingRoutes.filter(hasCategory('planning')),
    auditing: auditingRoutes.filter(hasCategory('auditing')),
    reporting: auditingRoutes.filter(hasCategory('reporting')),
    end: auditingRoutes.filter(hasCategory('end')),
  };
};
