import { IncomeStatementAndBalanceTable } from '../../../components/auditing/IncomeStatementAndBalance';
import { IncomeStatementAndBalanceComputeds } from '../../../components/auditing/IncomeStatementAndBalance.ComputedsTable';
import { MaterialitiesTable } from '../../../components/auditing/Materiality.MaterialitiesTable';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = IncomeStatementAndBalanceForm;

const sectionKey: AuditingSectionKey = 'incomeStatementAndBalance';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

export enum Risk {
  notSignificant = 'notSignificant',
  limitedRisk = 'limitedRisk',
  significant = 'significant',
}

const defaultFormState: DefaultFormState<SectionFormType> = {
  incomeStatement: [],
  balanceAssets: [],
  balanceLiabilities: [],
};

const formFields: FormFields<SectionFormType> = ({ auditing }) => [
  formBuilder.accordionGroup({
    open: true,
    titleKey: 'incomeStatement',
    items: [
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable
          targetKey="incomeStatement"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceAssets',
    items: [
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable targetKey="balanceAssets" {...props} />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceLiabilities',
    items: [
      formBuilder.custom(props => (
        <IncomeStatementAndBalanceTable
          targetKey="balanceLiabilities"
          {...props}
        />
      )),
    ],
  }),

  formBuilder.custom(props => (
    <IncomeStatementAndBalanceComputeds {...props} />
  )),

  formBuilder.custom(props => {
    const formState = auditing?.materiality?.form;
    if (!formState) return;
    return <MaterialitiesTable {...props} formState={formState} readonly />;
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMap', 'generalLedger'],
};

export default section;
