import i18n from '../../../i18n';
import { makeEmptyBalanceComparison } from './balanceComparisonUtils';

export enum IsDecisionMade {
  yes = 'yes',
  no = 'no',
}

export enum TaxRevenueDifference {
  no = 'no',
  yes = 'yes',
}

export const makeDefaultMatchingOfTaxRevenuesRows = () => {
  const tBase = 'auditing:form.governmentTransfersAndTaxRevenues.balanceMatchingOfTaxRevenues';
  return [
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfMunicipalTax`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfPropertyTax`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.shareOfCorporateTaxRevenue`),
    }),
  ];
};

export const makeDefaultMatchingOfGovernmentSharesRows = () => {
  const tBase = 'auditing:form.governmentTransfersAndTaxRevenues.balanceMatchingOfGovernmentShares';
  return [
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfBasicServices`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfEducationAndCulturalActivities`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfOtherGovernmentShares`),
    }),
    makeEmptyBalanceComparison({
      label: i18n.t(`${tBase}.balanceMatchingOfCompensationAdjustments`),
    }),
  ];
};
