import { Api } from '../RootApi';
import { ModuleBaseApi } from './ModuleBaseApi';

const URL = {
  base: 'auditings',
};

export class AuditingApi extends ModuleBaseApi<Auditing> {
  constructor(rootApi: Api) {
    super(rootApi, URL.base);
  }
}
