import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { Button, TextInput } from '../inputs';

interface Props {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  onSubmit?: () => void;
}

const PasswordForgotForm: React.FC<Props> = observer(
  ({ email, setEmail, onSubmit }) => {
    const { t } = useTranslation(['common', 'action']);

    const {
      authStore: { forgotPassword },
    } = useStore();

    const handleSendForgotPasswordLink = (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();
      forgotPassword({ email });
      if (onSubmit) onSubmit();
    };

    return (
      <form onSubmit={handleSendForgotPasswordLink}>
        <TextInput
          type="email"
          label={t('common:label.email')}
          value={email}
          setValue={setEmail}
          autoFocus
          required
          fullWidth
        />
        <Button text={t('action:send')} type="submit" />
      </form>
    );
  }
);

export default PasswordForgotForm;
