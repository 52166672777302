import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { path } from '../i18n/utils';
import { useStore } from '../store/RootContext';
import NotFound404 from './404';

export const FrontPage: React.FC = observer(() => {
  const {
    authStore: { role },
  } = useStore();

  if (role.isAuditor || role.isCustomer)
    return <Navigate to={`/${path('auditing')}`} />;
  if (role.isAdmin || role.isAuditorAdmin)
    return <Navigate to={`/${path('manage', 'manage.users')}`} />;
  return <NotFound404 />;
});

export default FrontPage;
