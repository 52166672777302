import React from 'react';
import { path, rt } from '../i18n/utils';
import { Role } from '../utils/constants';
import AuditingSection from '../views/AuditingSection';
import { notFoundRoute } from './routes.common';

// Helper function to compose routes
function auditingRoute<SectionForm>(
  sectionKey: AuditingSectionKey,
  category: AuditingCategory[],
  CustomSectionElement?: JSX.Element
) {
  return {
    id: sectionKey,
    path: path(`auditing.${sectionKey}`),
    title: rt(`auditing.${sectionKey}`),
    roles: [Role.Auditor],
    category,
    Element: CustomSectionElement ?? (
      <AuditingSection<SectionForm> sectionKey={sectionKey} />
    ),
  };
}

const auditingRoutes: Route[] = [
  // Precending routes
  auditingRoute<AuditingFrontPageForm>('auditingFrontPage', ['start']),

  // Planning routes
  auditingRoute<PermanentDataForm>('permanentData', ['planning']),
  auditingRoute<AccountMapForm>('accountMap', ['planning']),
  auditingRoute<GeneralLedgerForm>('generalLedger', ['planning']),
  auditingRoute<IncomeStatementAndBalanceForm>('incomeStatementAndBalance', [
    'planning',
  ]),
  auditingRoute<IndependenceForm>('independence', ['planning']),
  auditingRoute<AuditingPlanningForm>('auditingPlanning', ['planning']),
  auditingRoute<InternalControlAndOperatingEnvironmentForm>(
    'internalControlAndOperatingEnvironment',
    ['planning']
  ),
  auditingRoute<ItAndProcessesForm>('itAndProcesses', ['planning']),
  auditingRoute<RisksForm>('risks', ['planning']),
  auditingRoute<MaterialityForm>('materiality', ['planning']),
  auditingRoute<AuditingProceduresForm>('detailedPlan', ['planning']),

  // Auditing routes
  auditingRoute<AuditingProceduresForm>('auditingProcedures', ['auditing']),
  auditingRoute<ManagementForm>('management', ['auditing']),
  auditingRoute<PersonelCostsForm>('personelCosts', ['auditing']),
  auditingRoute<GovernmentTransfersAndTaxRevenuesForm>(
    'governmentTransfersAndTaxRevenues',
    ['auditing']
  ),

  // Reporting routes
  auditingRoute<AuditingProceduresForm>('errors', ['reporting']),
  auditingRoute<ClosureForm>('closure', ['reporting']),
  auditingRoute<CommunicationAndReportingForm>('communicationAndReporting', [
    'reporting',
  ]),

  // Rest of the routes
  auditingRoute('instructions', ['end']),
  notFoundRoute,
];

export default auditingRoutes;
