import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/RootContext';
import { formatPercent, useGroupedAuditingRoutes } from '../../utils';
import RemarksSection from './DegreeOfReadiness.RemarksSection';
import StatusSection from './DegreeOfReadiness.StatusSection';

export const getReadinessPercent = (status: AuditingStatusInformation) => {
  const finished = status.approved + status.finished;
  const unfinished = status.not_started + status.started;
  const percent = (finished / (finished + unfinished)) * 100;
  return formatPercent(isNaN(percent) ? 0 : percent);
};

const DegreeOfReadiness: React.FC = () => {
  const { t } = useTranslation();

  const {
    auditingStore: {
      selectedEntity: auditing,
      getAuditingStatusInformation,
      getAuditingRemarks,
    },
  } = useStore();

  const auditingRoutes = useGroupedAuditingRoutes();

  if (!auditing) return null;

  const toSectionKey = ({ id }: Route) => id as AuditingSectionKey;

  const { totalStatus, plannningStatus, auditingStatus, reportingStatus } =
    getAuditingStatusInformation({
      planningSectionKeys: auditingRoutes.planning.map(toSectionKey),
      auditingSectionKeys: auditingRoutes.auditing.map(toSectionKey),
      reportingSectionKeys: auditingRoutes.reporting.map(toSectionKey),
    });

  const getTitle = (
    key: 'totalStatus' | 'planning' | 'auditing' | 'reporting',
    status?: AuditingStatusInformation
  ) => {
    const percent = status ? getReadinessPercent(status) : undefined;
    const text = t(`auditing:${key}`);
    return percent ? `${text} ${percent}` : text;
  };

  const allStatusSections = [
    { title: getTitle('totalStatus'), statusInformation: totalStatus },
    {
      title: getTitle('planning', plannningStatus),
      statusInformation: plannningStatus,
    },
    {
      title: getTitle('auditing', auditingStatus),
      statusInformation: auditingStatus,
    },
    {
      title: getTitle('reporting', reportingStatus),
      statusInformation: reportingStatus,
    },
  ];

  const [totalStatusSection, ...statusSections] = allStatusSections;

  const totalReadinessPercent = getReadinessPercent(totalStatus);

  return (
    <div>
      <h2>{`${t('auditing:degreeOfReadiness')} ${totalReadinessPercent}`}</h2>

      <div className="flex-row flex-wrap justify-start align-start">
        <StatusSection {...totalStatusSection} />
        <RemarksSection
          title={t('auditing:remarks.title')}
          remarks={getAuditingRemarks().length}
        />
      </div>

      <div className="flex-row flex-wrap justify-start align-start">
        {statusSections.map(statusSection => (
          <StatusSection key={statusSection.title} {...statusSection} />
        ))}
      </div>
    </div>
  );
};

export default DegreeOfReadiness;
