import { action, computed, makeObservable, toJS } from 'mobx';
import { DateTime } from 'luxon';
import { Api } from '../../api/RootApi';
import {
  AuditingTemplate,
  formatDateTime,
  getPercent,
  round,
  today,
} from '../../utils';
import { BaseEntityStore } from '../BaseEntityStore';
import { RootStore } from '../RootStore';
import { REMARK_RULES } from '../../views/auditingSections/remarkRules';
import { t } from '../../i18n';
import { path } from '../../i18n/utils';
import { LegalSituation } from '../../views/auditingSections/planning/permanentDataUtils';
import { generateAccountMap } from '../../views/auditingSections/planning/accountMapUtils';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';
import {
  FinancialStatementBatch,
  hasPeriodOrTP,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import {
  getAverageOfLiabilitiesAndRevenues,
  getCombinedRevenues,
} from '../../views/auditingSections/planning/materialityUtils';

export class AuditingStore extends BaseEntityStore<Auditing> {
  /**
   * Observables
   */

  constructor(rootStore: RootStore, api: Api) {
    super(rootStore, api, api.auditing, 'AUDITING');

    makeObservable(this, {
      auditing: computed,
      companyName: computed,
      auditingStatus: computed,
      auditingTemplate: computed,
      auditingLocked: computed,
      isAuditingSelected: computed,

      clearAuditing: action,

      resetSectionForm: action,
      setAuditingSectionStatus: action,

      getLinkToAuditingSection: action,
      getFinancialYear: action,
      getFinancialPeriodMonthCount: action,
      getFinancialPeriodMonthCountFromVacationPeriodStart: action,
      getFinancialItem: action,
      getFinancialNumber: action,
      getAuditingSection: action,
      getAuditingSectionStatus: action,
      isAuditingSectionFinished: action,
      getAuditingStatusInformation: action,
      getAuditingSectionFormField: action,
      getAccountMap: action,
      getAuditingProceduresBatch: action,
      getAuditingRemarks: action,
      isMarkedAsRemark: action,
      fetchTaxRegisterInformation: action,

      materialityComputeds: action,
    });
  }

  /**
   * Computeds
   */

  get auditing() {
    return this.selectedEntity;
  }

  get companyName() {
    return this.selectedEntity?.customerCompany?.name ?? '';
  }

  get auditingStatus() {
    return this.selectedEntity?.status;
  }

  get auditingTemplate() {
    return this.selectedEntity?.template;
  }

  get auditingLocked() {
    const auditing = this.selectedEntity;
    return auditing && this.isFinishedStatus(auditing.status);
  }

  get isAuditingSelected() {
    return this.entity;
  }

  /**
   * Actions
   */

  clearAuditing = () => {
    this.entity = undefined;
  };

  resetSectionForm = async (sectionKey: AuditingSectionKey) => {
    const id = this.entity?.id;

    if (!id) return;

    const section = this.getAuditingSection(sectionKey);

    const auditingPatch = {
      [sectionKey]: { ...section, form: [], status: 'not_started' },
    };

    await this.editEntity({ id, data: auditingPatch }, { isSilent: true });
  };

  setAuditingSectionStatus = (
    sectionKey: AuditingSectionKey,
    status: AuditingSectionStatus
  ) => {
    const id = this.entity?.id;
    if (!id) return;

    const userId = this.rootStore.authStore.userId;

    const section = this.getAuditingSection(sectionKey);
    const isFinished = status === 'finished';
    const isApproved = status === 'approved';

    const auditingPatch = {
      [sectionKey]: {
        ...section,
        updatedAt: today(),
        updatedBy: userId,
        finishedAt: isFinished ? today() : section?.finishedAt,
        finishedBy: isFinished ? userId : section?.finishedBy,
        approvedAt: isApproved ? today() : section?.approvedAt,
        approvedBy: isApproved ? userId : section?.approvedBy,
        status,
      },
    };

    this.editEntity({ id, data: auditingPatch }, { isBackground: true });
  };

  /**
   * Getter actions
   */

  getLinkToAuditingSection = (props: {
    auditingId?: number;
    sectionKey: AuditingSectionKey;
  }) => {
    const id = props.auditingId ?? this.selectedEntity?.id;
    if (id) {
      const sectionPath = path(`auditing.${props.sectionKey}`);
      return `/${path('auditing')}/${id}/${sectionPath}`;
    }
    return `/${path('auditing')}`;
  };

  getFinancialYear = (auditing?: Auditing) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return '';

    const format = 'D';
    const startDate = formatDateTime(entity.startDate, { format });
    const endDate = formatDateTime(entity.endDate, { format });
    return `${startDate}-${endDate}`;
  };

  getFinancialPeriodMonthCount = (auditing?: Auditing) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return null;

    const startDate = DateTime.fromISO(entity.startDate);
    const endDate = DateTime.fromISO(entity.endDate);
    return round(endDate.diff(startDate, 'months').months, 1);
  };

  getFinancialPeriodMonthCountFromVacationPeriodStart = (
    auditing?: Auditing
  ) => {
    const entity = auditing ?? this.selectedEntity;

    if (!entity) return null;

    const vacationPeriodStart = { day: 1, month: 4 };

    const startDate = DateTime.fromISO(entity.startDate).set(
      vacationPeriodStart
    );
    const endDate = DateTime.fromISO(entity.endDate);
    return round(endDate.diff(startDate, 'months').months, 1);
  };

  getFinancialItem = (key: AccountKey | 'other') => {
    const form = this.entity?.incomeStatementAndBalance?.form;
    const incomeStatement = form?.incomeStatement;
    const balanceAssets = form?.balanceAssets;
    const balanceLiabilities = form?.balanceLiabilities;
    return (
      incomeStatement?.find(item => item.key === key) ??
      balanceAssets?.find(item => item.key === key) ??
      balanceLiabilities?.find(item => item.key === key)
    );
  };

  getFinancialNumber = (
    key: AccountKey | 'other' | 'assetsLiabilitiesDifference' | null,
    value: 'currentYear' | 'priorYear' = 'currentYear'
  ) => {
    if (!key) return;

    let number: number | undefined = undefined;

    if (key === 'assetsLiabilitiesDifference') {
      const [assets, liabilities] = [
        this.getFinancialItem(AccountKey.balanceAssets)?.[value],
        this.getFinancialItem(AccountKey.balanceLiabilities)?.[value],
      ];
      if (assets !== undefined && liabilities !== undefined)
        number = assets - liabilities;
    } else {
      number = this.getFinancialItem(key)?.[value];
    }

    return number ? round(number) : number;
  };

  getAuditingSection = (sectionKey: AuditingSectionKey) => {
    return toJS(this.entity?.[sectionKey]);
  };

  getAuditingSectionStatus = (sectionKey: AuditingSectionKey) => {
    const section = this.getAuditingSection(sectionKey);
    return section?.status ?? 'not_started';
  };

  isFinishedStatus = (status: AuditingStatus | AuditingSectionStatus) =>
    ['finished', 'approved'].includes(status);

  isAuditingSectionFinished = (sectionKey: AuditingSectionKey) => {
    const status = this.getAuditingSectionStatus(sectionKey);
    return this.isFinishedStatus(status);
  };

  getAuditingStatusInformation = (props: {
    planningSectionKeys: AuditingSectionKey[];
    auditingSectionKeys: AuditingSectionKey[];
    reportingSectionKeys: AuditingSectionKey[];
  }) => {
    const { planningSectionKeys, auditingSectionKeys, reportingSectionKeys } =
      props;

    const initialStatus: AuditingStatusInformation = {
      approved: 0,
      finished: 0,
      started: 0,
      not_started: 0,
    };

    const totalStatus: AuditingStatusInformation = { ...initialStatus };
    const plannningStatus: AuditingStatusInformation = { ...initialStatus };
    const auditingStatus: AuditingStatusInformation = { ...initialStatus };
    const reportingStatus: AuditingStatusInformation = { ...initialStatus };

    const addStatus =
      (statusGroup: AuditingStatusInformation) =>
      (sectionKey: AuditingSectionKey) => {
        const status =
          this.selectedEntity?.[sectionKey]?.status ?? 'not_started';
        totalStatus[status] += 1;
        statusGroup[status] += 1;
      };

    planningSectionKeys.forEach(addStatus(plannningStatus));
    auditingSectionKeys.forEach(addStatus(auditingStatus));
    reportingSectionKeys.forEach(addStatus(reportingStatus));

    return {
      totalStatus,
      plannningStatus,
      auditingStatus,
      reportingStatus,
    };
  };

  getAuditingSectionFormField = (
    sectionKey: AuditingSectionKey,
    accessor: AuditingSectionFormAccessor
  ) => {
    const auditing = this.selectedEntity;

    if (typeof accessor === 'function') {
      return accessor(auditing?.[sectionKey]);
    } else {
      const accessors = accessor.split('.');
      switch (accessors.length) {
        case 2:
          const [a1of2, a2of2] = accessors;
          return auditing?.[sectionKey]?.form[a1of2]?.[a2of2];
        case 3:
          const [a1of3, a2of3, a3of3] = accessors;
          return auditing?.[sectionKey]?.form[a1of3]?.[a2of3]?.[a3of3];
        // Add more cases if need to access deeper levels.
        default:
          return auditing?.[sectionKey]?.form[accessor];
      }
    }
  };

  getAccountMap = (): AccountMap =>
    this.selectedEntity?.accountMap?.form.accountMap ??
    generateAccountMap(this.auditingTemplate ?? AuditingTemplate.private);

  getAuditingProceduresBatch = (
    batch: FinancialStatementBatch,
    shouldHavePeriodOrTP = true
  ): AuditingProcedure[] => {
    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const auditingProcedures: AuditingProcedure[] =
      this.getAuditingSectionFormField(sectionKey, 'auditingProcedures') ?? [];

    return auditingProcedures.filter(
      procedure =>
        procedure.batch === batch &&
        (shouldHavePeriodOrTP ? hasPeriodOrTP(procedure) : true)
    );
  };

  private resolveDependentRemarkRule =
    (parentSectionKey: AuditingSectionKey) =>
    (dependingRemarkRule: DependingRemarkRule) => {
      const { remarkTriggers, accessor } = dependingRemarkRule;
      const sectionKey = dependingRemarkRule.sectionKey ?? parentSectionKey;
      const value = this.getAuditingSectionFormField(sectionKey, accessor);
      return remarkTriggers.includes(value);
    };

  private resolveRemarkRule = (
    remarkRule: RemarkRule
  ): AuditingRemark & { isRemark: boolean } => {
    const { sectionKey, accessor, accessorKey, remarkTriggers, depends } =
      remarkRule;
    const isDepending = !!depends?.length;
    const value = this.getAuditingSectionFormField(sectionKey, accessor);

    const bypass = !remarkTriggers.length;
    const remarkTriggered = bypass || remarkTriggers.includes(value);
    const dependingRemarks = depends
      ?.map(this.resolveDependentRemarkRule(sectionKey))
      .filter(dependentRemarkTriggered => dependentRemarkTriggered);
    const allDependingRemarksTriggered =
      dependingRemarks?.length === depends?.length;

    const isRemark =
      (!isDepending && remarkTriggered) ||
      (isDepending && allDependingRemarksTriggered && remarkTriggered);

    const key = `${sectionKey}.${accessorKey}`;

    return {
      key,
      label: t(`auditing:form.${key}`),
      sectionKey,
      accessor,
      accessorKey,
      value,
      isRemark,
    };
  };

  getAuditingRemarks = (): AuditingRemark[] => {
    return REMARK_RULES.map(this.resolveRemarkRule)
      .filter(({ isRemark }) => isRemark)
      .map(({ isRemark, ...rest }) => rest);
  };

  isMarkedAsRemark = (sectionKey: AuditingSectionKey, formFieldKey: string) => {
    return !!this.getAuditingRemarks().find(
      remark =>
        remark.sectionKey === sectionKey &&
        [remark.accessor, remark.accessorKey].includes(formFieldKey)
    );
  };

  private transformLegalSituation = (
    legalSituationNumber?: YtjLegalSituation
  ) => {
    switch (legalSituationNumber) {
      case 0:
        return LegalSituation.normal;
      case 1:
        return LegalSituation.bankruptcy;
      case 2:
        return LegalSituation.liquidation;
      case 3:
        return LegalSituation.restructuringProcedure;
      default:
        return null;
    }
  };

  fetchTaxRegisterInformation = async () => {
    const businessId = this.selectedEntity?.customerCompany?.businessId;

    const ytjData = businessId
      ? await this.rootStore.commonStore.fetchFromYtj({ businessId })
      : undefined;

    if (!ytjData) return;

    return {
      taxRegisterFetchedDate: today().toISO(),
      tradeRegister: ytjData.tradeRegister.value ?? null,
      tradeRegisterDate: ytjData.tradeRegister.date ?? null,
      taxAdministrationInformation:
        ytjData.taxAdministrationInformation.value ?? null,
      taxAdministrationInformationDate:
        ytjData.taxAdministrationInformation.date ?? null,
      advanceCollectionRegister:
        ytjData.advanceCollectionRegister.value ?? null,
      advanceCollectionRegisterDate:
        ytjData.advanceCollectionRegister.date ?? null,
      liableForVATOnBusiness: ytjData.liableForVatOnBusiness.value ?? null,
      liableForVATOnBusinessDate: ytjData.liableForVatOnBusiness.date ?? null,
      liableForVATOnProperty: ytjData.liableForVATOnProperty.value ?? null,
      liableForVATOnPropertyDate: ytjData.liableForVATOnProperty.date ?? null,
      employerRegister: ytjData.employerRegister.value ?? null,
      employerRegisterDate: ytjData.employerRegister.date ?? null,
      legalSituation: this.transformLegalSituation(ytjData.legalSituation),
    };
  };

  /**
   * Wrapped section computeds
   */
  materialityComputeds = (currentForm?: MaterialityForm) => {
    const form = currentForm ?? this.entity?.materiality?.form;
    const {
      materialityBaseNumber,
      materialityPercent,
      workingMaterialityPercent,
      singleErrorMaterialityPercent,
      alternatives,
    } = form ?? {};

    const {
      turnoverMaterialityPercent,
      operatingProfitOrLossMaterialityPercent,
      equityMaterialityPercent,
      liabilitiesMaterialityPercent,
      combinedRevenuesMaterialityPercent,
      averageOfLiabilitiesAndRevenuesMaterialityPercent,
      annualContributionMarginMaterialityPercent,
    } = alternatives ?? {};

    const materiality = getPercent(materialityBaseNumber, materialityPercent);

    return {
      materiality,
      workingMateriality: getPercent(materiality, workingMaterialityPercent),
      singleErrorMateriality: getPercent(
        materiality,
        singleErrorMaterialityPercent
      ),
      alternatives: {
        turnoverMateriality: getPercent(
          this.getFinancialNumber(AccountKey.turnover),
          turnoverMaterialityPercent
        ),
        operatingProfitOrLossMateriality: getPercent(
          this.getFinancialNumber(AccountKey.operatingProfitOrLoss),
          operatingProfitOrLossMaterialityPercent
        ),
        equityMateriality: getPercent(
          this.getFinancialNumber(AccountKey.equity),
          equityMaterialityPercent
        ),
        liabilitiesMateriality: getPercent(
          this.getFinancialNumber(AccountKey.balanceLiabilities),
          liabilitiesMaterialityPercent
        ),
        combinedRevenuesMateriality: getPercent(
          getCombinedRevenues(this.rootStore),
          combinedRevenuesMaterialityPercent
        ),
        averageOfLiabilitiesAndRevenuesMateriality: getPercent(
          getAverageOfLiabilitiesAndRevenues(this.rootStore),
          averageOfLiabilitiesAndRevenuesMaterialityPercent
        ),
        annualContributionMarginMateriality: getPercent(
          this.getFinancialNumber(AccountKey.annualContributionMargin),
          annualContributionMarginMaterialityPercent
        ),
      },
    };
  };
}
