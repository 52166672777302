import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import { emptyDetailedInformationItem } from '../../views/auditingSections/auditing/managementUtils';
import { isDetailedInformationItem } from '../../utils/typeGuards';

type FormType = ManagementForm;

const sectionKey: AuditingSectionKey = 'management';
const makeEmptyItem = emptyDetailedInformationItem;

interface Props extends FormFieldProps<FormType> {
  formSectionKey: keyof FormType;
}

export const DetailedInformation = ({
  formState,
  formSectionKey,
  patchFormState,
}: Props) => {
  const { t } = useTranslation();
  const tBase = `auditing:form.${sectionKey}.${formSectionKey}`;

  const formData = formState[formSectionKey];
  const data = isDetailedInformationItem(formData) ? formData : [];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      [formSectionKey]: [...data, makeEmptyItem()],
    });
  };

  const handleDeleteRow = ({ id, value }: DetailedInformationItem) => {
    if (confirmDelete(`${value ?? ''}`)) {
      const patch = data.filter(row => row.id !== id);
      patchFormState({ ...formState, [formSectionKey]: patch });
    }
  };

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = rowIndex === index;
      if (rowIndexMatch || idMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, [formSectionKey]: patch });
  }

  const tableColumns: TableColumnDefs<DetailedInformationItem> = [
    {
      accessorKey: 'value',
      header: () => t(`${tBase}.detailedInformationHeading`),
      className: 'nowrap text-bold',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'page',
      header: () => t(`${tBase}.detailedInformationPage`),
      className: 'nowrap text-bold',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <AuditingTable<DetailedInformationItem>
      data={data}
      onAddNewRow={handleAddNewRow}
      onRowDelete={handleDeleteRow}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};
