import { findByKey } from '../../../utils/array';
import {
  getValueOrDefaultValue,
  makeEmptyThresholdTestRow,
} from './auditingProceduresUtils';

/**
 * Define rows for auditing procedure unit rows.
 */
export const analyticalAuditOfVacationPayAccrualsRows = () => [
  makeEmptyThresholdTestRow({
    key: 'percentageOfAssociatedCostsInHolidayPay',
    unit: '%',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'vacationPayInRelationToWagesForTheFinancialYear',
    unit: '%',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'totalWages',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'averageWagesPerMonth',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'averageAccruedVacationDaysPerMonth',
    defaultValue: 2.5,
    unit: 'pv',
  }),
  makeEmptyThresholdTestRow({
    key: 'numberOfAccruedVacationMonths',
    unit: 'kk',
  }),
  makeEmptyThresholdTestRow({
    key: 'totalAverageUnusedWinterVacationDays',
    defaultValue: 6,
    unit: 'pv',
  }),
  makeEmptyThresholdTestRow({
    key: 'accruedVacationPayWithoutHolidayBonuses',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'accruedVacationPayIncludingHolidayBonusesWithoutOverheads',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({
    key: 'differenceBetweenAccruedAndUsedVacationPayReserve',
    unit: '€',
    disabled: true,
  }),
  makeEmptyThresholdTestRow({ key: 'THRESHOLD', unit: '€' }),
  makeEmptyThresholdTestRow({ key: 'CONCLUSION', disabled: true }),
];

/**
 * Define computation rules/formulas for each row.
 */
const computationRules: ThresholdComputationRules = {
  // Sivukuluprosentti lomapalkoissa
  percentageOfAssociatedCostsInHolidayPay: ({ getAccountMappingValues }) => {
    const [withCosts, withoutCosts] = getAccountMappingValues.cy(
      'holidayPayWithAssociatedCostsAccount',
      'holidayPayWithoutAssociatedCostsAccounts'
    );
    const costs = withCosts - withoutCosts;
    return withCosts !== 0 ? (costs / withCosts) * 100 : 0;
  },

  // Lomapalkat suhteessa tilikauden palkkoihin
  vacationPayInRelationToWagesForTheFinancialYear: props => {
    const [wagesTotal, holidayPay] = props.getAccountMappingValues.cy(
      'wagesTotal',
      'holidayPayWithoutAssociatedCostsAccounts'
    );
    return wagesTotal !== 0 ? (holidayPay / wagesTotal) * 100 : 0;
  },

  // Palkat yhteensä (tilikauden palkat + aktivoidut palkat)
  totalWages: props => {
    const [wagesTotal, capitalizedWages] = props.getAccountMappingValues.cy(
      'wagesTotal',
      'capitalizedWagesOrOtherAdjustmentsAccounts'
    );
    return Math.abs(wagesTotal + capitalizedWages);
  },

  // Keskimääräiset palkat per kuukausi
  averageWagesPerMonth: props => {
    const totalWages = computationRules.totalWages(props);
    const months = props.auditingStore.getFinancialPeriodMonthCount();
    if (typeof totalWages === 'number' && typeof months === 'number')
      return totalWages / (months + 0.5);
    return 0;
  },

  // Keskimääräiset kertyneet lomapäivät per kuukausi
  averageAccruedVacationDaysPerMonth: ({ data }) =>
    getValueOrDefaultValue(
      data.find(findByKey('averageAccruedVacationDaysPerMonth'))
    ),

  // Kertyneiden lomakuukausien määrä (1.4. alkaen)
  numberOfAccruedVacationMonths: ({ data, auditingStore }) =>
    getValueOrDefaultValue(
      data.find(findByKey('numberOfAccruedVacationMonths')),
      auditingStore.getFinancialPeriodMonthCountFromVacationPeriodStart()
    ),

  // Keskimääräiset pitämättömät talvilomapäivät yhteensä
  totalAverageUnusedWinterVacationDays: ({ data }) =>
    getValueOrDefaultValue(
      data.find(findByKey('totalAverageUnusedWinterVacationDays'))
    ),

  // Laskennalliset lomapalkat ilman lomarahoja
  accruedVacationPayWithoutHolidayBonuses: props => {
    const averageWagesPerMonth = computationRules.averageWagesPerMonth(props);
    const averageAccruedVacationDaysPerMonth =
      computationRules.averageAccruedVacationDaysPerMonth(props);
    const numberOfAccruedVacationMonths =
      computationRules.numberOfAccruedVacationMonths(props);
    const totalAverageUnusedWinterVacationDays =
      computationRules.totalAverageUnusedWinterVacationDays(props);

    return (
      (averageWagesPerMonth / 25) *
      (averageAccruedVacationDaysPerMonth * numberOfAccruedVacationMonths +
        totalAverageUnusedWinterVacationDays)
    );
  },

  // Laskennallinen lomapalkkavaraus ml. lomarahat ilman sivukuluja
  accruedVacationPayIncludingHolidayBonusesWithoutOverheads: props =>
    computationRules.accruedVacationPayWithoutHolidayBonuses(props) * 1.5,

  // Laskennallisen ja tehdyn varauksen erotus
  differenceBetweenAccruedAndUsedVacationPayReserve: props => {
    const [holidayPayWithoutAssociatedCostsAccounts] =
      props.getAccountMappingValues.cy(
        'holidayPayWithoutAssociatedCostsAccounts'
      );

    return (
      Math.abs(holidayPayWithoutAssociatedCostsAccounts) -
      computationRules.accruedVacationPayIncludingHolidayBonusesWithoutOverheads(
        props
      )
    );
  },

  // KYNNYSARVO
  THRESHOLD: ({ data, auditingStore }) =>
    Math.abs(
      getValueOrDefaultValue(
        data.find(findByKey('THRESHOLD')),
        auditingStore.materialityComputeds().materiality
      )
    ),

  // JOHTOPÄÄTÖS (ERO KYNNYSARVOON)
  CONCLUSION: props => {
    const threshold = computationRules.THRESHOLD(props);
    const comparisonValue = Math.abs(
      computationRules.differenceBetweenAccruedAndUsedVacationPayReserve(props)
    );
    const comparison = threshold - comparisonValue;
    return comparison < 0 ? -1 : comparison > 0 ? 1 : 0;
  },
};

export const analyticalAuditOfVacationPayAccrualsComputationRules =
  computationRules;
