import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import { Api } from '../api/RootApi';
import BaseStore from './BaseStore';
import { RootStore } from './RootStore';

interface EntityData {
  id: number;
}

type ApiResponse<T> = Promise<Api.Problem | Api.Success<T>>;

interface EntityApi<Entity> {
  getOneEntity: ({ id }: Api.Payload.GetOne) => ApiResponse<Entity>;
  getAllEntities: () => ApiResponse<Entity[]>;
  addEntity: ({
    data,
  }: Api.Payload.Post<Omit<Entity, 'id'>>) => ApiResponse<Entity>;
  editEntity: ({
    id,
    data,
  }: Api.Payload.Patch<Partial<Entity>>) => ApiResponse<Entity>;
  deleteEntity: ({ id }: Api.Payload.Delete) => ApiResponse<Entity>;
}

interface RequestOptions {
  isSilent?: boolean; // If false, any toasts/notifications are not shown
  isBackground?: boolean; // If false, any loading indicators are not shown
}

/**
 * Base entity store.
 *
 * Provides some basic actions for a given entity
 * E.g. fetch entities, add new entity, edit entity, delete entity etc...
 */
export abstract class BaseEntityStore<
  Entity extends EntityData
> extends BaseStore {
  entityApi: EntityApi<Entity>;
  entityType: EntityType;

  /**
   * Observables
   */
  entities?: Entity[];
  entity?: Entity;

  constructor(
    rootStore: RootStore,
    api: Api,
    entityApi: EntityApi<Entity>,
    entityType: EntityType
  ) {
    super(rootStore, api);
    this.entityApi = entityApi;
    this.entityType = entityType;

    makeObservable(this, {
      entityApi: false,
      entities: observable,
      entity: observable,

      allEntities: computed,
      selectedEntity: computed,

      getEntities: action,
      getEntity: action,
      addEntity: action,
      editEntity: action,
      deleteEntity: action,

      findEntity: action,
    });
  }

  /**
   * Setters
   */
  setEntities = (entities: Entity[]) => (this.entities = entities);
  setEntity = (entity?: Entity) => runInAction(() => (this.entity = entity));

  protected updateEntities = (
    entityData: Entity | Partial<Entity> | number
  ) => {
    const entities = this.entities ?? [];

    if (typeof entityData === 'number') {
      // If number (=ID) was passed, DELETE entity
      const deletedEntityId = entityData;
      this.entities = entities?.filter(({ id }) => id !== deletedEntityId);
    } else if (entityData.id) {
      // Entity should have ID in order to store it in entities array

      const entityExists = !!entities.find(({ id }) => id === entityData.id);
      if (entityExists) {
        // EDIT existing entity
        this.entities = entities.map(entity =>
          entity.id === entityData.id ? { ...entity, ...entityData } : entity
        );
      } else {
        // If existing entity was not found -> ADD new one
        this.entities = [...entities, entityData as Entity];
      }
    }
  };

  /**
   * Computeds
   */
  get allEntities() {
    return toJS(this.entities);
  }

  get selectedEntity() {
    return toJS(this.entity);
  }

  /**
   * Actions
   */

  getEntities = async (options: RequestOptions = {}) => {
    type ResponseType = Entity[];
    return await this.defaultAction<ResponseType>({
      taskName: `GET_${this.entityType}_ENTITIES`,
      taskType: options.isBackground ? 'fetching-background' : 'fetching',
      isSilent: options.isSilent !== undefined ? options.isSilent : true,
      apiRequest: () => this.entityApi.getAllEntities(),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.setEntities(response.data);
      },
    });
  };

  getEntity = async (id: number, options: RequestOptions = {}) => {
    type ResponseType = Entity;
    return await this.defaultAction<ResponseType>({
      taskName: `GET_${this.entityType}_ENTITY`,
      taskType: options.isBackground ? 'fetching-background' : 'fetching',
      isSilent: options.isSilent !== undefined ? options.isSilent : true,
      apiRequest: () => this.entityApi.getOneEntity({ id }),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.setEntity(response.data);
      },
    });
  };

  addEntity = async (
    params: Api.Payload.Post<Omit<Entity, 'id'>>,
    options: RequestOptions = {}
  ) => {
    type ResponseType = Entity;
    return await this.defaultAction<ResponseType>({
      taskType: options.isBackground ? 'saving-background' : 'saving',
      isSilent: options.isSilent,
      apiRequest: () => this.entityApi.addEntity(params),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.updateEntities(response.data);
        this.setEntity(response.data);
      },
    });
  };

  editEntity = async (
    params: Api.Payload.Patch<Partial<Entity>>,
    options: RequestOptions = {}
  ) => {
    type ResponseType = Entity | Partial<Entity>;
    return await this.defaultAction<ResponseType>({
      taskType: options.isBackground ? 'saving-background' : 'saving',
      isSilent: options.isSilent,
      apiRequest: () => this.entityApi.editEntity(params),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.updateEntities(response.data);
        if (this.entity) {
          this.setEntity({
            ...this.entity,
            ...response.data,
          });
        }
      },
    });
  };

  deleteEntity = async (
    params: Api.Payload.Delete,
    options: RequestOptions = {}
  ) => {
    type ResponseType = Entity;
    return await this.defaultAction<ResponseType>({
      taskType: options.isBackground ? 'deleting-background' : 'deleting',
      isSilent: options.isSilent,
      apiRequest: () => this.entityApi.deleteEntity(params),
      onSuccess: (_: Api.Success<ResponseType>) => {
        this.updateEntities(params.id);
      },
    });
  };

  findEntity = (entityId?: number) => {
    if (!entityId || !this.entities) return undefined;
    return toJS(this.entities.find(({ id }) => id === entityId));
  };
}

export default BaseStore;
