import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Api } from '../../api/RootApi';
import { QualificationLevel, Role } from '../../utils';
import { BaseEntityStore } from '../BaseEntityStore';
import { RootStore } from '../RootStore';

export class UserStore extends BaseEntityStore<User> {
  /**
   * Observables
   */
  roles?: Role[];
  qualificationLevels?: QualificationLevel[];

  constructor(rootStore: RootStore, api: Api) {
    super(rootStore, api, api.user, 'USER');
    makeObservable(this, {
      roles: observable,
      qualificationLevels: observable,

      allRoles: computed,
      allQualificationLevels: computed,

      getUserName: action,
      getAuditFirm: action,
      getCompany: action,
      getAuditFirmId: action,
      getCompanyId: action,
      getFirmOrCompanyName: action,
      getRoles: action,
      updateMe: action,
    });
  }

  /**
   * Setters
   */
  setRoles = (roles: Role[]) => (this.roles = roles);
  setQualificationLevels = (qualificationLevels: QualificationLevel[]) =>
    (this.qualificationLevels = qualificationLevels);

  /**
   * Overwrite super class's updateEntities method.
   *
   * This is done because we want to check if updated user was logged-id-user,
   * and if it was -> update user's data in auth store as well.
   */
  protected updateEntities = (entityData: User | Partial<User> | number) => {
    const entities = this.entities ?? [];
    if (typeof entityData === 'number') {
      // If number (=ID) was passed, DELETE entity
      const deletedEntityId = entityData;
      this.entities = entities?.filter(({ id }) => id !== deletedEntityId);
    } else if (entityData.id) {
      // Entity should have ID in order to store it in entities array

      const entityExists = !!entities.find(({ id }) => id === entityData.id);
      if (entityExists) {
        // EDIT existing entity
        this.entities = entities.map(entity =>
          entity.id === entityData.id ? { ...entity, ...entityData } : entity
        );
        // In case authenticated user was updated -> update user data in auth store as well
        const authUser = this.rootStore.authStore.user;
        if (authUser && authUser.id === entityData.id) {
          this.rootStore.authStore.setUser({ ...authUser, ...entityData });
        }
      } else {
        // If existing entity was not found -> ADD new one
        this.entities = [...entities, entityData as User];
      }
    }
  };

  /**
   * Computeds
   */
  get allRoles() {
    return toJS(this.roles);
  }

  get allQualificationLevels() {
    return toJS(this.qualificationLevels);
  }

  getUserName = (user?: User) => {
    if (!user) return '';
    return `${user.firstName} ${user.lastName}`;
  };

  getAuditFirmId = (user?: Partial<User>) => {
    return (
      user?.auditFirmId ??
      (user?.auditFirms?.length ? user.auditFirms[0]?.id : undefined)
    );
  };

  getCompanyId = (user?: Partial<User>) => {
    return user?.customerCompanyId ?? user?.customerCompanies?.[0]?.id;
  };

  getAuditFirm = (user?: Partial<User>) => {
    let auditFirm = user?.auditFirms?.length ? user.auditFirms[0] : undefined;
    if (!auditFirm && user?.auditFirmId) {
      auditFirm = this.rootStore.auditFirmStore.findEntity(user.auditFirmId);
    }
    return auditFirm;
  };

  getCompany = (user?: Partial<User>) => {
    let company = user?.customerCompanies?.length
      ? user.customerCompanies[0]
      : undefined;
    if (!company && user?.customerCompanyId) {
      company = this.rootStore.customerCompanyStore.findEntity(
        user.customerCompanyId
      );
    }
    return company;
  };

  getFirmOrCompanyName = (user?: User) => {
    const auditFirm = this.getAuditFirm(user);
    const company = this.getCompany(user);
    return company?.name ?? auditFirm?.name ?? '';
  };

  /**
   * Actions
   */
  getRoles = async () => {
    type ResponseType = Role[];
    return await this.defaultAction<ResponseType>({
      taskName: 'GET_ROLES',
      taskType: 'fetching',
      isSilent: true,
      apiRequest: () => this.api.user.getRoles(),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.setRoles(response.data);
      },
    });
  };

  getQualificationLevels = async () => {
    type ResponseType = QualificationLevel[];
    return await this.defaultAction<ResponseType>({
      taskName: 'GET_QUALIFICATION_LEVELS',
      taskType: 'fetching',
      isSilent: true,
      apiRequest: () => this.api.user.getQualificationLevels(),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.setQualificationLevels(response.data);
      },
    });
  };

  updateMe = async (params: Partial<User & { currentPassword?: string }>) => {
    type ResponseType = User;
    return await this.defaultAction<ResponseType>({
      taskType: 'saving',
      apiRequest: () => this.api.user.updateMe(params),
      onSuccess: (response: Api.Success<ResponseType>) => {
        this.rootStore.authStore.setUser(response.data);
        if (this.entities?.length) {
          this.updateEntities(response.data);
        }
      },
    });
  };
}
