import AuditingProcedureItem from '../../../components/auditing/AuditingProcedureItem';
import { PerceptionInformation } from '../../../components/auditing/Management.PerceptionInformation';
import { visibleData } from './managementUtils';
import ProcedureAccounts from '../../../components/auditing/procedures/ProcedureAccounts';
import i18n from '../../../i18n';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { AccountClassKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import { DetailedInformation } from '../../../components/auditing/Management.DetailedInformation';
import { EssentialNotes } from '../../../components/auditing/Management.EssentialNotes';

type SectionFormType = ManagementForm;

const sectionKey: AuditingSectionKey = 'management';
const batch = FinancialStatementBatch['G-management'];

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  // publicNetworkDocuments
  municipalStrategyUpToDate: null,
  municipalStrategy: '',
  municipalStrategySource: '',
  administrativeRule: '',
  administrativeRuleSource: '',
  administrativeRuleUpToDate: null,
  budget: '',
  budgetSource: '',
  budgetUpToDate: null,
  financialStatement: '',
  financialStatementSource: '',
  financialStatementUpToDate: null,
  auditBoardEvaluationReport: '',
  auditBoardEvaluationReportSource: '',
  auditBoardEvaluationReportUpToDate: null,
  auditReport: '',
  auditReportSource: '',
  auditReportUpToDate: null,
  municipalCooperationAgreements: '',
  municipalCooperationAgreementsSource: '',
  municipalCooperationAgreementsUpToDate: null,
  groupInstruction: '',
  groupInstructionReportource: '',
  groupInstructionReportUpToDate: null,
  boardCommitments: '',
  boardCommitmentsSource: '',
  boardCommitmentsUpToDate: null,
  boardRewardJustifications: '',
  boardRewardJustificationsSource: '',
  boardRewardJustificationsUpToDate: null,
  serviceFees: '',
  serviceFeesSource: '',
  serviceFeesUpToDate: null,

  programmeOfMeasures: '',
  programmeOfMeasuresSource: '',
  programmeOfMeasuresUpToDate: null,

  administrativeRuleMeetsRequirements: '',
  administrativeRuleMeetsRequirementsSource: '',
  administrativeRuleMeetsRequirementsUpToDate: null,

  // budget
  budgetAndMunicipalStrategyInLine: '',
  budgetAndMunicipalStrategyInLineSource: '',
  budgetAndMunicipalStrategyInLineUpToDate: null,
  isBudgetRealistic: '',
  isBudgetRealisticSource: '',
  isBudgetRealisticUpToDate: null,
  budgetAndChangesProperlyApproved: '',
  budgetAndChangesProperlyApprovedSource: '',
  budgetAndChangesProperlyApprovedUpToDate: null,

  // internalInstructions
  councilGuidelinesForInternalInstructions: '',
  councilGuidelinesForInternalInstructionsSource: '',
  councilGuidelinesForInternalInstructionsUpToDate: null,
  internalInstructionsMeetCouncilGuidelines: '',
  internalInstructionsMeetCouncilGuidelinesSource: '',
  internalInstructionsMeetCouncilGuidelinesUpToDate: null,
  internalInstructionsUpToDate: '',
  internalInstructionsUpToDateSource: '',
  internalInstructionsUpToDateUpToDate: null,
  currentPracticesAreValid: '',
  currentPracticesAreValidSource: '',
  currentPracticesAreValidUpToDate: null,

  // groupInstruction
  planningOfBudgetAndInvestments: '',
  planningOfBudgetAndInvestmentsSource: '',
  planningOfBudgetAndInvestmentsUpToDate: null,
  groupSupervisionAndReporting: '',
  groupSupervisionAndReportingSource: '',
  groupSupervisionAndReportingUpToDate: null,
  informingAndInformationAccess: '',
  informingAndInformationAccessSource: '',
  informingAndInformationAccessUpToDate: null,
  obligationToGetMunicipalitysOpinionBeforeDecisions: '',
  obligationToGetMunicipalitysOpinionBeforeDecisionsSource: '',
  obligationToGetMunicipalitysOpinionBeforeDecisionsUpToDate: null,
  groupsInternalServices: '',
  groupsInternalServicesSource: '',
  groupsInternalServicesUpToDate: null,
  subsidiaryBoardCompositionAndAppointment: '',
  subsidiaryBoardCompositionAndAppointmentSource: '',
  subsidiaryBoardCompositionAndAppointmentUpToDate: null,
  subsidiaryGoodManagementAndLeadership: '',
  subsidiaryGoodManagementAndLeadershipSource: '',
  subsidiaryGoodManagementAndLeadershipUpToDate: null,

  // boardMeetingMinutes
  boardMeetingMinutesChecked: null,

  // councilMeetingMinutes
  councilMeetingMinutesChecked: null,
  previousYearFinancialStatementConfirmed: '',
  previousYearFinancialStatementConfirmedSource: '',
  previousYearFinancialStatementConfirmedUpToDate: null,
  nextYearBudgetApproved: '',
  nextYearBudgetApprovedSource: '',
  nextYearBudgetApprovedUpToDate: null,
  handlingOfBudgetChanges: '',
  handlingOfBudgetChangesSource: '',
  handlingOfBudgetChangesUpToDate: null,
  isBudgetReviewedEveryQuarter: '',
  isBudgetReviewedEveryQuarterSource: '',
  isBudgetReviewedEveryQuarterUpToDate: null,
  affiliationNotices: '',
  affiliationNoticesSource: '',
  affiliationNoticesUpToDate: null,
  evaluationReport: '',
  evaluationReportSource: '',
  evaluationReportUpToDate: null,

  // reviewOfPriorityBoards
  reviewOfPriorityBoardsNotes: [],
  reviewOfPriorityBoardsChecked: null,

  // reviewOfPriorityOfficials
  reviewOfPriorityOfficialsNotes: [],
  reviewOfPriorityOfficialsChecked: null,

  // tables
  municipalStrategyDetails: [],
  administrativeRuleDetails: [],
  internalInstructionsDetails: [],
  groupInstructionDetails: [],
  councilMeetingMinutesNotes: [],
  boardMeetingMinutesNotes: [],

  // overall assessment
  overallAssessmentComments: '',
};

const formFields: FormFields<SectionFormType> = ({ store, auditing }) => [
  formBuilder.custom(
    <ProcedureAccounts classKey={AccountClassKey[batch]} auditing={auditing} />
  ),

  ...store.auditingStore.getAuditingProceduresBatch(batch).map(procedure => {
    const { actionKey } = procedure;
    const commonItems = [
      formBuilder.custom(
        <AuditingProcedureItem auditingProcedure={procedure} />
      ),
    ];
    let items: FormField<SectionFormType>[] = [];

    switch (actionKey) {
      case 'publicNetworkDocuments':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.publicNetworkDocuments}
              {...props}
            />
          )),
        ];
        break;
      case 'municipalStrategy':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.municipalStrategy}
              {...props}
            />
          )),
          formBuilder.custom(props => (
            <DetailedInformation
              formSectionKey="municipalStrategyDetails"
              {...props}
            />
          )),
        ];
        break;
      case 'administrativeRule':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.administrativeRule}
              {...props}
            />
          )),
          formBuilder.custom(props => (
            <DetailedInformation
              formSectionKey="administrativeRuleDetails"
              {...props}
            />
          )),
        ];
        break;
      case 'budget':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.budget}
              {...props}
            />
          )),
        ];
        break;
      case 'internalInstructions':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.internalInstructions}
              {...props}
            />
          )),
          formBuilder.custom(props => (
            <DetailedInformation
              formSectionKey="internalInstructionsDetails"
              {...props}
            />
          )),
        ];
        break;
      case 'groupInstruction':
        items = [
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.groupInstruction}
              {...props}
            />
          )),
          formBuilder.custom(props => (
            <DetailedInformation
              formSectionKey="groupInstructionDetails"
              {...props}
            />
          )),
        ];
        break;
      case 'boardMeetingMinutes':
        items = [
          formBuilder.boolean({
            accessor: 'boardMeetingMinutesChecked',
            options: ['no', 'yes'],
          }),
          formBuilder.custom(props => (
            <EssentialNotes
              formSectionKey="boardMeetingMinutesNotes"
              {...props}
            />
          )),
        ];
        break;
      case 'councilMeetingMinutes':
        items = [
          formBuilder.boolean({
            accessor: 'councilMeetingMinutesChecked',
            options: ['no', 'yes'],
          }),
          formBuilder.custom(props => (
            <PerceptionInformation
              visibleData={visibleData.councilMeetingMinutes}
              {...props}
            />
          )),
          formBuilder.custom(props => (
            <EssentialNotes
              formSectionKey="councilMeetingMinutesNotes"
              {...props}
            />
          )),
        ];
        break;
      case 'reviewOfPriorityBoards':
        items = [
          formBuilder.boolean({
            accessor: 'reviewOfPriorityBoardsChecked',
            options: ['no', 'yes'],
          }),
          formBuilder.custom(props => (
            <EssentialNotes
              formSectionKey="reviewOfPriorityBoardsNotes"
              {...props}
            />
          )),
        ];
        break;
      case 'reviewOfPriorityOfficials':
        items = [
          formBuilder.boolean({
            accessor: 'reviewOfPriorityOfficialsChecked',
            options: ['no', 'yes'],
          }),
          formBuilder.custom(props => (
            <EssentialNotes
              formSectionKey="reviewOfPriorityOfficialsNotes"
              {...props}
            />
          )),
        ];
        break;
    }

    return formBuilder.accordionGroup({
      title: i18n.t(
        `auditing:auditingProcedureAction.${batch}.${procedure.actionKey}`
      ),
      items: [...commonItems, ...items],
    });
  }),

  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
        showContextInfo: 'top-right',
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['detailedPlan'],
};

export default section;
